<p-dialog [style]="{minWidth: '500px', width: '100px'}" [(visible)]="visible" [draggable]="false" (onHide)="onClose()" 
(onShow)="onShow()" resizable="false" responsive="true" modal="false" dismissableMask="true" 
positionTop="200" styleClass="modal-link-job">
  <p-header>
    Originating Job
  </p-header>
  <div class="row" [loadingIndicator]="loadingSubscription">
    <div class="link-mode" *ngIf="!isConfirm">
      <span class="message">Enter the Job Number of the Originating Job</span>
      <input class="form-control input-job-number" type="text" [(ngModel)]="jobCode" (keydown.enter)="getInfoOriginJob()" [disabled]="appStateService.notifyIFactDown$ | async"/>
      <span class="error-warning" [innerHtml]="jobOriginInfo.errorMessage"></span>
    </div>
    <div class="confirm-mode" *ngIf="isConfirm">
      <div class="content">
        <table>
          <tr>
            <td>Job Number:</td>
            <td>{{ jobOriginInfo.jobCode }}</td>
          </tr>
          <tr>
            <td>Job Name:</td>
            <td>{{ jobOriginInfo.jobName }}</td>
          </tr>
          <tr>
            <td>Customer:</td>
            <td>{{ jobOriginInfo.customer }}</td>
          </tr>
          <tr>
            <td>Well Name:</td>
            <td>{{ jobOriginInfo.wellName }}</td>
          </tr>
        </table>
      </div>
      <div class="cf-message">
        <span>Would you like to set this Job as the Originating Job?</span>
      </div>
    </div>
  </div>
  <p-footer>
    <span class="btn-cancel" (click)="onCancel()">Cancel</span>
    <button class="btn-next" *ngIf="!isConfirm" (click)="getInfoOriginJob()" [disabled]="appStateService.notifyIFactDown$ | async">Next</button>
    <button class="btn-confirm" *ngIf="isConfirm" (click)="btnConfirm_click()">Confirm</button>
  </p-footer>
</p-dialog>
