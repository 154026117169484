<div>
    <div class="row">
        <div class="p-4">
            Please confirm that the temperatures below are correct.<br>
            BHCT/BHST Validation is required to submit Control Point 1.
        </div>
    </div>
    <div class="content">
        <div>
            <p-table [value]="data" [columns]="cols">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <td>
                            {{data?.bhct.value}} {{data?.bhct.units}}
                        </td>
                        <td>
                            {{data?.bhst.value}} {{data?.bhst.units}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="row">
        <div class="text-link p-4">
             <a class="link" (click)="onClose()"><b>Close</b></a>
        </div>
        <button class="btn-upload" (click)="confirm()">
            CONFIRM
        </button>
    </div>
</div>