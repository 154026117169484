import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { JobType, JobTypeEquipment, JobTypeEquipmentData, List, SurfaceEquipment } from '../../models/entities/equipment';
import { environment } from '../../environment';
import { RoleLevels } from 'apps/vida/src/modules/application/models';

@Injectable({
  providedIn: 'root'
})
export class SurfaceEquipmentService {

  surfaceEquipmentListUpdated$ = new Subject<boolean>();

  constructor(private http: HttpService) { }

  getGroups(minRoleLevel: number = RoleLevels.Enhanced) {
    return this.http.Get(`${environment.baseUrl}/api/admin/region-groups?minRoleLevel=${minRoleLevel}`);
  }

  public getEquipment(equipmentTypeId: string, equipmentName: string, objectType: string, activeOnly: boolean = true): Observable<any> {
    return this.http.Get<any>(`${environment.baseUrl}/api/admin/surface-equipment/equipment?equipmentTypeId=${equipmentTypeId}&equipmentName=${equipmentName}&objectType=${objectType}&activeOnly=${activeOnly}`).pipe(shareReplay());
  }

  public getEquipmentTypes(activeOnly: boolean = true): Observable<any[]> {
    return this.http.Get<any>(`${environment.baseUrl}/api/admin/surface-equipment/equipment-types/?activeOnly=${activeOnly}`).pipe(shareReplay());
  }

  public getEquipmentNames(equipmentTypeId: string, activeOnly: boolean = true): Observable<any> {
    return this.http.Get<any>(`${environment.baseUrl}/api/admin/surface-equipment/equipment-names/?equipmentTypeId=${equipmentTypeId}&activeOnly=${activeOnly}`).pipe(shareReplay());
  }

  public getEquipmentObjectTypes(equipmentTypeId: string, equipmentName: string, activeOnly: boolean = true): Observable<any> {
    return this.http.Get<any>(`${environment.baseUrl}/api/admin/surface-equipment/object-types/?equipmentTypeId=${equipmentTypeId}&equipmentName=${equipmentName}&activeOnly=${activeOnly}`).pipe(shareReplay());
  }

  public getEquipmentByGroup(groupId: string): Observable<SurfaceEquipment[]> {
    return this.http.Get<SurfaceEquipment[]>(`${environment.baseUrl}/api/admin/surface-equipment/${groupId}`).pipe(shareReplay());
  }

  public updateEquipmentList(groupId: string, equipmentList: SurfaceEquipment[]): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/admin/surface-equipment/update-list/${groupId}`, JSON.stringify(equipmentList));
  }

  public getLists(groupId: string): Observable<List[]> {
    return this.http.Get<List[]>(`${environment.baseUrl}/api/admin/jtequipment/lists/${groupId}`).pipe(shareReplay());
  }

  public getListById(listId: string): Observable<JobTypeEquipment[]> {
    return this.http.Get<JobTypeEquipment[]>(`${environment.baseUrl}/api/admin/jtequipment/equipment/${listId}`).pipe(shareReplay());
  }

  public getJobTypes(groupId: string): Observable<JobType[]> {
    return this.http.Get<JobType[]>(`${environment.baseUrl}/api/admin/jtequipment/job-types/${groupId}`).pipe(shareReplay());
  }

  public addList(name: string): Observable<List> {
    return this.http.Post(`${environment.baseUrl}/api/admin/jtequipment/list-add`, JSON.stringify(name));
  }

  public updateJobTypeEquipmentList(jobTypeEquipmentList: List): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/admin/jtequipment/list-update`, JSON.stringify(jobTypeEquipmentList));
  }

  public deleteList(listId: string): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/admin/jtequipment/list-delete`, JSON.stringify(listId));
  }

  public updateDefaultEquipmentByJobType(listId: string, jobTypeEquipmentData: JobTypeEquipmentData): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/admin/jtequipment/update/${listId}`, JSON.stringify(jobTypeEquipmentData));
  }

  public addListWithModel(groupId: string, listName: string, jobTypeEquipmentData: JobTypeEquipmentData): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/admin/jtequipment/add/${groupId}/${listName}`, JSON.stringify(jobTypeEquipmentData));
  }

  public updateDefaultEquipmentByJobTypeWithList(list: List, jobTypeEquipmentData: JobTypeEquipmentData): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/admin/jtequipment/update/${list.id}/${list.name}`, JSON.stringify(jobTypeEquipmentData));
  }
}
