import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { Job } from "libs/models";
import { RoleService } from "libs/shared";
import { map } from "rxjs/operators";
import { Role } from "../../application/models";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import * as moment from "moment-mini";
import { EventEmitter } from "@angular/core";
import { isJobDueSoon, isJobOutOfComplience } from "libs/helpers/job-helper";

export class JobForm {
    public job: Job;
    public jobDateFormGroup: FormGroup<IJobDateFormGroup>;
    public onChanges = new EventEmitter<any>();

    private isJobOutOfComplience = isJobOutOfComplience;
    private isJobDueSoon = isJobDueSoon;
    private sbjJobOutOfComplience$ = new BehaviorSubject<boolean>(false);
    private sbjJobDueSoon$ = new BehaviorSubject<boolean>(false);
    private supbscriptionProjectDate: Subscription;
    
    constructor(job: Job, private roleService: RoleService) {
        this.job = job;

        this.jobDateFormGroup = new FormGroup<IJobDateFormGroup>({
            actualDate: new FormControl(job.actualDate),
            isManualActualDate: new FormControl(job.isManualActualDate),
            projectedDate: new FormControl(job.projectedDate)
        });

        this.supbscriptionProjectDate = this.jobDateFormGroup.controls['projectedDate']
                                        .valueChanges.subscribe(x => this.onProjectDateChanged(x));

        this.updateJobComplienceStatus();
    }

    public isMemberOfGroup$: Observable<boolean> = this.roleService.roles$.pipe(
        map((roles: Role[]) => roles.findIndex(r => r.groupId === this.job.groupId) !== -1));

    public get isJobOutOfComplience$(): Observable<boolean> {
        return this.sbjJobOutOfComplience$;
    }

    public get isJobDueSoon$(): Observable<boolean> {
        return this.sbjJobDueSoon$;
    }

    private updateJobComplienceStatus() {
        this.sbjJobOutOfComplience$.next(this.isJobOutOfComplience(this.job));
        this.sbjJobDueSoon$.next(this.isJobDueSoon(this.job));
    }

    private onProjectDateChanged(x: any) {
        const newValue = moment(x).toISOString();
        if (this.job.projectedDate !== newValue){
            this.job.projectedDate = newValue;
            this.updateJobComplienceStatus();
            this.onChanges.emit();
        }
    }

    onDestroy(): void {
        if (this.supbscriptionProjectDate != null) {
            this.supbscriptionProjectDate.unsubscribe();
            this.supbscriptionProjectDate = null;
        }
    }
}

export interface IJobDateFormGroup {
    actualDate: AbstractControl;
    isManualActualDate: AbstractControl;
    projectedDate: AbstractControl;
}