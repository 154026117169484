import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { VidaFileType } from '../../../shared/constant';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AdUser } from 'libs/models';
import { DynamicSidebarContent, DynamicSidebarRef } from 'libs/ui';
import { LocalWellReviewModel, FileModel } from '../../../control-point/models';
import { ControlPointService2 } from 'libs/shared/services';
import * as moment from 'moment-mini';
import { HttpCommanderService } from 'libs/shared/services';
import { JobUploaderComponent } from 'apps/vida/src/modules/shared/components';

@Component({
  selector: 'local-well-review-sidebar',
  templateUrl: './local-well-review-sidebar.component.html',
  styleUrls: ['./local-well-review-sidebar.component.scss']
})
export class LocalWellReviewSideBarComponent implements DynamicSidebarContent,  OnInit {

  data: any;
  localWellReviewFb: UntypedFormGroup;
  vidaFileType: VidaFileType = VidaFileType.LocalWellReview;
  errorMessage: string;
  isLocalWellReviewVisible = false;
  resetUploerUI = false;
  disableSaveButton = false;
  readOnly = false;
  destinationFolderPath: string;
  localWellReview: LocalWellReviewModel = null;
  listUploadedJobProgram: any = [];
  loadingSubscription: Subscription;
  localWellReviewFiles = [];
  currentComment: string;
  jobId: string;
  @ViewChild('uploader', { static: true }) jobUploader: JobUploaderComponent;
  constructor(
    private HCService: HttpCommanderService,
    private fb: UntypedFormBuilder,
    private dynamicSidebarRef: DynamicSidebarRef<LocalWellReviewSideBarComponent>,
    private controlPointService: ControlPointService2
  ) {
  }

  ngOnInit() {
    this.destinationFolderPath = this.data.path;
    this.jobId = this.data.jobId;
    const localWell = this.data.form ? this.data.form : new LocalWellReviewModel();
    this.localWellReviewFb = this.createFormGroup(localWell);
    this.getFiles();
    this.readOnly =  !this.data.canEdit;
    this.disableSaveButton = this.readOnly;
  }
  // Enable Save button when selecting an file to import
  fileSelectedHandler() {
    if (this.data.canEdit) {
      this.disableSaveButton = false;
    }
  }

  // Upload in progress callback
  uploadInprogress(event) {
    if (event) {
      this.disableSaveButton = true;
    }
  }

  // Upload success callback
  uploadSuccess(event) {
    if (event.isSuccess) {
      this.getFiles();
      if (this.data.canEdit) {
        this.disableSaveButton = false;
      }
    }
  }

  // Upload cancel callback
  uploadCancel(event) {
    if (!event.isSuccess) {
      this.getFiles();
    }
  }

  getFiles() {
    this.resetUI();
    this.loadingSubscription = this.HCService.getFilesByVirtualRelativeFolderPath(this.destinationFolderPath, VidaFileType.LocalWellReview)
    .subscribe(({statusCode, result}) => {
      if ( statusCode === 200 ) {
        this.localWellReviewFiles = [...result.map(file => Object.assign(new FileModel, file))];
      }
    });
   
  }

  resetUI() {
    this.errorMessage = '';
  }

  saveLocalWellReview() {
    this.localWellReviewFb.markAsTouched();
    let participantNames = [];
    if ( this.localWellReviewFb.value && this.localWellReviewFb.value.participantNames.length ) {
      participantNames = this.localWellReviewFb.value.participantNames.map(parti => {
        return `${parti.networkId}`;
      });
    }
    const jobTemp = Object.assign(new LocalWellReviewModel, this.localWellReviewFb.value, {comment: this.localWellReviewFb.value.commentLocal});
    jobTemp.participants = participantNames.join(', ');
    this.loadingSubscription = this.controlPointService.updateLocalWellReview(this.jobId, jobTemp).subscribe((data: any) => {
      this.localWellReview = Object.assign(new LocalWellReviewModel, data);
      const participantNamesTemp = data.activeDirectoryUsers.map( part => Object.assign( new AdUser, {...part}) );
      this.localWellReview.participantNames = participantNamesTemp;
      this.dynamicSidebarRef.close(this.localWellReview);
    });
  }

  cancelLocalWellReview() {
    this.jobUploader.cancelUpload();
    this.hideFileSidebar();
  }

  hideFileSidebar() {
    this.errorMessage = '';
    this.dynamicSidebarRef.close(null);
  }
  private createFormGroup(localWellReview: LocalWellReviewModel): UntypedFormGroup {
    return this.fb.group({
      virtualRelativeFilePathLocal: [
        {
          value: localWellReview.virtualRelativeFilePathLocal,
          disabled: !this.data.canEdit
        }
      ],
      description: [
        {
          value: localWellReview.description,
          disabled: !this.data.canEdit
        }
      ],
      createdDate: [
        {
          value: localWellReview.createdDate ? new Date(localWellReview.createdDate) : null,
          disabled: !this.data.canEdit
        }
      ],
      uploader: [
        {
          value: localWellReview.uploader,
          disabled: !this.data.canEdit
        }
      ],
      filePathDownload: [
        {
          value: localWellReview.filePathDownload,
          disabled: !this.data.canEdit
        }
      ],
      commentLocal: [
        {
          value: localWellReview.comment,
          disabled: !this.data.canEdit
        }
      ],
      participantNames: [
        {
          value: localWellReview.participantNames ? localWellReview.participantNames : [],
          disabled: !this.data.canEdit
        }
      ],
      reviewDate: [
        {
          value: localWellReview.reviewDate ? moment.utc(localWellReview.reviewDate).local() : null,
          disabled: !this.data.canEdit
        }
      ],
      isWellReviewCompleted: [
        {
          value: localWellReview.isWellReviewCompleted,
          disabled: !this.data.canEdit
        }
      ]
    });
  }
  getReviewInfor() {
    this.loadingSubscription = this.controlPointService.getLocalWellReview(this.jobId)
    .subscribe((result: any) => {
      // set default approve type
      if (result) {
        this.localWellReview  = Object.assign(new LocalWellReviewModel, result);
        const participantNames = result.activeDirectoryUsers.map( part => Object.assign( new AdUser, {...part}) );
        this.localWellReview.participantNames = participantNames;
      }
    });
  }

}


