export interface IToast {
    info: string;
    success: string;
    error: string;
    warn: string;
}

export const toastClasses: IToast = {
    info: 'pi-info-circle',
    success: 'pi-check',
    error: 'pi-times-circle',
    warn: 'pi-exclamation-triangle'
}