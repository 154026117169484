import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, share } from 'rxjs/operators';
import { SessionService } from './session.service';

@Injectable()
export class HttpService {

  // Used to prevent multiple server calls in a short period of time.
  private _pendingRequestsMap = new Map<string, Observable<any>>();

  constructor(public sessionService: SessionService, private http: HttpClient) {
  }

  public Get<T>(apiUrl: string): Observable<T> {

    const requestKey = `get_${ apiUrl }`;
    if (this._pendingRequestsMap.has(requestKey)) {

      return this._pendingRequestsMap[requestKey];
    }

    const request$: Observable<T> = this.http.get<T>(apiUrl).pipe(
      catchError(response => {
        return throwError(response);
      }),
      share(),
      finalize(() => {
        this._pendingRequestsMap.delete(requestKey);
      })
    );

    this._pendingRequestsMap[requestKey] = request$;

    return request$;
  }

  public GetWithParams<T>(apiUrl: string, params: HttpParams): Observable<T> {
    return this.http.get<T>(apiUrl, { params: params }).pipe(
      catchError(response => {
        return throwError(response);
      })
    );
  }

  public Post<T>(apiUrl: string, object: any): Observable<T> {

    const requestKey = `post_${ apiUrl }`;
    if (this._pendingRequestsMap.has(requestKey)) {

      return this._pendingRequestsMap[requestKey];
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    
    const request$: Observable<T> = this.http.post<T>(apiUrl, object, { headers: headers }).pipe(
      catchError(response => {
        return throwError(response);
      }),
      share(),
      finalize(() => {
        this._pendingRequestsMap.delete(requestKey);
      })
    );

    this._pendingRequestsMap[requestKey] = request$;

    return request$;
  }

  public Delete<T>(apiUrl: string): Observable<T> {

    const requestKey = `delete_${ apiUrl }`;
    if (this._pendingRequestsMap.has(requestKey)) {

      return this._pendingRequestsMap[requestKey];
    }

    const request$: Observable<T> = this.http.delete<T>(apiUrl).pipe(
      catchError(response => {
        return throwError(response);
      }),
      share(),
      finalize(() => {
        this._pendingRequestsMap.delete(requestKey);
      })
    );

    this._pendingRequestsMap[requestKey] = request$;

    return request$;
  }

  public Put<T>(apiUrl: string, objectJSON: string): Observable<T> {

    const requestKey = `put_${ apiUrl }`;
    if (this._pendingRequestsMap.has(requestKey)) {

      return this._pendingRequestsMap[requestKey];
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const request$: Observable<T> = this.http.put<T>(apiUrl, objectJSON, { headers: headers }).pipe(
      catchError(response => {
        return throwError(response);
      }),
      share(),
      finalize(() => {
        this._pendingRequestsMap.delete(requestKey);
      })
    );

    this._pendingRequestsMap[requestKey] = request$;

    return request$;
  }

  public Patch<T>(apiUrl: string, object: any): Observable<T> {

    const requestKey = `patch_${ apiUrl }`;
    if (this._pendingRequestsMap.has(requestKey)) {

      return this._pendingRequestsMap[requestKey];
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const request$: Observable<T> = this.http.patch<T>(apiUrl, object, { headers: headers }).pipe(
      catchError(response => {
        return throwError(response);
      }),
      share(),
      finalize(() => {
        this._pendingRequestsMap.delete(requestKey);
      })
    );

    this._pendingRequestsMap[requestKey] = request$;

    return request$;
  }
}
