export interface Role {
  groupId: string;
  roleId: number;
  roleName: string;
  level: number;
}

export const Roles = {
  Admin: 'Admin',
  User: 'User',
  Reviewer: 'Reviewer',
  Basic:'Basic',
  Enhanced:'Enhanced',
  Global:'Global',
  Region:'Region'
};

export const RoleLevels = {
    Basic: 1,
    Reviewer: 3,
    Enhanced: 2,
    Global: 5,
    Region: 4
  };
