export const SlurrySource = {
  ManualFluid: 1,
  LinkedFluid: 2,
  PumpScheduleFluid: 3,
  HDFFluid: 4,
};

export const MaterialNumberAssignedFlag = {
  Manual: 0,
  SAPFromMaterialTable: 1,
  SAPFromFluid: 2,
  FromMaterialTable: 3,
  FromFluid: 4
};