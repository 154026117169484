<div class="sidebar-content-title">
  <span class="badge-number">1</span>
  <h4>Download iFacts Lab Reports</h4>
</div>
<div class="sidebar-content-desc">
  <span>{{getSectionDescription()}}</span>
</div>
<div *ngIf="files.length > 0" class="sidebar-select-all">
  <p-checkbox (onChange)="checkAllFilesSelected($event)" name="selectAll" [disabled]="selectAllDisabled"
    [(ngModel)]="selectAllChecked" binary="true" label="Select all Lab Reports"></p-checkbox>
</div>
<div class="document-group-list" *ngIf="requestsWithStages.length > 0 && files.length > 0">
  <p-accordion [multiple]="true">
    <div *ngFor="let requestWithStages of requestsWithStages">
      <p-accordionTab [selected]="true" *ngIf="requestWithStages.files.length > 0">
        <ng-template pTemplate="header">
          <div class="w-100 d-flex justify-content-between stage-header">
            <div>
              <span>{{getTabStageHeader(requestWithStages.pumpSchedule, requestWithStages.stages)}}</span>
            </div>
            <div>ID: {{requestWithStages.requestId}}</div>
          </div>
        </ng-template>
        <div>Select File to Download</div>
        <table class="table file-list">
          <thead>
            <tr>
              <th>File Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of requestWithStages.files">
              <td>
                <div class="pcheckbox-wraplabel">
                  <div class="d-flex">
                    <div>
                      <p-checkbox (onChange)="checkSelection($event)" [name]="file.id" [disabled]="file.isDownloaded" binary="true"
                        [(ngModel)]="file.isSelected" [inputId]="file.requestId">
                      </p-checkbox>
                    </div>
                    <div class="file-label" (click)="toggleFile(file)" [class.file-label-disabled]="file.isDownloaded">
                      <div>{{file.fileName}}</div>
                      <div class="file-date">Created {{file.createdDate | dateFormatWithLocalTimeZone: DATE_TIME_CALL_STANDARD}}</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </p-accordionTab>
    </div>
  </p-accordion>
</div>