export enum EditJobTab {
  Header = 0,
  JobObjective = 1,
  Lessons = 2,
  Fluids = 3,
  PumpSchedule = 4,
  Wellbore = 5,
  CasingEquipment = 6,
  JobLog = 7,
}
