import { UntypedFormGroup, Validators } from '@angular/forms';
import { IPlacementMethod, PumpScheduleEventModel } from 'libs/models';
import { ConfirmDialogService } from 'libs/ui';
import { concat, from, Observable, Observer, of, Subscription } from 'rxjs';
import { filter, map, mergeAll, shareReplay, switchMap } from 'rxjs/operators';
import { PumpScheduleFormManager } from '../form-manager';
import { EventCalculator } from './calculators/event-calculator';
import { IFieldValue } from './stage-state-manager';
import { PumpScheduleStateFactory } from './state-factory';

interface IPlacementMethodChange {
    availableMethods: IPlacementMethod[];
    newMethodId: string;
    imported: boolean;
}

export class EventStateManager {

    public readonly form: UntypedFormGroup;

    public readonly calc: EventCalculator;

    private readonly _subscriptions = new Subscription();

    private readonly _selectedPlacementMethod$: Observable<IPlacementMethodChange> = this._availablePlacementMethods$
        .pipe(
            switchMap(methods => this.form.controls.placementMethod.valueChanges
                .pipe(
                    switchMap((newMethodId: string) => this._isChangingImportedMethod()
                        .pipe(
                            map(imported => {
                                return {
                                    availableMethods: methods,
                                    newMethodId: newMethodId,
                                    imported: imported
                                };
                            })
                        )
                    )
                )
            ),
            shareReplay()
        );

    public readonly placementMethod$: Observable<string> = this._selectedPlacementMethod$
        .pipe(
            filter(pm => {

                return pm.imported;
            }),
            map(ch => {

                return ch.newMethodId;
            })
        );

    public constructor(

        public model: PumpScheduleEventModel,

        public stageOrder: number,

        private readonly _isDrillingMudEvent: boolean,

        private readonly _isCementEvent: boolean,

        private readonly _availablePlacementMethods$: Observable<IPlacementMethod[]>,

        private readonly _confirmDialogService: ConfirmDialogService,

        private readonly _formManager: PumpScheduleFormManager,

        private readonly _stateFactory: PumpScheduleStateFactory
    ) {
        this.form = this._formManager.createEventForm(this.stageOrder, this.model.order);
        this._formManager.patchAloud(this.form, model);

        this._setControlsState();

        this.calc = this._createEventCalculator();

        this._subscribeToChanges();
    }

    public get isShutdown(): boolean {

        return this._isShutdown(this.model.placementMethodName);
    }

    public destroy() {

        this._subscriptions.unsubscribe();
    }

    public placementMethodChanged(): void {

        this.model.topOfFluid = null;
        this.model.length = null;

        this._formManager.patchAloud(
            this.form, {
            topOfFluid: this.model.topOfFluid,
            length: this.model.length
        }
        );
    }

    private _createEventCalculator(): EventCalculator {

        const rate$: Observable<number> = concat(
            of(this.model.rate),
            this.form.controls.rate.valueChanges
        )
            .pipe(
                map(v => Number(v)),
                shareReplay()
            );

        const volume$: Observable<number> = concat(
            of(this.model.volume),
            this.form.controls.volume.valueChanges
        )
            .pipe(
                map(v => Number(v)),
                shareReplay()
            );

        const duration$: Observable<number> = concat(
            of(this.model.duration),
            this.form.controls.duration.valueChanges
        )
            .pipe(
                map(v => Number(v)),
                shareReplay()
            );

        const isShutdown$: Observable<boolean> = this._availablePlacementMethods$
            .pipe(
                switchMap(availableMethods => {

                    // When importing from iCem there is no placement method name in event model.
                    // Find placement method and set both placment method id and placement method name.
                    this._setPlacementMethod(availableMethods);
                    this._setControlsState();

                    return concat(
                        of(this._isShutdown(this.model.placementMethodName)),
                        this._selectedPlacementMethod$
                            .pipe(
                                map(ch => {

                                    const newMethod =
                                        this._findPlacementMethod(ch.availableMethods, ch.newMethodId);

                                    return !newMethod ? false : this._isShutdown(newMethod.name);
                                })
                            )
                    );
                })
            )
            .pipe(
                shareReplay()
            );

        const topOfFluid$ = concat(
            of(this.model.topOfFluid),
            this.form.controls.topOfFluid.valueChanges
        )
            .pipe(
                map(v => Number(v)),
                shareReplay()
            );

        return new EventCalculator(rate$, volume$, duration$, topOfFluid$, isShutdown$);
    }

    private _subscribeToChanges(): void {

        this._subscriptions.add(
            this.form.valueChanges.subscribe(value => {
                const currentPumpScheduleModel = this._stateFactory.listPumpScheduleStageStateManager[this._stateFactory.currentIndex].model;
                if (currentPumpScheduleModel) {
                    currentPumpScheduleModel.stages[this.stageOrder].events[this.model.order] = value;
                    this._stateFactory.setListPumpScheduleStageStateManagerItemByPumpId(currentPumpScheduleModel);
                }
            })
        )
        this._subscriptions.add(
            from([
                this.calc.duration$
                    .pipe(
                        map(duration => {
                            return {
                                duration: duration,
                                default: 0
                            };
                        })
                    )
            ])
                .pipe(
                    mergeAll()
                )
                .subscribe((change: IFieldValue) => {

                    const field = Object.keys(change)[0];
                    const value = change[field];
                    const defVal = change.default;

                    this._setFieldValue(field, value, defVal);
                })
        );

        this._subscriptions.add(

            this.form.controls.rate.valueChanges.subscribe(value => {

                if (this.form.controls.rate.dirty) {

                    this._setFieldValue('rate', value);
                }
            })
        );

        this._subscriptions.add(

            this.form.controls.volume.valueChanges.subscribe(value => {

                if (this.form.controls.volume.dirty) {

                    this._setFieldValue('volume', value);
                }
            })
        );

        this._subscriptions.add(

            this.form.controls.duration.valueChanges.subscribe(value => {

                if (this.form.controls.duration.dirty) {

                    this._setFieldValue('duration', value);
                }
            })
        );

        this._subscriptions.add(

            this.form.controls.bulkCement.valueChanges.subscribe(value => {

                if (this.form.controls.bulkCement.dirty) {

                    this._setFieldValue('bulkCement', value);
                }
            })
        );

        this._subscriptions.add(

            this.form.controls.actualDuration.valueChanges.subscribe(value => {

                if (this.form.controls.actualDuration.dirty) {

                    this._setFieldValue('actualDuration', value);
                }
            })
        );

        this._subscriptions.add(

            this.form.controls.actualRate.valueChanges.subscribe(value => {

                if (this.form.controls.actualRate.dirty) {

                    this._setFieldValue('actualRate', value);
                }
            })
        );

        this._subscriptions.add(

            this._selectedPlacementMethod$.subscribe(change => {

                this._changePlacementMethod(change);
            })
        );

        if (this._stateFactory.viewState.isCP4InCompletionMode) {
            this._setRequiredValidators();
        }

        this._subscriptions.add(

            this._stateFactory.viewState.cp4Completion$.subscribe(completion => {
                if (completion.complete) {
                    this._setRequiredValidators(completion.pumpScheduleId, completion.isStageJob);
                }
            })
        );
    }

    private _toNumberOrDefault(value: number, defVal: number): number {

        if (value !== 0 && (!value || isNaN(Number(value)))) {

            return defVal;
        }

        return Number(value);
    }

    private _setFieldValue(field: string, value: number, defVal: number = null): void {

        if (defVal === undefined) {

            defVal = null;
        }

        const modelVal = this._toNumberOrDefault(value, defVal);
        this.model[field] = modelVal;

        const formPatch = {};
        const ctrl = this.form.controls[field];
        const currentCtrlVal = ctrl && ctrl.value;

        if (ctrl && currentCtrlVal !== value) {

            formPatch[field] = value;
            this._formManager.patchSilent(this.form, formPatch);
        }
    }

    private _findPlacementMethod(
        availablePlacementMethods: IPlacementMethod[],
        methodId: string
    ): IPlacementMethod {

        return availablePlacementMethods.find(pm => pm.id === methodId)
            || {
            id: null,
            name: null,
            description: null,
            order: null,
            isDisable: null
        };
    }

    private _setPlacementMethod(availableMethods: IPlacementMethod[]): void {

        let found = null;
        if (!!this.model.placementMethodName) {

            found = availableMethods.find(m => m.name === this.model.placementMethodName);

        } else if (!!this.model.placementMethod) {

            found = availableMethods.find(m => m.id === this.model.placementMethod);
        }

        if (found) {

            this.model.placementMethod = found.id;
            this.model.placementMethodName = found.name;
        } else {

            this.model.placementMethod = null;
            this.model.placementMethodName = null;
        }
    }

    private _changePlacementMethod(ch: IPlacementMethodChange): void {

        const newMethod = this._findPlacementMethod(ch.availableMethods, ch.newMethodId);

        if (ch.imported) {

            this._changeImportedPlacementMethod(newMethod);

        } else {

            this._changeKnownPlacementMethod(newMethod);
        }
    }

    private _isCurrentMethodImported(): boolean {

        return !this._isNoPlacement(this.model.placementMethodName)
            && !this._isShutdown(this.model.placementMethodName)
            && !this._isVolume(this.model.placementMethodName);
    }

    private _changeImportedPlacementMethod(newMethod: IPlacementMethod): void {

        const changes = this._isShutdown(newMethod.name)
            ? this._getShutdownPlacementChange()
            : this._getVolumePlacementChange();

        this._updateOnPlacementMethodChange(newMethod, changes);
    }

    private _changeKnownPlacementMethod(newMethod: IPlacementMethod): void {

        let changes = {};
        if (this._isShutdown(newMethod.name)) {

            changes = this._getShutdownPlacementChange();

        } else if ((this._isNoPlacement(newMethod.name)
            || this._isVolume(newMethod.name)) && (!this.model.rate || !this.model.volume)) {

            changes = this._getOtherPlacementChange();

        }

        this._updateOnPlacementMethodChange(newMethod, changes);
    }

    private _updateOnPlacementMethodChange(
        newMethod: IPlacementMethod,
        changes: object): void {

        this.model.placementMethod = newMethod.id;
        this.model.placementMethodName = newMethod.name;

        const props = Object.keys(changes);
        for (const p of props) {

            this.model[p] = changes[p];
        }

        this._formManager.patchSilent(this.form, changes);

        this._setControlsState();
    }

    private _revertPlacementMethodDisplay(): void {

        const changes = {
            placementMethod: this.model.placementMethod
        };

        this._formManager.patchSilent(this.form, changes);
    }

    private _getVolumePlacementChange(): object {

        return {
            length: null,
            topOfFluid: null,
            actualDuration: null
        };
    }

    private _getShutdownPlacementChange(): object {

        return {
            rate: null,
            actualRate: null,
            volume: null,
            bulkCement: null,
            ...this._getOtherPlacementChange()
        };
    }

    private _getOtherPlacementChange(): object {

        return {
            duration: 0,
            ...this._getVolumePlacementChange()
        };
    }

    private _isChangingImportedMethod(): Observable<boolean> {

        if (!this._isCurrentMethodImported()) {

            return of(false);
        }

        return new Observable((observer: Observer<boolean>) => {

            const accept = () => {

                observer.next(true);
                observer.complete();
            };

            const reject = () => {

                this._revertPlacementMethodDisplay();
                observer.complete();
            };

            this._confirmDialogService.show({
                message: `Are you sure you want to change to the other placement methods?`,
                header: 'Confirmation',
                accept: accept,
                reject: reject,
                acceptLabel: 'Yes',
                rejectLabel: 'No'
            });

            return () => {

                this._confirmDialogService.hide();
            };
        });
    }

    private _isNoPlacement(placementMethodName: string): boolean {

        return !placementMethodName;
    }

    private _isShutdown(placementMethodName: string): boolean {

        return placementMethodName === 'Shutdown';
    }

    private _isVolume(placementMethodName: string): boolean {

        return placementMethodName === 'Volume';
    }

    private _setControlsState(): void {

        const options = PumpScheduleFormManager.silent;

        if (this.isShutdown) {

            this.form.controls.rate.disable(options);
            this.form.controls.volume.disable(options);
            this.form.controls.duration.enable(options);

            if (!this._isCementEvent) {

                this.form.controls.bulkCement.disable(options);

            }

        } else {

            this.form.controls.rate.enable(options);
            this.form.controls.duration.disable(options);
            this.form.controls.bulkCement.enable(options);

            if (!this._isDrillingMudEvent) {

                this.form.controls.volume.enable(options);
            }
        }
    }

    private _setRequiredValidators(pumpScheduleId: string = null, isStageJob: boolean = false): void {
        if (isStageJob || pumpScheduleId === this.form.controls.actualRate.parent.parent.parent.parent.parent.value.pumpScheduleId) {
            if (!this.isShutdown) {
                if (!this._isDrillingMudEvent) {
                    const reassignValue = this.form.controls.actualRate.value;
                    this.form.controls.actualRate.setValidators(Validators.required);
                    this.form.controls.actualRate.markAsTouched();
                    this.form.controls.actualRate.patchValue(reassignValue);
                    this.form.controls.actualRate.updateValueAndValidity();
                }

            } else {

                const reassignValue = this.form.controls.actualDuration.value;
                this.form.controls.actualDuration.setValidators(Validators.required);
                this.form.controls.actualDuration.markAsTouched();
                this.form.controls.actualDuration.patchValue(reassignValue);
                this.form.controls.actualDuration.updateValueAndValidity();
            }
        }
    }
}
