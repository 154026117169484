import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadingService {

  constructor() { }

  public loadEditJobModule(): Promise<any> {
    return import('../../../apps/vida/src/modules/edit-job/edit-job.module').then(m => m.EditJobModule);
  }

  public loadWellImportDialog(): Promise<any> {
    return import('../../../apps/vida/src/modules/well/well.module').then(m => m.WellModule);
  }
  
  public loadCreateWellModule(): Promise<any> {
    return import('../../../apps/vida/src/modules/well/create-well.module').then(m => m.CreateWellModule);
  }

  public loadJobCreateModule(): Promise<any> {
    return import('../../../apps/vida/src/modules/job-create/job-create.module').then(m => m.JobCreateModule);
  }

  public loadAdminModule(): Promise<any> {
    return import('../../../apps/vida/src/modules/administrations/administrations.module').then(m => m.AdministrationsModule);
  }

  public loadAdvancedSearchModule(): Promise<any> {
    return import('../../../apps/vida/src/modules/advance-search-results/advance-search-results.module').then(m => m.AdvanceSearchResultModule);
  }

  public loadVisualizerModule(): Promise<any> {
    return import('../../../apps/vida/src/modules/ifacts-visualizer/ifacts-visualizer.module').then(m => m.IfactsVisualizerModule);
  }

  public loadIFactsSearchModule(): Promise<any> {
    return import('../../../apps/vida/src/modules/ifacts-search/ifacts-search.module').then(m => m.IfactsSearchModule);
  }
}
