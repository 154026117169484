import { UntypedFormGroup } from '@angular/forms';
import { CustomUnit, DeadVolumeFluidType, DeadVolumeFluidTypes, DeadVolumeFluidTypesForMixFluid, FLUIDS_CONTANTS, SlurrySource, SpacerMixMethod, UnitType } from 'libs/constants';
import { FluidMaterialModel, FluidModel, ISlurryType, PumpScheduleStageMaterialModel, PumpScheduleStageModel } from 'libs/models';
import { PumpScheduleStageMaterialCOGSModel } from 'libs/models/entities/pump-schedule-stage-cogs.model';
import { MaterialService } from 'libs/shared/services';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { SelectItem } from 'primeng/api';
import { concat, BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { MissingDataMaterial } from '../../shared/models/no-cogs-material.model';
import { PumpScheduleFormManager } from '../form-manager';
import { ViewState } from '../view-state';
import { StageFluidCalculator } from './calculators/stage-calculator';
import { FluidMaterialStateManager } from './material-state-manager';
import { PumpScheduleStateFactory } from './state-factory';
import { isObjectOfCertainType, isPrimitive } from 'libs/helpers/type.helper';

export class FluidStateManager {

  private readonly _subscriptions = new Subscription();

  public readonly model$: Observable<FluidModel> = this._model$
    .pipe(shareReplay());

  private readonly _materialsStates$: Observable<FluidMaterialStateManager[]> = this._materialsSrc
    .asObservable().pipe(shareReplay());

  private readonly _isMixFluid$: Observable<boolean> = this.model$
    .pipe(
      map(fluid => {

        return this._isMixFluid(fluid);
      }),
      shareReplay()
    );

  public readonly blendMaterials$: Observable<PumpScheduleStageMaterialModel[]> = this._materialsStates$
    .pipe(
      map(states => {

        return this._filterBlendMaterials(states).map(s => s.model);
      }),
      shareReplay()
    );

  public readonly additives$: Observable<PumpScheduleStageMaterialModel[]> = this._materialsStates$
    .pipe(
      map(states => {
        return this._filterAdditives(states).map(s => s.model);
      }),
      shareReplay()
    );

  public readonly supplementals$: Observable<PumpScheduleStageMaterialModel[]> = this._materialsStates$
    .pipe(
      map(states => {

        return this._filterSupplementals(states).map(s => s.model);
      }),
      shareReplay()
    );

  public readonly blendMaterialsCount$: Observable<number> = this.blendMaterials$
    .pipe(
      map(m => {

        return m.length;
      }),
      shareReplay()
    );

  public readonly additivesCount$: Observable<number> = this.additives$
    .pipe(
      map(m => {

        return m.length;
      }),
      shareReplay()
    );

  public readonly supplementalsCount$: Observable<number> = this.supplementals$
    .pipe(
      map(m => {

        return m.length;
      }),
      shareReplay()
    );

  public readonly isFoam$: Observable<boolean> = this.model$
    .pipe(
      map(model => {

        return model.isFoam;
      }),
      shareReplay()
    );

  public readonly labName$: Observable<string> = this.model$
    .pipe(
      map(model => {

        return model.labName;
      }),
      shareReplay()
    );

  public readonly blendName$: Observable<string> = this.model$
    .pipe(
      map(model => {

        return model.blendName;
      }),
      shareReplay()
    );

  public readonly cementName$: Observable<string> = this.model$
    .pipe(
      map(_ => {

        return this._getCementName();
      }),
      shareReplay()
    );

  public readonly water$: Observable<string> = this.model$
    .pipe(
      map(model => {

        return model.water;
      }),
      shareReplay()
    );

  public readonly sackWeight$: Observable<number> = this.model$
    .pipe(
      map(model => {

        return model.sackWeight;
      }),
      shareReplay()
    );

  public readonly bulkCementDisabled$: Observable<boolean> = this.model$
    .pipe(
      map(model => {

        const apiUnitMeasure = this._unitConversionService.getApiUnitMeasure(UnitType.BaseMaterialWeight);
        const currentUnitMeasure = this._unitConversionService.getCurrentUnitMeasure(UnitType.BaseMaterialWeight);

        return currentUnitMeasure.name !== apiUnitMeasure.name && !model.sackWeight && model.sackWeight !== 0;

      }),
      shareReplay()
    );


  public readonly yield$: Observable<number> = this.model$
    .pipe(
      map(model => {

        return model.yield;
      }),
      shareReplay()
    );

  public readonly foamQuality$: Observable<number> = this.model$
    .pipe(
      map(model => {

        return model.foamQuality;
      }),
      shareReplay()
    );

  public readonly hasBlendMaterials$: Observable<boolean> = this.blendMaterials$
    .pipe(
      map(blendMaterials => {

        return blendMaterials.length > 0;
      }),
      shareReplay()
    );

  public readonly dropdownDeadVolumeItems$: Observable<SelectItem[]> =
    combineLatest([
      this._isMixFluid$,
      this.supplementalsCount$
    ])
      .pipe(
        map(([isMixFluid, supplementalsCount]) => {

          return this._getDeadVolumeDropdownItems(isMixFluid, supplementalsCount);
        }),
        shareReplay()
      );

  private readonly _slurryTypeUpdate$: Observable<FluidModel> =
    combineLatest([
      this.model$,
      this._availableSlurryTypes$
    ])
      .pipe(
        switchMap(([model, availableSlurryTypes]) => {

          return concat(
            of(model),
            this._slurryTypeChanges$
              .pipe(
                filter(fluid => {

                  return this._isSameFluid(model, fluid);
                }),
                map(fluid => {

                  return this._updateSlurryType(model, fluid, availableSlurryTypes);
                })
              )
          );
        }),
        shareReplay()
      );

  private readonly _slurryType$: Observable<string> = this._slurryTypeUpdate$
    .pipe(
      map(fluid => {

        return fluid.slurryTypeId;
      }),
      shareReplay()
    );

  public readonly mixWaterUnit$: Observable<string> =
    combineLatest([
      this._slurryType$,
      this._availableSlurryTypes$,
      this._spacerMixMethod$
    ])
      .pipe(
        map(([slurryTypeId, slurryTypes, mixMethod]) => {

          return this._getMixWaterUnit(slurryTypeId, slurryTypes, mixMethod);
        })
      );

  public readonly isIFactsCementFluid$: Observable<boolean> = this._slurryTypeUpdate$
    .pipe(
      map(fluid => {

        return FluidStateManager.isIFactsCementFluid(fluid);
      }),
      shareReplay()
    );

  public readonly materialsCogs$: Observable<PumpScheduleStageMaterialCOGSModel[]> = this._materialsStates$
    .pipe(
      switchMap(materialsStates =>
        combineLatest(materialsStates.map(s => s.cogs$))
      ),
      shareReplay()
    );

  public readonly noCogsMaterials$: Observable<MissingDataMaterial[]> = this._materialsStates$
    .pipe(
      switchMap(materialStates =>
        combineLatest(materialStates.map(s => s.noCogs$))
      ),
      map(noCogsList => {

        return noCogsList.filter(noCogs => !!noCogs);
      }),
      shareReplay()
    );

  public readonly noBulkDensityMaterials$: Observable<MissingDataMaterial[]> = this._materialsStates$
    .pipe(
      switchMap(materialStates =>
        combineLatest(materialStates.map(s => s.noBulkDensity$))
      ),
      map(noBulkDensityList => {

        return noBulkDensityList.filter(noBulkDensity => !!noBulkDensity);
      }),
      shareReplay()
    );

  private _baseCementMaterial: FluidMaterialModel = null;

  public id: number;
  private static idCounter = 0;
  public totalBlendCo2e = null;
  public totalBlendActualCO2e = null;
  public constructor(

    public readonly form: UntypedFormGroup,

    private readonly _model$: Observable<FluidModel>,

    private readonly _stageModel: PumpScheduleStageModel,

    private readonly _materialsSrc: BehaviorSubject<FluidMaterialStateManager[]>,

    private readonly _stageCalc: StageFluidCalculator,

    private readonly _slurryTypeChanges$: Observable<FluidModel>,

    private readonly _availableSlurryTypes$: Observable<ISlurryType[]>,

    private readonly _spacerMixMethod$: Observable<SpacerMixMethod>,

    private readonly _materialService: MaterialService,

    private readonly _unitConversionService: UnitConversionService,

    private readonly _formManager: PumpScheduleFormManager,

    private readonly _stateFactory: PumpScheduleStateFactory
  ) {
    this.id = FluidStateManager.idCounter;
    FluidStateManager.idCounter++;

    const stageFormPart = this._getStageFormPartValue(this._stageModel);
    this._formManager.patchSilent(this.form, stageFormPart);
    this.totalBlendCo2e = this._stageModel.totalBlendCO2e;
    this.totalBlendActualCO2e = this._stageModel.totalBlendActualCO2e;
    this._subscriptions.add(this.model$.subscribe(model => this.updateFluidModel(model)));
  }

  public updateFluidModel(model: FluidModel): void {
    if (isPrimitive(model)) {
      return;
    }

    this._formManager.clearMaterialForms(this._stageModel.order);

    const fluidFormPart = this._getFluidFormPartValue(model);

    this._formManager.patchSilent(this.form, fluidFormPart);

    if (!!model) {

      this._baseCementMaterial =
        this._materialService.getCementMaterial(model.fluidMaterial);
    }

    this._resetStageMaterialModels();
    this._resetMaterialsStates(model);
  }

  public get viewState(): ViewState {

    return this._stateFactory.viewState;
  }

  public static isIFactsCementFluid(fluid: FluidModel): boolean {

    return !!fluid.requestId && !!fluid.slurryType && fluid.slurryType.indexOf('Cement') >= 0;
  }

  public destroy(): void {

    this._subscriptions.unsubscribe();
    this._materialsSrc.value.forEach(s => s.destroy());
  }

  public getMaterialState(material: PumpScheduleStageMaterialModel): FluidMaterialStateManager {

    if (material.fluidMaterialId) {

      const materialState = this._materialsSrc.value.find(s => s._fluidMaterialModel.id === material.fluidMaterialId);
      if (materialState) {

        return materialState;
      }
    }

    if (material.order > 0) {

      const materialState = this._materialsSrc.value.find(s => s._fluidMaterialModel.order === material.order);
      if (materialState) {

        return materialState;
      }
    }

    return this._materialsSrc.value.find(s => s._fluidMaterialModel.cemBlendCompId === material.cemBlendCompId);
  }

  private _resetMaterialsStates(model: FluidModel): void {

    if (this._formManager.isStageFluidDirty(this._stageModel.order)) {
      this.viewState.isNewMaterialCOGSCalculation = true;
    }

    this._materialsSrc.value.forEach(s => s.destroy());

    let materialsStates = [];
    if (!!model) {

      materialsStates = this._createMaterialStates(model);
    }

    this._materialsSrc.next(materialsStates);
    this.viewState.isNewMaterialCOGSCalculation = false;
  }

  private _filterBlendMaterials(states: FluidMaterialStateManager[]): FluidMaterialStateManager[] {

    return states.filter(s => s.isBlend);
  }

  private _filterAdditives(states: FluidMaterialStateManager[]): FluidMaterialStateManager[] {

    return states.filter(s => s.isAdditive);
  }

  private _filterSupplementals(states: FluidMaterialStateManager[]): FluidMaterialStateManager[] {

    return states.filter(s => s.isSupplemental);
  }

  private _resetStageMaterialModels(): void {

    // Possible when Pump Shedule is imported from iCem or HDF
    if (!this._stageModel.fluidMaterials) {
      this._stageModel.fluidMaterials = [];
    }
  }

  private _createMaterialStates(fluidModel: FluidModel)
    : FluidMaterialStateManager[] {

    const blendComponentsCount = fluidModel.fluidMaterial
      .filter(m => {
        return this._materialService.isCement(m);
      })
      .length;

    // Vietnam logic to assign added supplemental materials order
    let maxMaterialOrder = fluidModel.fluidMaterial                         // Water
      .filter(m => !this._materialService.isSupplemental(m) && m.order && m.order !== 100)
      .map(m => m.order)
      .max();

    fluidModel.fluidMaterial
      .filter(m => this._materialService.isSupplemental(m))
      .forEach(m => m.order = ++maxMaterialOrder);
    // End of Vietnam logic.

    if (this._stageModel && this._stageModel.fluidMaterials && this._stageModel.fluidMaterials.length > 0) {
      // Remove stages materials data for materials not found in fluid.
      this._stageModel.fluidMaterials = this._stageModel.fluidMaterials
        .filter(stageMaterial => !!stageMaterial.fluidMaterialId
          && fluidModel.fluidMaterial.some(fm => fm.id === null || fm.id === stageMaterial.fluidMaterialId)
        );
    }

    const materialsStates = fluidModel.fluidMaterial
      .map(fluidMaterial => {

        let stageMaterial = null;
        if (!this._stageModel.fluidMaterials) {
          this._stageModel.fluidMaterials = [];
        }
        if (fluidMaterial.id) {

          stageMaterial = this._stageModel.fluidMaterials.find(sfm => sfm.fluidMaterialId === fluidMaterial.id);

        } else if (fluidMaterial.order !== 0) {

          stageMaterial = this._stageModel.fluidMaterials.find(sfm => sfm.order === fluidMaterial.order);

        } else if (fluidMaterial.cemBlendCompId !== '0') {

          stageMaterial = this._stageModel.fluidMaterials.find(sfm => sfm.cemBlendCompId === fluidMaterial.cemBlendCompId);
        }

        if (!stageMaterial) {

          stageMaterial = new PumpScheduleStageMaterialModel();
          this._stageModel.fluidMaterials.push(stageMaterial);
        }

        stageMaterial.fluidMaterialId = fluidMaterial.id;
        stageMaterial.ifactMaterialId = fluidMaterial.materialId;
        stageMaterial.order = fluidMaterial.order;
        stageMaterial.cemBlendCompId = fluidMaterial.cemBlendCompId;
        stageMaterial.fluidMaterialName = fluidMaterial.materialName;
        stageMaterial.concentration = fluidMaterial.concentration;
        stageMaterial.concentrationUnit = fluidMaterial.concentrationUnit || fluidMaterial.fluidUnitMeasureName;
        stageMaterial.plannedVolumeUnit = fluidMaterial.plannedVolumeUnit;
        stageMaterial.loadoutVolume = fluidMaterial.loadoutVolume;
        stageMaterial.loadoutVolumeUnit = fluidMaterial.loadoutVolumeUnit;
        stageMaterial.sapMaterialNumber = fluidMaterial.sapMaterialNumber;
        stageMaterial.sapMaterialName = fluidMaterial.sapMaterialName;
        stageMaterial.materialType = fluidMaterial.materialType;

        if (this._materialService.isWater(fluidMaterial.materialType)) {

          return null;
        }

        return this._stateFactory.createFluidMaterialState(
          stageMaterial,
          fluidMaterial,
          this._baseCementMaterial,
          this._stageModel.order,
          fluidModel,
          blendComponentsCount,
          this._stageCalc,
          this._stageModel
        );
      })
      .filter(ms => !!ms);

    return materialsStates;
  }

  private _isMixFluid(fluid: FluidModel): boolean {

    const additiveMaterials = fluid.fluidMaterial ? fluid.fluidMaterial
      .filter(m => m.materialType === FLUIDS_CONTANTS.ADDITIVE && !m.isBlendComponent)
      : [];

    return additiveMaterials.length > 0 && additiveMaterials.every(m => !!m.mixingProcedureValue);
  }

  private _getCementName(): string {
    return this._baseCementMaterial ? this._baseCementMaterial.materialName : null;
  }

  private _getMixWaterUnit(slurryTypeId: string, slurryTypes: ISlurryType[], spacerMixMethod: SpacerMixMethod): string {

    const slurryType = slurryTypes.find(st => st.id === slurryTypeId);

    if (spacerMixMethod === SpacerMixMethod.MixOnTheFly || !slurryType || slurryType.name !== 'Spacer') {

      return null;
    }

    const unit = this._unitConversionService.getCurrentUnitMeasure(UnitType.LargeVolume);

    if (spacerMixMethod === SpacerMixMethod.BatchMix) {

      if (unit.name === 'bbl') {

        return CustomUnit.galbbl;
      }

      if (unit.name === 'm3') {

        return CustomUnit.lm3;
      }
    }

    return null;
  }

  private _getDeadVolumeDropdownItems(isMixFluid: boolean, supplementalsCount: number): SelectItem[] {

    if (isMixFluid) {
      return DeadVolumeFluidTypes;

    } else {

      return DeadVolumeFluidTypesForMixFluid;
    }
  }

  private _getStageFormPartValue(stageModel: PumpScheduleStageModel): { [key: string]: any } {

    if (!stageModel.deadVolumeFluidType) {

      stageModel.deadVolumeFluidType = DeadVolumeFluidType.CementSlurry;
    }

    if (!stageModel.deadVolume) {

      stageModel.deadVolume = 0;
    }

    const formValue = {
      waterRequirements: stageModel.waterRequirements,
      stageWaterTotal: stageModel.stageWaterTotal,
      plannedVolume: stageModel.plannedVolume,
      loadoutVolume: stageModel.loadoutVolume,
      deadVolume: stageModel.deadVolume,
      isManuallyDeadVolume: stageModel.isManuallyDeadVolume || false,
      deadVolumeFluidType: stageModel.deadVolumeFluidType,
      bulkCement: stageModel.bulkCement,
    };

    return formValue;
  }

  private _getFluidFormPartValue(model: FluidModel): { [key: string]: any } {

    let value = null;
    if (!model) {

      value = new FluidModel();
    } else {

      value = { ...model };
    }

    if (Number(this.form.controls.deadVolumeFluidType.value) !== this._stageModel.deadVolumeFluidType) {
      value['deadVolumeFluidType'] = this._stageModel.deadVolumeFluidType;
    }

    const formValue = {
      ...value,
      cementName: this._getCementName(),
      sapMaterialDisplayName: value.sapMaterialNumber
    };

    return formValue;
  }

  private _updateSlurryType(
    model: FluidModel,
    updatedFluid: FluidModel,
    availableSlurryTypes: ISlurryType[]
  ): FluidModel {

    model.slurryTypeId = updatedFluid.slurryTypeId;
    const foundSlurryType = availableSlurryTypes.find(st => st.id === model.slurryTypeId);

    if (!!foundSlurryType) {

      model.slurryType = foundSlurryType.name;
    }

    return model;
  }

  private _isSameFluid(fluid1: FluidModel, fluid2: FluidModel): boolean {

    if (!!fluid1.id) {

      return fluid1.id === fluid2.id;

    } else if (!!fluid1.requestId && !!fluid1.slurryNo) {

      return fluid1.requestId === fluid2.requestId && fluid1.slurryNo === fluid2.slurryNo;

    } else if (fluid1.slurrySource === SlurrySource.HDFFluid) {

      return fluid1.slurryIdHDF === fluid2.slurryIdHDF;
    }

    return !!fluid1.displayName && fluid1.displayName === fluid2.displayName;
  }
}
