export enum FileStatus {

    Preparing = 1,

    Uploading = 3,

    Cancelled = 6,

    Success = 7,

    Finalizing = 8,

    Failed = 9
}
