import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CustomValidators } from 'apps/vida/src/modules/shared/validators';
import { ERROR_TYPE } from 'libs/constants';
import { ErrorMessageModel } from 'libs/ui/validations';
import { ConfirmationDialogModel } from 'libs/ui/models';


@Component({
  selector: 'confirmation-dialog-container',
  templateUrl: './confirmation-dialog.container.html',
  styleUrls: ['./confirmation-dialog.container.scss']
})
export class ConfirmationDialogContainer {
  isVisible: boolean = false;
  confirmationDialogForm: UntypedFormGroup = this.fb.group({ comment: [''] });
  configs: any = {};
  placeholder: string = '';
  private defaultConfigs: ConfirmationDialogModel = {
    header: 'Confirmation',
    message: 'Are you sure that you want to proceed?',
    commentFieldTitle: null,
    commentFieldRequired: false,
    acceptLabel: 'Yes',
    rejectLabel: 'No',
    errorMessages: [],
    labelConfirm: 'CONFIRM',
    placeholder: '',
    length: '',
    type: '',
    dialogStyle: {width: '600px', height: '300px'},
    hideTextarea: false,
    rejectAsButton: false
  };

  constructor(private fb: UntypedFormBuilder) {
    this.confirmationDialogForm = this.fb.group({ comment: null });
  }

  confirm(options: ConfirmationDialogModel, autoHide = true) {
    this.configs = Object.assign({}, this.defaultConfigs, options);
    this.confirmationDialogForm.controls.comment.setValue(options.comment);
    setTimeout(() => {
      this.confirmationDialogForm.controls.comment.markAsUntouched();
    }, 100); //work-around
    this.isVisible = true;

    if (options.commentFieldRequired === true) {
      this.confirmationDialogForm.controls['comment'].setValidators(CustomValidators.requiredWithTrimming);
      this.confirmationDialogForm.controls['comment'].updateValueAndValidity();
      this.configs.errorMessages = [
        new ErrorMessageModel(ERROR_TYPE.REQUIRED, this.configs.commentFieldRequiredErrorMessage || 'Comment field is required.')
      ];
    } else {
      this.confirmationDialogForm.controls['comment'].setValidators(null);
      this.confirmationDialogForm.controls['comment'].updateValueAndValidity();
    }

    this.accept = () => {
      this.confirmationDialogForm.controls.comment.markAsDirty();
      if (options.accept) {
        if (!this.confirmationDialogForm.invalid) {
          const formData = { comment: this.confirmationDialogForm.get('comment').value };
          options.accept(formData.comment);
          this.hide(autoHide);
        }
      } else {
        this.hide(autoHide);
      }
    };

    this.reject = () => {
      if (options.reject) {
        options.reject();
      }
      this.hide();
    };
  }

  hide(autoHide = true) {
    if (autoHide) {
      this.reset();
      this.isVisible = false;
    }
  }

  reject() { }
  accept() { }

  private reset(autoHide = true) {
    if (autoHide) {
      this.accept = () => { };
      this.reject = () => { };
      this.confirmationDialogForm.reset();
      this.confirmationDialogForm.clearValidators();
      this.confirmationDialogForm.updateValueAndValidity();
      this.configs = {};
      this.accept = () => { };
      this.reject = () => { };
    }
  }

  displayTextError(control: string) {
    const formControl = this.confirmationDialogForm.controls[control];
    const error = this.configs && this.configs.errorMessages && this.configs.errorMessages.find((x: ErrorMessageModel) => formControl && formControl.errors && formControl.errors[x.errorType]);
    return formControl.dirty && error != null ? error.errorMessage : '';
  }


}
