import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MODAL_ID } from 'libs/constants/modal-id.constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationStateService, EventHubService, UserSettingService } from 'libs/shared/services';
import { GroupTabModel, MissingDataMaterial } from '../../models/no-cogs-material.model';

@Component({
    selector: 'no-material-data',
    templateUrl: './no-material-data-modal.component.html',
    styleUrls: ['./no-material-data-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoMaterialDataComponent implements OnInit {
    public dataType: string = 'COGS';
    public missingDataMaterials: MissingDataMaterial[] = [];
    public currentGroup: string = null;
    public jobGroupId: string;

    cols = [
        { field: 'ifactsMeterilaName', header: 'iFacts Material Name', width: '45%' },
        { field: 'sapMaterialName', header: 'SAP Material', width: '40%' },
        { field: 'appearenceInSlurrySet', header: 'Appearances in This Slurry Set', width: '15%' }
    ];

    constructor(
        private bsModalService: BsModalService,
        private userSettingService: UserSettingService,
        public modalManagementService: ApplicationStateService,
        public eventHubService: EventHubService) {
        }

    ngOnInit(): void {
        if (!this.currentGroup) {
            this.currentGroup = this.userSettingService.selectedLocation.displayName;
        }
    }

    onClose() {
        this.bsModalService.hide(MODAL_ID.NO_COGS);
    }

    onMaterialsLink() {
        const groupTab = new GroupTabModel(3, this.jobGroupId);
        this.eventHubService.useGroupFromJob$.next(groupTab);
        this.modalManagementService.openSecurityAdmin$.next(groupTab);
    }
}