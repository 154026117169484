import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { of, timer, Observable } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { ApplicationStateService, CollectionService } from 'libs/shared/services';
import { PersonalCollectionService } from '../services';
import { CollectionModel } from '../models';
import { ValidatorSetting } from '../../../shared/constant';
import { CustomValidators } from '../../../shared/validators';
import { ErrorMessageModel } from 'libs/ui';
import { ERROR_TYPE, ERROR_MESSAGE, HELP_FILES } from 'libs/constants';
import { htmlEntities } from 'libs/helpers';
import { environment } from 'libs/environment';

@Component({
  selector: 'collection-sidebar',
  templateUrl: './collection-sidebar.component.html',
  styleUrls: ['./collection-sidebar.component.scss'],
  providers: [PersonalCollectionService]
})
export class CollectionSidebarComponent implements OnInit {

  isShowCollection: boolean = false;
  name: string;
  description: string;
  collectionModel: CollectionModel;

  collection: UntypedFormGroup;
  collections = [];

  errorMessages = {
    collectionName: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Collection Name')),
      new ErrorMessageModel(ERROR_TYPE.MaxLength, ValidatorSetting.ERROR_MAX_LENGHT_100_TEXT),
      new ErrorMessageModel(ERROR_TYPE.UNIQUE, ValidatorSetting.ERROR_UNIQUE_COLLECTION_NAME),
    ]
  };

  constructor(
    private applicationStateService: ApplicationStateService,
    private messageService: MessageService,
    private personalCollectionService: PersonalCollectionService,
    private collectionService: CollectionService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.collection = this.fb.group({
      collectionName: ['', Validators.compose([CustomValidators.requiredWithTrimming, Validators.maxLength(ValidatorSetting.MAX_LENGTH_TEXT_100)]), checkCollectionNameValidator(this.personalCollectionService)],
      collectionDescriptions: ['']
    });

    this.applicationStateService.openCollection$.subscribe(create => {
      if (create == null) {
        this.collection.reset();
      }
      this.isShowCollection = true;
    });
  }

  createCollection() {
    if (this.collection.valid) {
      this.collectionModel = {
        id: '',
        name: this.collection.controls['collectionName'].value.trim(),
        description: this.collection.controls['collectionDescriptions'].value,
        jobId: '',
        mode: '',
        CollectionIdOld: ''
      };

      this.personalCollectionService.createCollection(this.collectionModel).pipe(
        catchError((response: any) => of(response.error.text))
      ).subscribe(() => {
        this.isShowCollection = false;
        const collectionName = htmlEntities(this.collectionModel.name);
        this.collectionService.saveCollectionSuccess$.next(true);
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', summary: '', detail: ` Collection <strong>${collectionName}</strong> has been created successfully.` });
        this.collectionService.getAllCollections().subscribe((data) => {
          this.collections = data;
        });
      });
    } else {
      this.validateAllFormFields(this.collection);
    }
  }

  openHelp() {
    window.open(HELP_FILES.Create_Collection);
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}

export function checkCollectionNameValidator(personalCollectionService: PersonalCollectionService) {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    return timer(1000).pipe(switchMap(() => {
      const collectionCheckNameModel = {
        id: '',
        name: control.value.trim(),
        description: '',
        jobId: '',
        mode: '',
        CollectionIdOld: ''
      };

      if (control.value && control.value.trim()) {
        return personalCollectionService.checkCollectionName(collectionCheckNameModel).pipe(
          map(res => {
            return (res === true) ? { unique: ValidatorSetting.ERROR_UNIQUE_COLLECTION_NAME } : null;
          })
        );
      }
      return of(null);
    }));
  };
}
