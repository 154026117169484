import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject, Input,
  OnInit,
  Provider, Renderer2,
  ViewChild,
  ViewEncapsulation, ViewRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconRegistry } from 'apps/vida/src/modules/pump/components/mix-on-the-fly/icon-registry.service';
import { DOCUMENT } from '@angular/common';
import { OverlayPanel } from 'primeng/overlaypanel';
import { PumpScheduleStageMaterialModel } from 'libs/models';
import { EventHubService } from 'libs/shared/services';

export const MIX_ON_THE_FLY_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MixOnTheFlyComponent),
  multi: true
};



const alignOld = OverlayPanel.prototype.align;

/*OverlayPanel.prototype.align = function () {
  alignOld.call(this);
};*/


@Component({
  selector: 'pump-mix-on-the-fly',
  template: `
    <div class="mix-on-the-fly-container">
    <p-overlayPanel data-mix #op dismissable="true">
        <ng-template pTemplate>
          <div class="mix-on-the-fly-popup-content">
            <ul class="mix-on-the-fly-list" role="listbox">
            <ng-container *ngFor="let option of options">
              <li class="mix-on-the-fly-item" (click)="selectItem($event, option)">
                <div class="item-row">
                    <svg-icon class="mix-icon" [icon]="option.icon"></svg-icon>
                    <div class="item-text">{{option.label}}</div>
                </div>
              </li>
            </ng-container>
            </ul>
          </div>
        </ng-template>
    </p-overlayPanel>
      <div>
        <a role="button" class="svg-icon-button" [ngClass]="{'svg-icon-button-disabled': disabled}" (click)="show($event)" [title]="selectedOption?.label || ''">
          <div class="svg-icon-wrapper">
            <svg-icon *ngIf="selectedOption" [icon]="selectedOption.icon"></svg-icon>
          </div>
        </a>
      </div>
    </div>
  `,
  styleUrls: ['./mix-on-the-fly-component.scss'],
  providers: [MIX_ON_THE_FLY_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // encapsulation: ViewEncapsulation.None
})
export class MixOnTheFlyComponent implements ControlValueAccessor, OnInit {

  @ViewChild('icon', { read: ElementRef })
  private icon: ElementRef;

  @ViewChild('op')
  private panel: OverlayPanel;

  private _options: any[] = [
    {
      value: '5d11ae9b-4f15-4cc6-bf1e-e51845a01584',
      label: 'None (blank)',
      icon: 'PB'
    },
    {
      value: '07c80e99-5b3c-474d-b94a-34c14214fe5c',
      label: 'Add to Mix Water',
      icon: 'PH'
    },
    {
      value: 'cfb95677-cd4c-4b92-80ab-ff52a1760c70',
      label: 'Add on the Fly',
      icon: 'PM'
    }
  ]

  private _disabled: boolean;

  value: any;

  selectedOption: any;

  onChange: Function = () => { };

  onTouched: Function = () => { };

  @Input()
  get options() { return this._options; }

  @Input() material: PumpScheduleStageMaterialModel;

  set options(options: any[]) {
    this._options = options;
    this.updateSelectedOption(this.value);
  }

  @Input()
  get disabled() { return this._disabled; }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
    if (!(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  constructor(public el: ElementRef,
    public renderer: Renderer2,
    public cd: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    if(this.material.overrideMixingProcedureId){
      this.onChange(this.material.overrideMixingProcedureId);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
    this.updateSelectedOption(value);
    this.cd.markForCheck();
  }

  selectItem(event, option: any) {
    this.selectedOption = option;
    this.value = option.value;
    if (Object.keys(event).length === 0) {
      return;
    }

    try {
      this.onChange(this.value);
    } catch (e) {
    }
    setTimeout(() => {
      if (this.panel) {

        try {
          this.hide();
        } catch (e) {
        }
      }
    }, 100);
  }

  hide() {
    this.panel.hide();
    this.onTouched();
  }

  show(event?) {
    if (!this.disabled) {
      this.panel.show(event);
    }
  }

  private updateSelectedOption(value: any) {
    if (this._options) {
      this.selectedOption = this._options.find((opts) => opts.value === value);

      if (this.material && this.material.overrideMixingProcedureId) {
        this.selectedOption = this._options.find((opts) => opts.value === this.material.overrideMixingProcedureId);
        this.value = this.selectedOption.value;
        this.selectItem({}, this.selectedOption);
      }

    } else {
      this.selectedOption = null;
    }

    if (this.selectedOption) {
      this.value = this.selectedOption.value;
    }

    this.cd.markForCheck();
  }

}


