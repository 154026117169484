import { MixingProcedure } from "../ifact";

export class IMaterialModel {
    id: string;
    name: string;
    materialId: number;
}

export class FluidMaterialModel {
    id: string = null;
    materialId: string = null;
    additiveId: string = null;
    cemBlendCompId: string = null;
    materialTypeId: string = null;
    materialTypeName: string = null;
    materialType: string = null;
    materialName: string = null;
    isBaseMaterial: boolean = false;
    concentration: number = null;
    concentrationUnit: string = null;
    fluidUnitMeasureId: string = null;
    fluidUnitMeasureName: string = null;
    testAmount: number = null;
    outputUnit: string = null;
    sg: number = null;
    slurryId: string = null;
    sapMaterialNumber: string = null;
    sapMaterialName: string = null;
    plannedVolume: number = null;
    loadoutVolume: number = null;
    isBlendComponent: boolean | string = false;
    notExistedSAPMaterial: boolean = false;
    order: number = 0;
    hdfComponentId: string = null;
    bulkDensity: number = null;
    mixingProcedureId: string = null;
    mixingProcedureValue: string = null;
    plannedQty: number = null;
    hdfMaterialId: string = null;
    materialNumberAssignedFlag: number = null;
    ifactMaterialAssignedFlag: number = null;
    totalCOGS: number = null;
    plannedScope3Co2e: number = null;
    actualScope3Co2e: number = null;
    sampleId: number = null;
    batchNo: string = null;
    lotNo: string = null;
    // for UI only
    plannedVolumeUnit: number;
    loadoutVolumeUnit: number;
}
