<ng-container *ngIf="actualDate;else editableProjectedDate">
  {{ actualDate | date: DATE_TIME_CALL_STANDARD }}
</ng-container>
<ng-template #editableProjectedDate>
  <job-projected-date
    [actualDate]="jobDateFormGroup.controls?.actualDate"
    [isManualActualDate]="jobDateFormGroup.controls?.isManualActualDate"
    [projectedDate]="jobDateFormGroup.controls?.projectedDate"
    (onDeleteProjectedDate)="deleteProjectedDate($event)"
    (onProjectedDateSelect)="changeProjectedDate($event)"
    (onBlur)="changeProjectedDate($event)"
    [cp4State]="cp4State"
    [jobStatusDescription]="jobStatusDescription"
    [isDisabled]="!(isMemberOfGroup$ | async) 
      || jobStatusDescription === 'Cancelled' 
      || jobStatusDescription === 'Closed'
      || jobStatusDescription === 'Completed'">
  </job-projected-date>
</ng-template>