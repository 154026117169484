<div class="panel panel-custom no-padding custom-panel" [formGroup]="pumpScheduleForm">
    <div class="panel-heading">Liner Job Parameters</div>
    <div class="panel-body">
        <div class="ui-table">
            <table>
                <thead>
                    <tr>
                        <th>Circulation Method above Liner*</th>
                        <th>Length of Cement above Liner (Gauge Hole)*</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <p-dropdown [options]="LinerCirculationMethods" formControlName="linerCirculationMethod"
                                [lockableElement]="'Liner_CirculationMethod'" placeholder=" ">
                            </p-dropdown>
                        </td>
                        <td class="vertical-align-middle">
                            <div class="input-unit" [lockableElement]="'Liner_CementLength'">
                                <input-unit [maxlength]="'6'"   [pKeyFilter]="'pint'"  formControlName="linerCementLength" unit={{UnitType.Distance}}>
                                </input-unit>
                            </div>
                            <div class="custom-unit" dUnitName={{UnitType.Distance}}></div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <span class="link-warning">
                                Refer to
                                <a target="_blank" [href]="linerJobGuidelineLink" class="link-warning">Guideline GD-GL-HAL-CMT-100J</a>
                                for Liner Jobs
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ui-table">
            <table>
                <thead>
                    <tr>
                        <th>Is This a VersaFlex Liner?*</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <p-dropdown class="versaFlexOptions" [options]="VersaFlexOptions" formControlName="isVersaFlexLiner"
                                [lockableElement]="'Liner_IsVersaFlex'" placeholder=" ">
                            </p-dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="pumpScheduleForm.controls.isVersaFlexLiner.value">
                <tbody>
                    <tr>
                        <td>
                            <span class="link-warning">
                                For VersaFlex liners, refer to
                                <a target="_blank" [href]="linerJobVersaFlexGuidelineLink" class="link-warning">GD-GL-HAL-CMT-100K</a>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
