<div [formGroup]="stageForm" [loadingIndicator]="loadingSubscription" class="ui-table w-100">
  <table>
    <thead>
      <tr class="stage-info-header">
        <th width="175px">Type <span *ngIf="!readonly">*</span>
        </th>
        <th *ngIf="!isMudType">
          <span *ngIf="!isTopBottom">Fluid
            <a *ngIf="stageForm && stageForm.controls.slurry.value && stageForm.controls.slurry.value.slurryId"
              href="{{stageForm.controls.slurry.value.slurryId | iFactsLink:'slurry'}}" target="_blank">
              (View in iFacts)
            </a>
          </span>
        </th>
        <th *ngIf="isMudType">
          <span [lockableElement]="'pumpSchedule_btnMudParamEdit'">Mud parameters
            <a *ngIf="!controlPointNumber || controlPointNumber===1" (click)="openMudParameters()"
              [class.disabled]="applicationStateService.notifyIFactDown$ | async">(Edit)</a>
          </span>
        </th>         
        <th width="130px"><span *ngIf="!isTopBottom">Planned Quantity</span></th>
        <th width="130px"><span *ngIf="!isTopBottom">{{isOffshore ? 'Required on Rig' : 'Load Out Quantity'}}</span></th>
        <th width="140px">
          <span *ngIf="!isTopBottom">Actual Quantity*</span>
        </th>
        <th width="140px">
          <span *ngIf="!isTopBottom">Planned Density</span>
        </th>
        <th width="140px">
          <span *ngIf="!isTopBottom">Actual Density*</span>
        </th>
        <th *ngIf="!readonly"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!readonly; else readonlyStageTemplate" class="stage-info-body" [formGroup]="stageForm">
        <td [lockableElement]="'PumpSchedule_pumpScheduleFluidTypeId'">
          <p-dropdown *ngIf="stageForm.controls.order.value === 0" [options]="fluidTypeDataStageOne"
            formControlName="pumpScheduleFluidTypeId"  placeholder="Enter a Fluid Value"
            showClear="true" (onChange)="_onFluidChange($event)" filter="true"></p-dropdown>
          <p-dropdown *ngIf="stageForm.controls.order.value !== 0" [options]="pumpScheduleAdapter.fluidTypes$ | async"
            formControlName="pumpScheduleFluidTypeId"  placeholder="Enter a Fluid Value"
            showClear="true" (onChange)="_onFluidChange($event)" filter="true"></p-dropdown>
          <error-display formControlName="pumpScheduleFluidTypeId" [errorMsg]="errorMessages.pumpScheduleFluidType">
          </error-display>
        </td>
        <td *ngIf="!isMudType" class="vertical-align-middle">
          <div *ngIf="!isTopBottom" [lockableElement]="'PumpSchedule_slurry'">
            <p-autoComplete #autoComplete (onFocus)="onFocusShowAllData()"
              (onClear)="onFocusShowAllData();onClearFluid.next()" formControlName="slurry"
              [suggestions]="filteredFluid" (completeMethod)="onComplete($event.query)" field="displayName"
              (onSelect)="onSelectedFluid.next($event)" [minLength]="0" (onKeyUp)="onFluidKeyUp.emit()"
              class="auto-complete-icon"
              [ngClass]="{'exception-disable-item': cp4State !== 'Completed' && cp2State !== 'Completed'}">
            </p-autoComplete>
          </div>
        </td>
        <td *ngIf="isMudType" class="vertical-align-middle">
          <span>{{mudParameter}}</span>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="!isTopBottom">
            <div class="display-measure">
              <span formControlName="avgRate" dUnitValue={{UnitType.FlowRate}} dUnitWithName="true"></span>
            </div>
          </div>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="!isTopBottom">
            <div class="display-measure">
              <span formControlName="volume" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
            </div>
          </div>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="!isTopBottom">
            <div class="display-measure">
              <span formControlName="loadOutQuantity" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
            </div>
          </div>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="!isTopBottom">
            <div class="display-measure">
              <span formControlName="actualVolumePumped" dUnitValue={{UnitType.LargeVolume}}
                dUnitWithName="true"></span>
            </div>
          </div>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="!isTopBottom">
            <div class="display-measure">
              <span formControlName="plannedDensity" dUnitValue={{UnitType.Density}}
                dUnitWithName="true"></span> 
            </div>
          </div>
        </td>
        <td>
        <td class="vertical-align-middle">
          <div *ngIf="!isTopBottom">
            <div class="display-measure">
              <span formControlName="actualDensity" dUnitValue={{UnitType.Density}}
                dUnitWithName="true"></span> 
            </div>
          </div>
        </td>
        <td>
          <ul class="list-inline">
            <li class="list-inline-item" [lockableElement]="'PumpSchedule_addStage'">
              <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                aria-hidden="true" *ngIf="stageForm.controls.number.value === 0">
                Add Stage
              </a>
              <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                aria-hidden="true" *ngIf="stageForm.controls.number.value !== 0">
                Add/ Remove Stage
              </a>
              <ul *ngIf="!readonly" class="dropdown-menu">
                <li [ngClass]="{disabled: stageForm.controls.order.value === 0}">
                  <a *ngIf="stageForm.controls.order.value !== 0" (click)="insertStageBefore.emit()">
                    <i class="fa fa-plus"></i> Insert Stage Before
                  </a>
                  <a *ngIf="stageForm.controls.order.value === 0">
                    <i class="fa fa-plus"></i> Insert Stage Before
                  </a>
                </li>
                <li>
                  <a (click)="insertStageAfter.emit()">
                    <i class="fa fa-plus"></i> Insert Stage After
                  </a>
                </li>
                <li [ngClass]="{disabled: stageForm.controls.order.value === 0}">
                  <a *ngIf="stageForm.controls.order.value !== 0" (click)="deleteStage.emit()">
                    <i class="fa fa-times"></i> Delete Stage
                  </a>
                  <a *ngIf="stageForm.controls.order.value === 0">
                    <i class="fa fa-times"></i> Delete Stage
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </td>
      </tr>

      <ng-template #readonlyStageTemplate>
        <tr>
          <td class="vertical-align-middle">
            <span>{{stageForm.controls.pumpScheduleFluidTypeName.value}}</span>
          </td>
          <td *ngIf="!isMudType">
            <div>
              <span class="unit-value">{{stageForm?.controls?.slurry?.value?.displayName}}</span>
            </div>
          </td>
          <td *ngIf="isMudType">
            <span>{{mudParameter}}</span>
          </td>          
          <td class="vertical-align-middle">
            <div *ngIf="!isTopBottom">
              <span class="unit-value" [apiValue]="stageForm.controls.volume.value"
                [unitType]="UnitType.LargeVolume"></span>
            </div>
          </td>
          <td class="vertical-align-middle">
            <div *ngIf="!isTopBottom">
              <span class="unit-value" [apiValue]="loadOutQuantity"
                    [unitType]="UnitType.LargeVolume"></span>
            </div>
          </td>
          <td class="vertical-align-middle">
            <div *ngIf="!isTopBottom">
              <div class="flx-ctn-row" [lockableElement]="'fluidDetailInfo_actualVolume'">
                <input-unit class="flx-itm-grow m-r-10" formControlName="actualVolumePumped"
                  unit={{UnitType.LargeVolume}} (onChange)="_onActualVolumeChange($event)">
                </input-unit>
                <span class="flx-itm-center" dUnitName={{UnitType.LargeVolume}}></span>
              </div>
            </div>
          </td>         
          <td class="vertical-align-middle">
            <div *ngIf="!isTopBottom">
              <span class="unit-value" [apiValue]="stageForm.controls.plannedDensity.value"
                    [unitType]="UnitType.Density"></span>
            </div>
          </td>
          <td class="vertical-align-middle">
            <div *ngIf="!isTopBottom && stageForm && stageForm.controls.order.value > 0">
              <div class="flx-ctn-row" [lockableElement]="'fluidDetailInfo_actualDensity'">
                <input-unit class="flx-itm-grow m-r-10" formControlName="actualDensity"
                  unit={{UnitType.Density}} (onChange)="_onActualDensityChange($event)">
                </input-unit>
                <span class="flx-itm-center" dUnitName={{UnitType.Density}}></span>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </tbody>
    </table>
</div>
