<p-dialog [(visible)]="isVisible" [responsive]="true" [draggable]="false" styleClass="dialog-custom"
  [style]="configs.dialogStyle" modal="false" dismissableMask="true" [closable]="false">
  <p-header>
    {{configs.header}}
    <span class="pi pi-times close-custom" (click)="reject()"></span>
  </p-header>
  <form [formGroup]="confirmationDialogForm" [ngClass]="configs.commentFieldRequired || configs.commentFieldTitle ? 'dialog-comment' : 'dialog-message'">
    <div [innerHTML]="configs.message"></div>
    <div *ngIf="(configs.type === 'Update Pump Schedule Name' && configs.type !== 'Delete Pump Schedule Name') || configs.type === ''" class="p-top-2">
      <div>
        {{configs.commentFieldTitle}}
        <span *ngIf="configs.warning">
          <i class="fa fa-info-circle" (click)="inactiveOp.toggle($event)"></i>
          <p-overlayPanel styleClass="overlay-panel-right" #inactiveOp [showCloseIcon]="false" [dismissable]="true">
            <div>
              <span>
                <strong>{{configs.warning}}</strong>
              </span>
            </div>
          </p-overlayPanel>
        </span>
      </div>

      <div *ngIf="!configs.hideTextarea">
        <textarea autofocus="" [maxlength]="configs.length" type="text" class="form-control" formControlName="comment" [placeholder]="configs.placeholder"></textarea>
        <div *ngIf="displayTextError('comment')" class="text-error-message">{{displayTextError('comment')}}</div>
      </div>
    </div>
  </form>
  <div class="dialog-footer" [class.space-between]="configs.rejectAsButton">
    <a *ngIf="!configs.rejectAsButton; else rejectBtn" class="btn link-cancel" (click)="reject()">Cancel</a>
    <ng-template #rejectBtn>
      <button class="btn primary-btn"
              (click)="reject()">{{configs.rejectLabel}}</button>
    </ng-template>
    <!-- <button class="btn custom-cancel" (click)="reject()">Cancel</button> -->
    <button class="btn primary-btn"
      (click)="accept()">{{configs.labelConfirm ? configs.labelConfirm : 'CONFIRM'}}</button>
  </div>
</p-dialog>
