export class IwellConditionVerificationModel {
  id: string;
  jobId: string;
  customerMD: number;
  customerTVD: number;
  customerMudWeight: number;
  isStaticWell: boolean;
  comments: string;
  controlPointId: string;
  isCasingStretch:boolean;
  casingStretchComments:string;
  isImpactLCMConsidered:boolean;
  impactLCMComments:string

  constructor() {
    this.id = null;
    this.jobId = null;
    this.customerMD = null;
    this.customerTVD = null;
    this.customerMudWeight = null;
    this.isStaticWell = null;
    this.comments = null;
    this.controlPointId = null;
    this.isCasingStretch=null;
    this.casingStretchComments=null;
    this.isImpactLCMConsidered=null;
    this.impactLCMComments=null;
  }
}