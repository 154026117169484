
import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

import { UnitType, MATERIAL_TYPE } from 'libs/constants';
import { ErrorMessageModel } from 'libs/ui/validations/error-message.model';
import { ISAPMaterialModel, Job, FluidModel, PumpScheduleStageModel, MaterialManagementMappingModel } from 'libs/models';

import { ControlPointState, ControlPointType } from '../../../shared/constant';
import { calculateMaterialQuantity, calculateMaterialCost } from 'libs/shared/calculations';
import { Subscription } from 'rxjs';
import { EditJobAdapter } from '../../../edit-job/adapters';
import { UserSettingService, ApplicationStateService, RoleService } from 'libs/shared/services';

@Component({
  selector: 'pump-fluid-material-detail',
  templateUrl: './pump-fluid-material-detail.component.html',
  styleUrls: ['./pump-fluid-material-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PumpFluidMaterialDetailComponent implements OnChanges, OnDestroy {
  @Input() header: string;
  @Input() plannedVolume: number;
  @Input() loadoutVolume: number; // Unit API
  @Input() mixVolume: number; // Unit ml
  @Input() yield: number;
  @Input() bulkCementBaseOnFluid: number = 0;
  @Input() materialFormGroups: UntypedFormGroup[] = [];
  @Input() rowClass = '';
  @Input() controlPointNumber: string;
  @Input() tabName: string;
  @Input() cpState: number = 0;
  @Input() jobState: string = '';
  @Input() actualVolume: number = 0;
  @Input() job: Job;
  @Input() fluid: FluidModel;
  @Input() isBlend: false;
  @Input() fluidType: string;
  @Input() isSupplemental: false;
  @Input() stage: UntypedFormGroup;
  @Input() sapMaterialMappings: MaterialManagementMappingModel[];
  @Output() onLoadoutVolumeChanged = new EventEmitter<any>();

  isCalculateActualQty: boolean = false;
  isExpanded: boolean = false;
  isTableExpanded: boolean = false;
  isFirstUpdate: boolean = true;
  UnitType = UnitType;
  isOffshore: boolean;
  cp4State: string;
  subcriptions: Subscription[] = [];
  MATERIAL_TYPE = MATERIAL_TYPE;
  errorMessages = {
    sapMaterialNumber: [
      new ErrorMessageModel('notExisted', 'SAP Number does not exist. Please input another value.')
    ]
  };

  get showCogs$() {
    return this.userSettingsService.showCogs$;
  }

  get isJobReviewer$() {
    return this.roleService.isJobReviewer$;
  }

  constructor(
    private applicationStateService: ApplicationStateService,
    public editJobAdapter: EditJobAdapter,
    private roleService: RoleService,
    private userSettingsService: UserSettingService,
    private ref: ChangeDetectorRef
  ) {
    this.editJobAdapter.updateSAPMaterial$.subscribe(_ => {
      this.updateMappingMaterial(_);
    });
    // this.editJobAdapter.removeSAPMaterial$.subscribe(_ => {
    //   if (!this.fluid || this.fluid.id !== _.slurryId || this.fluid.slurryNo !== _.slurryNo) {
    //     return;
    //   }
    //   if (this.materialFormGroups[_.materialIndex]) {
    //     this.materialFormGroups[_.materialIndex].controls.sapMaterialNumber.setValue(null);
    //   }
    // });

    this.subcriptions.push(this.applicationStateService.actualVolumeDetectChange$.subscribe(() => {
      this.ref.markForCheck();
    }));
  }

  ngOnDestroy() {
    if (this.subcriptions) {
      this.subcriptions.forEach(sub => sub.unsubscribe());
    }
  }

  private updateMappingMaterial(_) {
    if (this.fluid && (this.fluid.id || this.fluid.slurryIdHDF || this.fluid.slurryId)) {
      const { id, slurryIdHDF, slurryId, slurryNo } = this.fluid;
      const slurry = this.editJobAdapter.fluidFormArray.controls
        .find((x: UntypedFormGroup) => (id && x.controls.id.value === id) || (slurryIdHDF && x.controls.slurryIdHDF.value === slurryIdHDF) ||
          (slurryId && x.controls.slurryId.value === slurryId && x.controls.slurryNo.value === slurryNo && slurryNo != null)) as UntypedFormGroup;
      if (slurry) {
        const fluidBlendMaterialFm = slurry.controls.fluidBlendMaterial as UntypedFormArray;
        const fluidAdditiveMaterialFm = slurry.controls.fluidAdditiveMaterial as UntypedFormArray;
        const fluidSupplementalMatFm = slurry.controls.supplementalMaterial as UntypedFormArray;
        if (this.fluidType === MATERIAL_TYPE.SUPPLEMENTAL && fluidSupplementalMatFm.controls.length === this.materialFormGroups.length) {
          for (let index = 0; index < this.materialFormGroups.length; index++) {
            this.setMaterial(fluidSupplementalMatFm, index);
          }
        } else {
          if ((_.isBlend == null || _.isBlend && this.isBlend) && fluidBlendMaterialFm.controls.length === this.materialFormGroups.length) {
            for (let index = 0; index < this.materialFormGroups.length; index++) {
              this.setMaterial(fluidBlendMaterialFm, index);
            }
          }
          if ((_.isBlend == null || !_.isBlend && !this.isBlend) && fluidAdditiveMaterialFm.controls.length === this.materialFormGroups.length) {
            for (let index = 0; index < this.materialFormGroups.length; index++) {
              this.setMaterial(fluidAdditiveMaterialFm, index);
            }
          }
        }
      }
    }
  }

  private setMaterial(fluidAdditiveMaterialFm, index) {
    const fluidForm = fluidAdditiveMaterialFm.controls[index] as UntypedFormGroup;
    const materialFormGroup = this.materialFormGroups[index];
    if (fluidForm && fluidForm.controls && fluidForm.controls.sapMaterialName) {
      materialFormGroup.controls.sapMaterialNumber.setValue(fluidForm.controls.sapMaterialNumber.value);
      materialFormGroup.controls.sapMaterialNumber.setErrors(fluidForm.controls.sapMaterialNumber.errors);
    }

    if (fluidForm && fluidForm.controls && fluidForm.controls.materialId && fluidForm.controls.materialName) {
      materialFormGroup.controls.ifactMaterialId.setValue(fluidForm.controls.materialId.value);
      materialFormGroup.controls.ifactMaterialName.setValue(fluidForm.controls.materialName.value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    const { plannedVolume, loadoutVolume, materialFormGroups, actualVolume, fluid, controlPointNumber, sapMaterialMappings } = changes;

    if (plannedVolume && plannedVolume.currentValue !== plannedVolume.previousValue) {
      this._calculatePlannedVolume();
      this._calculateCogs(plannedVolume.firstChange);
    }

    if (fluid) {
      this.updateMappingMaterial({
        isBlend: this.isBlend
      });
      this._calculatePlannedVolume();
      this._calculateCogs(false);
    }

    if (loadoutVolume) {
      this._calculateLoadoutVolume();
    }

    if (controlPointNumber && this.controlPointNumber) {
      this.editJobAdapter.updateSAPMaterialForCP$.subscribe(data => {
        if (!this.fluid) {
          return;
        }
        const { id, slurryIdHDF, slurryId, slurryNo } = this.fluid;
        if (((id && data.fluid.id === id) || (slurryIdHDF && data.fluid.slurryIdHDF === slurryIdHDF) ||
          (slurryId && data.fluid.slurryId === slurryId && data.fluid.slurryNo === slurryNo && slurryNo != null)) &&
          this.materialFormGroups.length === data.materialFormGroups.length) {
          for (let index = 0; index < this.materialFormGroups.length; index++) {
            this.materialFormGroups[index].controls.sapMaterialNumber.setValue(data.materialFormGroups[index].controls.sapMaterialNumber.value);
            this.materialFormGroups[index].controls.sapMaterialName.setValue(data.materialFormGroups[index].controls.sapMaterialName.value);
          }
        }
      });
    }

    if (materialFormGroups && materialFormGroups.currentValue) {

      this._calculatePlannedVolume();
      this._calculateLoadoutVolume();
      this._calculateActualQty();
      this._calculateCogs(false);

      if (materialFormGroups.currentValue.length > 0) {
        this.materialFormGroups.forEach(fluidMaterial => {
          setTimeout(() => {
            // this.editJobAdapter.fillMaterial(fluidMaterial);
            if (fluidMaterial.controls.notExistedSAPMaterial.value) {
              fluidMaterial.controls.sapMaterialNumber.setErrors({ 'notExisted': true });
              fluidMaterial.controls.sapMaterialNumber.markAsDirty();
            }
          });
        });
      }
    }
    if (actualVolume) {
      this.isCalculateActualQty = !actualVolume.firstChange;
      this._calculateActualQty();
    }

    this.applicationStateService.isChangeOffshore$.subscribe(value => this.isOffshore = !!value);

    if (this.job && this.job.controlPoints && this.job.controlPoints.length) {
      this.cp4State = this.job.controlPoints[3].controlPointState.name;
    }

    if (sapMaterialMappings) {
      this._calculateCogs(false);
    }
  }

  onChangeSAP(formGroup: UntypedFormGroup, sapMaterial: ISAPMaterialModel, materialIndex: number) {
    if (sapMaterial) {
      this._notifySAPChange(formGroup, sapMaterial.materialNumber, materialIndex);
    }
  }

  _notifySAPChange(formGroup: UntypedFormGroup, sapMaterialNumber: string, materialIndex: number) {
    const { controls: { fluidMaterialId: { value: fluidMaterialId } } } = formGroup;
    const { controls: { hdfMaterialId: { value: hdfMaterialId } } } = formGroup;
    const { controls: {
      ifactMaterialId: { value: ifactMaterialId },
      ifactMaterialName: { value: ifactMaterialName }
    } } = formGroup;
  }

  onClearSAP(formGroup: UntypedFormGroup, sapMaterial: ISAPMaterialModel, materialIndex: number) {
    if (!sapMaterial) {
      sapMaterial = new ISAPMaterialModel;
    }
    this._notifySAPChange(formGroup, sapMaterial.materialNumber, materialIndex);
  }

  _calculatePlannedVolume() {
    this.materialFormGroups.forEach(fg => {
      const materialFormGroup = fg as UntypedFormGroup;
      const materialData = materialFormGroup.getRawValue();
      const calculatedPlannedVolume = calculateMaterialQuantity(materialData.outputUnit, materialData.sg, materialData.testAmount, this.plannedVolume,
        this.mixVolume, this.yield, materialData.concentration, materialData.concentrationUnit, materialData.sackWeight, materialData.mixWater, this.fluid.waterDensity, this.fluid.density);
      if (calculatedPlannedVolume.UnitType) {
        materialFormGroup.controls.plannedVolumeUnit.setValue(calculatedPlannedVolume.UnitType, { emitEvent: false });
      }
      materialFormGroup.controls.plannedVolume.setValue(calculatedPlannedVolume.Value, { emitEvent: false });
    });
  }

  _calculateActual(materialData, materialFormGroup) {
    const volume = this.fluidType === MATERIAL_TYPE.SUPPLEMENTAL ? this.plannedVolume : this.actualVolume;
    const calculatedActualQty = calculateMaterialQuantity(materialData.outputUnit, materialData.sg, materialData.testAmount, volume,
      this.mixVolume, this.yield, materialData.concentration, materialData.concentrationUnit, materialData.sackWeight, materialData.mixWater, this.fluid.waterDensity, this.fluid.density);
    if (calculatedActualQty.Value === 0 || calculatedActualQty.Value == null || isNaN(calculatedActualQty.Value)) {
      calculatedActualQty.Value = null;
    }
    materialFormGroup.controls.actualQty.setValue(calculatedActualQty.Value, { emitEvent: false });
  }

  private _calculateCogs(isFirstChange: boolean) {
    this.materialFormGroups.forEach(x => {
      let calculatedCogs = null;
      const materialFormGroup = x as UntypedFormGroup;
      const materialData = materialFormGroup.getRawValue();
      const sapMaterialMapping = this.sapMaterialMappings ? this.sapMaterialMappings.find(x => x.materialId === materialData.ifactMaterialId) : null;
      const currentCogsValue = materialFormGroup.controls.totalCOGS.value;
      if (sapMaterialMapping) {
        if (this.plannedVolume && (sapMaterialMapping.cogsPrice === 0 || materialData.concentration === 0)) {
          calculatedCogs = {
            uomIncompatible: false,
            value: 0
          };
        } else if (sapMaterialMapping.cogsPrice && sapMaterialMapping.cogsUnitMeasureName
          && materialFormGroup.controls.plannedVolume.value && materialFormGroup.controls.plannedVolumeUnit.value) {
          calculatedCogs = calculateMaterialCost(materialFormGroup.controls.plannedVolume.value, materialFormGroup.controls.plannedVolumeUnit.value,
            materialData.sg, sapMaterialMapping.cogsPrice, sapMaterialMapping.cogsUnitMeasureName,
            sapMaterialMapping.sackWeight, materialData.cemmentBulkDensity, this.yield);
          materialFormGroup.controls.uomIncompatible.setValue(calculatedCogs.uomIncompatible, { emitEvent: false });
        }
      }
      if ((((!calculatedCogs || !calculatedCogs.value) && currentCogsValue !== null) || (calculatedCogs && calculatedCogs.value !== null && +calculatedCogs.value.toFixed(4) !== currentCogsValue))
        && this.sapMaterialMappings && this.sapMaterialMappings.length > 0 && (!this.isCP1Submitted || this.applicationStateService.cogsRecalculationNeeded$.value)) {
        materialFormGroup.controls.totalCOGS.setValue(calculatedCogs && calculatedCogs.value !== null ? +calculatedCogs.value.toFixed(2) : null, { emitEvent: false });
      }
      materialFormGroup.controls.cogsCalculated.setValue(true, { emitEvent: false });
    });
    if (this.materialFormGroups.length > 0 || (this.materialFormGroups.length === 0 && !isFirstChange && this.applicationStateService.cogsRecalculationNeeded$.value)) {
      this.applicationStateService.materialCostCalculated$.next();
    }
  }

  private _calculateActualQty() {
    this.materialFormGroups.forEach(fg => {
      const materialFormGroup = fg as UntypedFormGroup;
      const materialData = materialFormGroup.getRawValue();
      if (this.isCalculateActualQty) {
        this._calculateActual(materialData, materialFormGroup);
      } else {
        if (!materialData.actualQty) {
          this._calculateActual(materialData, materialFormGroup);
        }
      }
    });
  }

  private _calculateLoadoutVolume() {
    this.materialFormGroups.forEach(fg => {
      const materialFormGroup = fg as UntypedFormGroup;
      const materialData = materialFormGroup.getRawValue();
      const volume = this.loadoutVolume;
      const calculatedLoadOutVolume = calculateMaterialQuantity(materialData.outputUnit, materialData.sg, materialData.testAmount, volume,
        this.mixVolume, this.yield, materialData.concentration, materialData.concentrationUnit,
        materialData.sackWeight, materialData.mixWater, this.fluid.waterDensity, this.fluid.density);
      if (calculatedLoadOutVolume.UnitType) {
        materialFormGroup.controls.loadoutVolumeUnit.setValue(calculatedLoadOutVolume.UnitType, { emitEvent: false });
      }
      materialFormGroup.controls.loadoutVolume.setValue(calculatedLoadOutVolume.Value, { emitEvent: false });
      materialFormGroup.controls.overrideVolume.markAsTouched();
      materialFormGroup.controls.overrideVolume.updateValueAndValidity();
    });
    this.onLoadoutVolumeChanged.emit();
  }

  // checkDisableBasedOnState() {
  //   if (this.job && (this.job.jobStatusDescription.toLowerCase() === 'cancelled' || this.job.jobStatusDescription.toLowerCase() === 'closed')) {
  //     return 'custom-disable';
  //   } else {
  //     return (this.cp4State !== 'Completed' && this.jobState !== 'Completed' && this.cpState === ControlPointState.Approved || this.cpState === ControlPointState.Submitted) ? 'exception-disable-item' : '';
  //   }
  // }

  checkDisableByState() {
    if (this.job && this.job.jobStatusDescription !== null && (this.job.jobStatusDescription.toLowerCase() === 'cancelled' || this.job.jobStatusDescription.toLowerCase() === 'closed')
      || this.cpState === ControlPointState.Completed) {
      return 'custom-disable';
    } else {
      this.checkDisableItem();
    }
  }

  isBlockedByJobState() {
    return (this.job && this.job.jobStatusDescription !== null
      && (this.job.jobStatusDescription.toLowerCase() === 'cancelled' || this.job.jobStatusDescription.toLowerCase() === 'closed'));
  }

  checkDisableItem() {
    return (this.cp4State !== 'Completed' && this.jobState !== 'Completed' && this.cpState === ControlPointState.Approved || this.cpState === ControlPointState.Submitted) ? 'exception-disable-item' : '';
  }

  checkDisableItemMaterialNumber() {
    return (this.jobState !== 'Completed' && this.cpState === ControlPointState.Approved || this.cpState === ControlPointState.Submitted) ? 'custom-disable' : '';
  }

  markAsTouched() {
    this.materialFormGroups.forEach(fluidMaterial => fluidMaterial.markAsTouched());
  }

  isInvalid() {
    const isInvalid = this.materialFormGroups.some(f => f.invalid);
    return isInvalid;
  }

  get isCP1(): boolean {
    return this.controlPointNumber && this.controlPointNumber.toString() === ControlPointType.ControlPoint1.toString();
  }

  get isCP4(): boolean {
    return this.controlPointNumber && this.controlPointNumber.toString() === ControlPointType.ControlPoint4.toString();
  }

  get isCP1Submitted(): boolean {
    return this.job && this.job.controlPoints && this.job.controlPoints.length
      && (this.job.controlPoints[0].eControlPointState === ControlPointState.Completed
        || this.job.controlPoints[0].eControlPointState === ControlPointState.Approved
        || this.job.controlPoints[0].eControlPointState === ControlPointState.Submitted
        || this.job.controlPoints[0].eControlPointState === ControlPointState.Prepared);
  }
}
