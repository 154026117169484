<div *ngIf="fluidFormGroup" class="flx-itm-grow flx-ctn-row" [ngClass]="{ odd: (index % 2) , even: !(index % 2) }"
    [loadingIndicator]="loadingSubscription">
    <div class="flx-itm flx-ctn-col fluid-left-container" (click)="isExpanded = !isExpanded">
        <div class="flx-itm flx-itm-center p-l-30 p-r-30 p-t-15">
            <div class="flx-itm-center m-r-20">
                <a>
                    <i class="fa fa-play" [ngClass]="{'expanded': isExpanded}"></i>
                </a>
            </div>
            <div class="flx-itm flx-itm flx-ctn-col" [ngClass]="shouldDisplayError ? 'fluids-error' : ''">
                <span class="fluid-title">Fluid</span>
                <span class="flx-itm-center fluid-number">{{index}}</span>
            </div>
        </div>
    </div>
    <div class="flx-itm-grow flx-ctn-col fluid-details-container">
        <fluid-request-info #fluidRequestInfo
                            [fluidFormGroup]="fluidFormGroup"
                            [canEdit]="canEdit" (copyRequest)="copyRequestFluid()"
                            (createRequest)="createRequestFluid()"
                            (deleteFluidDetail)="deleteFluid()"
                            (onFluidChange)="onFluidChange()"
                            (onActualVolumeChange)="onActualVolumeChange.next()"
                            [job] = "job">
        </fluid-request-info>
        <div class="p-t-20" [hidden]="!isExpanded">
            <div class="form-subtitle-section m-l-10">Slurry Details</div>
            <div class="fluid-details m-r-30">
                <fluid-detail-info tabName="Fluids" [OriginalJobId]="OriginalJobId" [fluidFormGroup]="fluidFormGroup"></fluid-detail-info>
                <fluid-materials class="flx-ctn-col fluid-blend-materials p-20" [index]="-index"
                    [header]="getHeaderMaterialName(fluidFormGroup?.controls?.fluidBlendMaterial['controls'], MATERIAL_TYPE.BLEND)"
                    [fluidMaterials]="fluidFormGroup?.controls?.fluidBlendMaterial['controls']"
                    [slurrySourceId]="fluidFormGroup?.controls?.slurrySource?.value" [fluidType]="MATERIAL_TYPE.BLEND"
                    [canEdit] = "canEdit"
                    [job] = "job"
                    (onSAPMaterialChange)="updateFluidInAllStages()">
                </fluid-materials>
                <fluid-materials class="flx-ctn-col fluid-additives-materials p-20" [index]="index"
                    [header]="getHeaderMaterialName(fluidFormGroup?.controls?.fluidAdditiveMaterial['controls'], MATERIAL_TYPE.ADDITIVE)"
                    [fluidMaterials]="fluidFormGroup?.controls?.fluidAdditiveMaterial['controls']"
                    [slurrySourceId]="fluidFormGroup?.controls?.slurrySource?.value"
                    [fluidType]="MATERIAL_TYPE.ADDITIVE"
                    [canEdit] = "canEdit"
                    [job] = "job"
                    (onSAPMaterialChange)="updateFluidInAllStages()">
                </fluid-materials>
                <fluid-materials class="flx-ctn-col fluid-blend-materials p-20" [index]="index"
                    [header]="getHeaderMaterialName(fluidFormGroup?.controls?.supplementalMaterial['controls'], MATERIAL_TYPE.SUPPLEMENTAL)"
                    [fluidMaterials]="fluidFormGroup?.controls?.supplementalMaterial['controls']"
                    [slurrySourceId]="fluidFormGroup?.controls?.slurrySource?.value"
                    [fluidType]="MATERIAL_TYPE.SUPPLEMENTAL" (onAddSup)="addNewSupplemental($event)"
                    (supplementalRemoved)="onSupplementalRemoved($event)"
                    [concentrationUnitList]="concentrationUnitList"
                    [slurryTypeId]="fluidFormGroup['controls'].slurryTypeId.value"
                    [canEdit] = "canEdit"
                    [job] = "job"
                    (onSAPMaterialChange)="updateFluidInAllStages()">
                </fluid-materials>
            </div>
        </div>
    </div>
</div>
