import { Injectable } from '@angular/core';
import { IUser } from 'libs/models';

@Injectable()
export class SessionService {
  user: IUser = {
    userName: '',
    userId: '',
    networkId: '',
    token: '',
    hcToken: '',
    prefUserName: '',
  };
}
