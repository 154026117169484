<div class="modal-header">    
    <span class="modal-title pull-left">Materials without {{ dataType }}</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
        <span class="fa fa-times"></span>
    </button>
</div>
<div class="modal-body">
    <div class="margin-div">
        <div class="row">
            <div class="col-sm-12 text p-4">
                For group <b>{{ currentGroup }}</b>, the following materials are missing {{ dataType }} data:
            </div>
        </div>
        <div class="content opaque-div rscroll">
            <div>
                <p-table 
                    [columns]="cols" 
                    [value]="missingDataMaterials" 
                    [scrollable]="true" 
                    scrollHeight="220px" >
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [style.width]="col.width">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-row let-columns="columns">
                        <tr>
                            <td *ngFor="let col of columns" [style.width]="col.width">
                                {{row[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                          <td [attr.colspan]="columns.length + 1" [style.border]="0">
                            No records found
                          </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    
    <div class="col-sm-10 site-footer">
        <div>
            <div class="text">
                To input/update {{ dataType }} values, <a class="link" (click)="onMaterialsLink()">visit Maintenance > Material Management</a>.
                After updating, refresh the page to view updated results.
            </div>
        </div><br/>
        <div>
            <div class="text p-4 link-center">
                 <a class="link" (click)="onClose()"><b>Close</b></a>
            </div>
        </div>
    </div>
</div>