<div class="row g-o">
    <div class="col-md-1">
        <img *ngIf="jobForm.isJobOutOfComplience$ | async" class="fa-img-exclamation" src="../../../assets/images/exclamationmark-circle-fill.svg" />
    </div>
    <div class="col-md-1">
        <img *ngIf="jobForm.isJobDueSoon$ | async" class="fa-img-exclamation" src="../../../assets/images/exclamationmark-triangle-fill.svg" />
    </div>
    <div class="col-md-1">
        <i *ngIf="jobForm.job.hasNewMessage" class="fa fa-envelope-o" aria-hidden="true" [pTooltip]="opsMessageText" tooltipPosition="left"></i>
    </div>
</div>