<p-sidebar [(visible)]="showHandower" (onHide)="closeHandover.emit()" position="right" blockScroll="true" appendTo="body" styleClass="vida-sidebar">

    <div class="sidebar-header">
        <h1>Handover </h1>
    </div>
    <div class="sidebar-content" [formGroup]="handOverFg" *ngIf="handOverFg">
        <p class="text-bold">Enter Handover information below.</p>
        <div class="modal-container">
            <div class="row">
                <span class="badge-number">1</span> <label>Supervisor information</label>

                <div class="form-group modal-section">
                    <label class="title-label">Service Supervisor Name/ID*</label>
                    <p-autoComplete #supervisorAutoComplete field="display" formControlName="supervisor" forceSelection="true"
                        placeholder="Enter Service Supervisor Name/ID" [suggestions]="members" (completeMethod)="onComplete($event.query)"
                        (onClear)="clearSelectedSupervisor()" (onBlur)="clearAutoCompleteInput()">
                    </p-autoComplete>
                </div>
            </div>

            <div class="row ">
                <span class="badge-number">2</span> <label>Communication information</label>
                <div class="container-fluid">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <label class="title-label">Communication Type*</label>
                            <p-dropdown optionLabel="name" placeholder="Select Type" formControlName="communicationType" emptyMessage="No user found"
                                [options]="communicationTypes">
                            </p-dropdown>
                        </div>

                        <div class="form-group col-sm-6">
                            <label class="title-label">Communication Date and Time*</label>
                            <div style="margin-top:-3px">
                                <datetime-picker (onClear)="onClearDateTime()" format="dd-MMM-yyyy" styleClass="float-left-calendar"
                                    formControlName="communicationDateTime" placeholder="DD-MMM-YYYY"></datetime-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group modal-section">
                    <label class="title-label">Comments / Description</label>
                    <div class="">
                        <textarea pInputTextarea class="full-width-input form-control comments-input" rows="2" formControlName="comment"
                            placeholder="Enters comments Here"></textarea>
                    </div>
                </div>
            </div>

            <div class="row">
                <span class="badge-number">3</span> <label>Upload File</label>
                <div *ngIf="!handoverFile" class="form-group modal-section">
                    <label class="title-label">Select a Handover file to import (optional)</label>
                    <job-uploader #uploader [isDisabled]="!canEdit" [jobId]="jobId" [destinationFolderPath]="destinationFolderPath"
                        drapDropDescription="or Drag and Drop Handover File Here" [isEnableDescriptionInput]="false" [badgeNumber]=false [displayMode]="'Vertical'"
                        [vidaFileType]="handoverFileType" (uploadSuccessEmitter)="fileUploaded($event)">
                    </job-uploader>
                </div>
                <div *ngIf="handoverFile" class="form-group modal-section">
                    <div class="file-link">
                        <a href="{{handoverFile?.filePathDownload}}"> {{handoverFile.getFileName()}}</a>
                    </div>
                    <div class="file-upload-by" *ngIf="handoverFile.uploader && handoverFile.createdDate">
                        <div> Uploaded by <span class="author">{{handoverFile.uploader}}</span></div>
                        <div> on <span>{{handoverFile.getUploadDate()}}</span></div>
                    </div>
                    <div class="delete-link">
                        <a (click)="deleteFile()"><i class="img-icon img-icon-delete"></i>&nbsp;<span>Delete file</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sidebar-footer">
        <a class="btn lnk-cancel" (click)="close($event)">Cancel</a>
        <button type="button" class="btn primary-btn" [disabled]="submitting || !checkValidateHandover()" (click)="submit($event)">SUBMIT</button>
    </div>
</p-sidebar>