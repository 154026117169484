export const ENV_NAME = {
  DEV: 'dev',
  QA: 'qa',
  SME: 'sme',
  PROD: 'prod'
};
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export class AzureAdConfig {
  clientId: string;
  resource: string;
  tenantId: string;
  redirectUri: string;
  cacheLocation: string;
}

export interface IEnvironment {
  name: string;
  production: boolean;
  baseUrl: string;
  httpCommanderUrl: string;
  iFactsUrl: string;
  sharePointITSCUrl: string;
  sharePointVIDAUrl: string;
  opsMessageReloadIntervalInMilisecond: number;
  messagePopupLifetimeMs: number;
  dataReloadPeriodMs: number;
  iFactsChunkSize: number;
  linkToProposalHDF: string;
  linkToHDFWithoutProposal: string;
  linkToWorkMethod: string;
  plugBaseLinkToWorkMethod: string;
  linerJobGuidelineLink: string;
  linerJobVersaFlexGuidelineLink: string;
  casingStretchSimulationAndRatHoleGuidelineLink: string;
  appianKPISystemLink: string;
  appianBulkBoardPageLink: string;
  usePumpSchedule_1_3: boolean;
  azureAd: AzureAdConfig;
  appInsightsInstrumentationKey: string;
}

let env: IEnvironment = {
  name: ENV_NAME.DEV,
  production: false,
  baseUrl: 'http://localhost:50108',
  httpCommanderUrl: 'http://localhost:7047',
  iFactsUrl: 'https://ifactscemstg.ienergy.halliburton.com',
  sharePointITSCUrl: 'http://spwest/sites/ITSC/Lists/ITSC/DispForm.aspx?ID=25210',
  sharePointVIDAUrl: 'https://halliburton.sharepoint.com/sites/SPW_VIDA/SitePages/Home.aspx',
  opsMessageReloadIntervalInMilisecond: 300000,
  messagePopupLifetimeMs: 6000,
  dataReloadPeriodMs: 30000,
  iFactsChunkSize: 25,
  linkToProposalHDF: 'https://hdfstg/AdvancedSearch?tabs=ProposalAdvancedSearch&preLoadedTab=ProposalAdvancedSearch&proposalNumber=',
  linkToHDFWithoutProposal: 'https://hdfstg/AdvancedSearch?tabs=JobAdvancedSearch%2C%20PlanningOrderAdvancedSearch&preLoadedTab=JobAdvancedSearch',
  linkToWorkMethod: 'https://halworld.corp.halliburton.com/content/dam/halworld/internal/hms/cementing/pubsdata/cmt/WM-GL-HAL-CMT-101.docx?node-id=j1ezgvqq',
  plugBaseLinkToWorkMethod: 'https://halworld.corp.halliburton.com/content/dam/halworld/internal/hms/cementing/pubsdata/cmt/GD-GL-HAL-CMT-100E.docx?node-id=j1ezgvqq',
  linerJobGuidelineLink: 'https://halworld.corp.halliburton.com/content/dam/halworld/internal/hms/cementing/pubsdata/cmt/GD-GL-HAL-CMT-100J.docx?node-id=j1ezgvqq',
  linerJobVersaFlexGuidelineLink: 'https://halworld.corp.halliburton.com/content/dam/halworld/internal/hms/cementing/pubsdata/cmt/GD-GL-HAL-CMT-100K.docx?node-id=j1ezgvqq',
  casingStretchSimulationAndRatHoleGuidelineLink: 'https://halliburton.service-now.com/hms?id=kb_article_view&sysparm_article=KB0035980&sys_kb_id=6705af3c1b8cc55417bacb7f034bcbab&spa=1',
  appianKPISystemLink:'https://halliburtoncompanytest.appiancloud.com/suite/sites/vida-jobs/page/kpi',
  appianBulkBoardPageLink: 'https://halliburtoncompanytest.appiancloud.com/suite/sites/vida-jobs/page/bulkBoard',
  usePumpSchedule_1_3: true,
  azureAd: new AzureAdConfig(),
  appInsightsInstrumentationKey: '5afe3638-f911-4a9c-b098-a565cb95a361'
};

if (window['env']) env = <IEnvironment>window['env'];
export const environment = env;
