import { IFactsAvailableSlurry } from './ifacts-available-slurry';

export class IFactsRequestInfo {

    public constructor (

        public id: number,

        public type: string,

        public status: string,

        public trademarkName: string,

        public availableSlurries: IFactsAvailableSlurry[]
    ) {
    }
}
