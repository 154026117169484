import { AdUser } from 'libs/models';
import { CommunicationType } from './communication-type.model';

export class HandOver {
  handoverId: string;
  supervisor: AdUser;
  networkId: string;
  communicationDateTime: string;
  communicationType: CommunicationType;
  communicationTypeId: string;
  comment: string;
  jobId: string;
  controlPointId: string;

   constructor() {
    this.handoverId = null;
    this.supervisor = new AdUser();
    this.networkId = null;
    this.communicationDateTime = null;
    this.communicationType = new CommunicationType();
    this.communicationTypeId = null;
    this.comment = null;
    this.jobId = null;
    this.controlPointId = null;
  }
}

