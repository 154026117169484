
<div class="linked-job" *ngIf="!job.originalJobId">
    <i class="fa fa-plus-circle"></i>&nbsp;
    <a class="billing-info-link" (click)="openModalLinkJob()" [ngClass]="{'disabled-edit-link': !canEdit }">Add Originating Job</a>
</div>
<div *ngIf="job.originalJobId" class="linked-job">Originating Job:
    <a class="billing-info-link disable-except" [routerLink]="['/job-simple', job.originalJobId]" target="_blank" rel="noopener noreferrer">
    [{{job.originalJobCode}}] / {{job.originalJobName}}
    </a>
    &nbsp;
    <a class="billing-info-link" (click)="openModalLinkJob()" [ngClass]="{'disabled-edit-link': !canEdit }">Edit Link</a>
    <a *ngIf="job.originalJobId&&job.riskLevel!==1" class="billing-info-link p-3" 
        [ngClass]="{'disabled-edit-link': !canEdit }" (click)="showConfirmationOnRemoveLink()">Remove Link</a>
</div>

<modal-link-origin-job [(visible)]="isShowModal" (onConfirm)="confirmFromModal($event)" [job]="job"></modal-link-origin-job>