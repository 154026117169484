<div [formGroup]="formMudParameter">
  <div class="mud-parameter-popup">
    <p-dialog
      responsive="true"
      dismissableMask="true"
      styleClass="dialog-custom"
      modal="false"
      [autoZIndex]="true"
      [draggable]="false"
      [closable]="false"
      [style]="{width: '600px', height: '355px'}"
      (onHide)="resetParams()"
      (onShow)="setValidator()"
      baseZIndex="1050"
      [visible]="isMudParametersVisible | async">
      <p-header>
        <span>Enter/Edit Mud Parameters</span>
        <span class="pi pi-times close-custom" (click)="closeMud()"></span>
      </p-header>
      <div class="p-dialog-body w-100 h-100">
        <div class="row mud-custom">
          <div class="col-12 col-md-6">
            <span>Mud Type</span>
            <p-dropdown [options]="mudTypes" formControlName="mudTypeId" 
              placeholder="&nbsp;" showClear="true"></p-dropdown>
          </div>
        </div>
        <div class="row mud-custom">
          <div class="col-12 col-md-6">
            <span>Mud Name</span>
            <input type="text" class="form-control" formControlName="mudName">
          </div>
        </div>
        <div class="row mud-custom-unit">
          <div class="col-12 col-md-3">
            <span>Mud Density*</span>
            <input-unit pKeyFilter="num" formControlName="mudDensity" unit={{UnitType.Density}}></input-unit>
          </div>
          <div class="col-12 col-md-1 custom-unit">
            <span [unitType]="UnitType.Density"></span>
          </div>
        </div>
        <div class="error-message">
          <field-error-display [displayError]="formMudParameter.invalid" [errorMsg]="errorMessages"></field-error-display>
        </div>
      </div>
      <p-footer>
        <a href="javascript:void(0);" class="btn-cancel mr-5" (click)="closeMud()">Cancel</a>
        <button type="button" class="btn primary-btn update-custom" (click)="updateMud()">UPDATE</button>
      </p-footer>
    </p-dialog>
  </div>
</div>