import { UntypedFormGroup } from '@angular/forms';
import { DeadVolumeFluidType, MIXING_PROCEDURE, SlurrySource, UnitType } from 'libs/constants';
import { FluidMaterialModel, FluidModel, ISAPMaterialModel, MaterialManagementMappingModel, MixingProcedure, PumpScheduleStageMaterialModel, PumpScheduleStageModel } from 'libs/models';
import { PumpScheduleStageMaterialCOGSModel } from 'libs/models/entities/pump-schedule-stage-cogs.model';
import { MaterialService } from 'libs/shared/services';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { SelectItem } from 'primeng/api';
import { combineLatest, concat, from, Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter, map, mergeAll, pairwise, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { MaterialNumberAssignedFlag } from '../../shared/constant/slurry-source';
import { MissingDataMaterial, NoCogsMaterialModel } from '../../shared/models/no-cogs-material.model';
import { PumpScheduleFormManager } from '../form-manager';
import { ViewState } from '../view-state';
import { CalculatedCogs, CalculatedVolume, FluidMaterialCalculator } from './calculators/material-calculator';
import { StageFluidCalculator } from './calculators/stage-calculator';
import { PumpScheduleStateFactory } from './state-factory';

interface ICalculatedVolumeField {

    [key: string]: CalculatedVolume | number;
}

const AddToMixWaterId = '07c80e99-5b3c-474d-b94a-34c14214fe5c';

export class FluidMaterialStateManager {

    private static readonly _stageMaterialModelFields: string[] = Object.keys(new PumpScheduleStageMaterialModel());

    private static readonly _fluidMaterialModelFields: string[] = Object.keys(new FluidMaterialModel());

    private readonly _subscriptions = new Subscription();

    public readonly calc: FluidMaterialCalculator;

    public readonly form: UntypedFormGroup;

    private _availableMaterialMappings: MaterialManagementMappingModel[];

    private _materialMappingModel: MaterialManagementMappingModel;

    private _calculatedCogs$ = this._viewState.isCogsCalculationDisabled$
        .pipe(
            switchMap(disabled => {

                if (disabled) {

                    return of({
                        uomIncompatible: false,
                        value: this.model.totalCOGS
                    });

                } else {

                    return this.calc.cogs$;
                }
            }),
            shareReplay()
        );

    private readonly _materialMapping$: Observable<MaterialManagementMappingModel> =
        this._getMaterialMapping$(this._fluidMaterialModel.materialId);

    public readonly cogs$: Observable<PumpScheduleStageMaterialCOGSModel> = this._calculatedCogs$
        .pipe(
            map(cost => {

                return this._toMaterialCost(cost);
            }),
            shareReplay()
        );
    public readonly cogsUomIncompatible$: Observable<boolean> = this._calculatedCogs$
        .pipe(
            map(cost => {

                return cost.uomIncompatible;
            }),
            shareReplay()
        );

    public readonly cogsAvailable$: Observable<boolean> = this._calculatedCogs$
        .pipe(
            map(cost => {

                return !cost.uomIncompatible && (!!cost.value || cost.value === 0);
            }),
            shareReplay()
        );

    public readonly noCogs$: Observable<MissingDataMaterial> = this.cogsAvailable$
        .pipe(
            map(available => {

                return !available ? this._toMisingDataMaterialModel(this._fluidMaterialModel) : null;
            }),
            shareReplay()
        );

    public readonly bulkDensityAvailable$: Observable<boolean>;

    public readonly noBulkDensity$: Observable<MissingDataMaterial>;
    
    public readonly overrideMixingProcedureId$: Observable<string>;

    public constructor(

        public readonly model: PumpScheduleStageMaterialModel,

        public readonly _fluidMaterialModel: FluidMaterialModel,

        private readonly _baseCementMaterial: FluidMaterialModel,

        private readonly _availableMixes$: Observable<MixingProcedure[]>,

        public readonly dropdownMixItems$: Observable<SelectItem<string>[]>,

        stageIndex: number,

        private readonly _fluidModel: FluidModel,

        private readonly _blendComponentsCount: number,

        stageCalc: StageFluidCalculator,

        private readonly _availableMaterialMappings$: Observable<MaterialManagementMappingModel[]>,

        private readonly _viewState: ViewState,

        private readonly _materialService: MaterialService,

        private readonly _unitConversionService: UnitConversionService,

        private readonly _formManager: PumpScheduleFormManager,

        private readonly _stageModel: PumpScheduleStageModel,
    ) {

        this.form = this._formManager.createMaterialForm(stageIndex, this.model.order);

        const formValue = this._buildFormValue(
            this.model,
            this._fluidMaterialModel,
            this._fluidModel,
            this._blendComponentsCount
        );

        this._formManager.patchSilent(this.form, formValue);

        this.calc = this._createMaterialCalculator(stageCalc);

        this.bulkDensityAvailable$ = this.calc.dryVolume$
            .pipe(
                map(dryVolume => {

                    return !!dryVolume.Value || dryVolume.Value === 0 || !this.isDry;
                }),
                shareReplay()
            );

        this.noBulkDensity$ = this.bulkDensityAvailable$
            .pipe(
                map(available => {

                    return !available ? this._toMisingDataMaterialModel(this._fluidMaterialModel) : null;
                }),
                shareReplay()
            );

        this.overrideMixingProcedureId$ = this.calc.loadoutVolume$
            .pipe(
                map(_ => {

                    if (!this._materialService.isBlend(this._fluidMaterialModel) && this.isDry && !this.model.overrideMixingProcedureId) {

                        return this._findMixProcedure(this._fluidMaterialModel);
                    }
                    
                    return this.model.overrideMixingProcedureId;
                })
            );

        this._subscribeToChanges();
    }

    public get isWater(): boolean {

        return this._materialService.isWater(this._fluidMaterialModel.materialType);
    }

    public get isCement(): boolean {

        return this._fluidMaterialModel.materialType === this._materialService.CementType;
    }

    public get isBlend(): boolean {

        return this._materialService.isBlend(this._fluidMaterialModel);
    }

    public get isAdditive(): boolean {

        return this._materialService.isAdditive(this._fluidMaterialModel, this._baseCementMaterial);
    }

    public get isSupplemental(): boolean {

        return this._materialService.isSupplemental(this._fluidMaterialModel);
    }

    public get isMixWater(): boolean {

        return this._materialService.isMixWater(this._fluidMaterialModel);
    }

    public get isLiquid(): boolean {

        return this._fluidMaterialModel.mixingProcedureValue === MIXING_PROCEDURE.PH;
    }

    public get isDry(): boolean {

        return this.model.loadoutVolumeUnit === UnitType.Weight;
    }

    public get concentration(): number {

        return this._fluidMaterialModel.concentration;
    }

    public get isDryVolumeCalculationDisabled(): boolean {

        return this._viewState.isDryVolumeCalculationDisabled;
    }

    public destroy(): void {

        this._subscriptions.unsubscribe();
    }

    public updateSap(sap: ISAPMaterialModel): void {

        const noSap: boolean = !sap || (!sap.materialNumber && !sap.materialName);
        if (noSap) {

            this.model.sapMaterialNumber = null;
            this.model.sapMaterialName = null;

            this._fluidMaterialModel.sapMaterialNumber = null;
            this._fluidMaterialModel.sapMaterialName = null;
            
        } else {

            this.model.sapMaterialNumber = sap.materialNumber;
            this.model.sapMaterialName = sap.materialName;

            this._fluidMaterialModel.sapMaterialNumber = sap.materialNumber;
            this._fluidMaterialModel.sapMaterialName = sap.materialName;
        }

        this._updateSapDisplayName(this.model.sapMaterialNumber);
    }

    public updateSapManually(sap: ISAPMaterialModel, material: PumpScheduleStageMaterialModel): void {

        this.updateSap(sap);
        
        //turn off manual flag if mapping sap and selected sap are equal
        const noSap: boolean = !sap || (!sap.materialNumber && !sap.materialName);
        const mappingModelMaterialNo: string = this._materialMappingModel && Boolean(this._materialMappingModel.sapMaterialNumber) ? this._materialMappingModel.sapMaterialNumber : null;
        this._fluidMaterialModel.materialNumberAssignedFlag = (noSap && mappingModelMaterialNo) 
            || (!noSap && sap.materialNumber !== mappingModelMaterialNo) ? MaterialNumberAssignedFlag.Manual : null;

        const stageMaterial = this._stageModel?.slurry?.fluidMaterial?.find(x => x.id === material.fluidMaterialId);

        if (stageMaterial !== null) {
            stageMaterial.materialNumberAssignedFlag = this._fluidMaterialModel.materialNumberAssignedFlag;
        }
    }

    private _subscribeToChanges(): void {

        this._subscriptions.add(
            from([
                this.calc.plannedVolume$
                    .pipe(
                        map(plannedVolume => {

                            return {
                                plannedVolume: plannedVolume,
                            };
                        })
                    ),
                combineLatest([
                    this.calc.loadoutVolume$,
                    this.calc.deadVolumeType$
                ])
                    .pipe(
                        startWith([null, null]),
                        pairwise(),
                        filter(([prior, current]) => {
                            return prior[0] === null 
                                || current[1] === DeadVolumeFluidType.CementSlurry
                        }),
                        map(([prior, current]) => {

                            return {
                                loadoutVolume: current[0]
                            };
                        })
                    ),
                this.calc.overrideVolume$
                    .pipe(
                        map(overrideVolume => {
                
                            return {
                                overrideVolume: overrideVolume
                            };
                        })
                    ),
                this.calc.actualVolume$
                    .pipe(
                        map(actualVolume => {

                            return {
                                actualQty: actualVolume
                            };
                        })
                    ),
                this.calc.dryVolume$
                    .pipe(
                        map(dryVolume => {

                            return {
                                dryVolume: dryVolume,
                                default: 0
                            };
                        })
                    )
            ])
            .pipe(
                mergeAll()
            )
            .subscribe((change: ICalculatedVolumeField) => {

                const field = Object.keys(change)[0];
                const value = change[field] as CalculatedVolume;
                const defVal = change.default as number;

                this._setFieldValue(field, value, defVal);
            })
        );

        this._subscriptions.add(

            this._calculatedCogs$.subscribe(cost => {

                this.model.totalCOGS = cost.value;
            })
        );

        this._subscriptions.add(

            combineLatest([
                this.calc.disableManualOverride$,
                this.calc.isLiquid$
            ])
            .pipe(debounceTime(0)).subscribe(([disable, isLiquid]) => {

                if (disable) {

                    if (this.form.controls.overrideVolume.enabled) {

                        this.form.controls.overrideVolume.disable(PumpScheduleFormManager.silent);
                    }

                } else {

                    if (!this.form.controls.overrideVolume.enabled) {

                        this.form.controls.overrideVolume.enable(PumpScheduleFormManager.silent);

                        if (this.form.controls.overrideVolume.value) {

                            this._formManager.patchSilent(this.form, { overrideVolume: null });

                            if (!isLiquid) {
                                this.form.controls.overrideVolume.updateValueAndValidity();
                            }
                        }
                    }
                }
            })
        );

        this._subscriptions.add(

            this.form.controls.overrideMixingProcedureId.valueChanges
              .pipe(withLatestFrom(this._availableMixes$))
              .subscribe(([value, availableMixes]) => {

                if (this.form.controls.overrideMixingProcedureId.dirty) {

                    this.model.overrideMixingProcedureId = value;
                    this.model.overrideMixingProcedure = availableMixes.find(mix => mix.id === value);
                }
            })
        );

        this._subscriptions.add(
            combineLatest([
                this.calc.deadVolumeType$,
                this.calc.plannedVolume$
            ])
            .subscribe(([
                deadVolumeType,
                plannedVolume,
            ]) => {
                if (deadVolumeType === DeadVolumeFluidType.MixFluid) {
                    this._setFieldValue("loadoutVolume", plannedVolume, 0);
                }
            })
        );

        this._subscriptions.add(

            this.form.controls.actualQty.valueChanges.subscribe(value => {

                if (this.form.controls.actualQty.dirty) {

                    this.model.actualQty = value;
                }
            })
        );

        this._subscriptions.add(

            this._materialMapping$.subscribe(mapping => {

                this._materialMappingModel = mapping || null;

                this._updateSapNumber(mapping);

                this._updateSapDisplayName(this.model.sapMaterialNumber, mapping);
            })
        );

        this._subscriptions.add(

            this.overrideMixingProcedureId$.subscribe(overrideMixingProcedureId => {

                this.model.overrideMixingProcedureId = overrideMixingProcedureId;
            })
        );

        this._subscriptions.add(

            combineLatest([this._availableMixes$, this.overrideMixingProcedureId$])
                .subscribe(

                    ([availableMixes, overrideMixingProcedureId]) => {

                        this._setMix(availableMixes, overrideMixingProcedureId);
                    })
        );
    }

    private _setMix(avalableMixes: MixingProcedure[], overrideMixingProcedureId: string): void {

        if (!!overrideMixingProcedureId) {

            const foundMix = avalableMixes.find(am => am.id === overrideMixingProcedureId);
            if (!!foundMix) {

                this._formManager.patchSilent(this.form, { overrideMixingProcedureId: overrideMixingProcedureId });
                this.model.overrideMixingProcedure = foundMix;
                this.form.controls.overrideMixingProcedureId.updateValueAndValidity();
            }
        }
    }

    private _toNumberOrDefault(value: number, defVal: number): number {

        if (value !== 0 && (!value || isNaN(Number(value)))) {

            return defVal;
        }

        return Number(value);
    }

    private _setFieldValue(field: string, volume: CalculatedVolume, defVal: number): void {

        if (defVal === undefined) {

            defVal = null;
        }

        const value = this._toNumberOrDefault(volume.Value, defVal);

        let modelField = field;
        if (field === 'plannedVolume') {

            modelField = 'plannedQty';
        }

        const unitTypeField = `${field}Unit`;

        if (FluidMaterialStateManager._stageMaterialModelFields.includes(modelField)) {

            this.model[modelField] = value;

            if (volume.UnitType
                && FluidMaterialStateManager._stageMaterialModelFields.includes(unitTypeField)) {

                this.model[unitTypeField] = volume.UnitType;
            }
        }

        if (FluidMaterialStateManager._fluidMaterialModelFields.includes(field)) {

            this._fluidMaterialModel[field] = value;

            if (volume.UnitType
                && FluidMaterialStateManager._fluidMaterialModelFields.includes(unitTypeField)) {

                this._fluidMaterialModel[unitTypeField] = volume.UnitType;
            }
        }

        const formPatch = {};
        const ctrl = this.form.controls[field];
        const currentCtrlVal = ctrl && ctrl.value;

        if (ctrl && currentCtrlVal !== volume.Value) {

            formPatch[field] = value;
        }

        if (volume.UnitType && this.form.controls[unitTypeField]) {

            formPatch[unitTypeField] = volume.UnitType;
        }

        if (Object.keys(formPatch).length > 0) {

            this._formManager.patchSilent(this.form, formPatch);
        }
    }

    private _getMaterialMapping$(materialId: string): Observable<MaterialManagementMappingModel> {

        return this._availableMaterialMappings$
            .pipe(
                map(mappings => {

                    this._availableMaterialMappings = mappings;
                    return mappings.find(m => m.materialId === materialId);
                }),
                shareReplay()
            );
    }

    private _updateSapNumber(mapping: MaterialManagementMappingModel): void {

        const mappingSapNumber: string = !mapping || !mapping.sapMaterialNumber ? null : mapping.sapMaterialNumber;
        
        if (this._fluidMaterialModel.materialNumberAssignedFlag === MaterialNumberAssignedFlag.Manual) {
            return;
        }

        if (!mapping || this.model.sapMaterialNumber === mappingSapNumber || this._isSapNumberFromHdf()) {

            // do not overwrite (clear) SAP number which comes from HDF if there is no mapping in MMT
            return;
        }

        this.model.sapMaterialNumber = mappingSapNumber;
        this.model.sapMaterialName = mapping.sapMaterialName;
        this._fluidMaterialModel.sapMaterialNumber = mappingSapNumber;
        this._fluidMaterialModel.sapMaterialName = mapping.sapMaterialName;

        this._formManager.patchSilent(
                this.form, {
                sapMaterialNumber: this.model.sapMaterialNumber,
                sapMaterialName: this.model.sapMaterialName
            }
        );
    }

    private _isSapNumberFromHdf(): boolean {

        return this._fluidModel.slurrySource === SlurrySource.HDFFluid && !!this.model.sapMaterialNumber;
    }

    private _updateSapDisplayName(sapMaterialNumber: string, mapping: MaterialManagementMappingModel = null): void {

        if (this._isSapNumberFromHdf()) {

            mapping = mapping || this.findMaterialMapping(sapMaterialNumber);

            if (mapping && mapping.sapDisplayName) {

              this.model.sapMaterialDisplayName = mapping.sapDisplayName;

            } else

            if (!this.model.sapMaterialDisplayName && this.model.sapMaterialNumber && this.model.sapMaterialName) {

                const trimmedSapNumber = this.model.sapMaterialNumber.replace(/^0*/, '');
                const sapNumberInName = `(${ trimmedSapNumber })`;
                const sapMaterialDisplayName = this.model.sapMaterialName.replace(sapNumberInName, '').trimEnd();

                this.model.sapMaterialDisplayName = sapMaterialDisplayName;
            }

        } else {

            mapping = (mapping && mapping.sapMaterialNumber === sapMaterialNumber ? mapping : null) || this.findMaterialMapping(sapMaterialNumber);

            if (mapping) {
                this.model.sapMaterialDisplayName = mapping.sapDisplayName ? mapping.sapDisplayName : mapping.sapMaterialName;
            } else {
                this.model.sapMaterialDisplayName = this.model.sapMaterialName;
            }
        }
    }

    private findMaterialMapping(sapNumber: string): MaterialManagementMappingModel {
        const ifactId: string = this.model.ifactMaterialId;
        if (!this._availableMaterialMappings) return undefined;
         return sapNumber == null || sapNumber === '' ? 
                this._availableMaterialMappings.find(m => m.materialId === ifactId)
                : this._availableMaterialMappings.find(m => m.materialId === ifactId 
                                                    && m.sapMaterialNumber === sapNumber);
    }

    private _toMaterialCost(cost: CalculatedCogs): PumpScheduleStageMaterialCOGSModel {

        if (cost) {

            this._fluidMaterialModel.totalCOGS = (!!cost.value || cost.value === 0) ? cost.value : null;

        } else {

            this._fluidMaterialModel.totalCOGS = null;
        }

        const materialCost = new PumpScheduleStageMaterialCOGSModel();
        materialCost.id = this._fluidMaterialModel.id;
        materialCost.totalCOGS = this._fluidMaterialModel.totalCOGS;

        return materialCost;
    }

    private _toMisingDataMaterialModel(model: FluidMaterialModel): MissingDataMaterial {

        return new NoCogsMaterialModel(
            Number(model.materialId),
            model.materialName,
            model.sapMaterialName,
            model.sapMaterialNumber,
            1,
            null
        );
    }

    private _createMaterialCalculator(stageCalc: StageFluidCalculator): FluidMaterialCalculator {

        const overrideVolume$: Observable<number> = concat(
                of(this.model.overrideVolume),
                this.form.controls.overrideVolume.valueChanges
            )
            .pipe(
                shareReplay()
            );

        const actualVolume$: Observable<number> = concat(
                of(this.model.actualQty),
                this.form.controls.actualQty.valueChanges.pipe(map(v => v))
            )
            .pipe(
                shareReplay()
            );

        const hasDryWeight$: Observable<boolean> =
            this.isBlend
                ? of(true)
                : concat(
                        of(this.model.overrideMixingProcedureId),
                        this.form.controls.overrideMixingProcedureId.valueChanges
                    )
                    .pipe(
                        map(mixId => {
                            return this._hasDryWeight(mixId);
                        }),
                        shareReplay()
                    );

        const addToMixWater$: Observable<boolean> = 
                combineLatest([
                    concat(
                        of(this.model.overrideMixingProcedureId),
                        this.form.controls.overrideMixingProcedureId.valueChanges
                    ),
                    stageCalc.deadVolumeType$
                ])
                .pipe(
                    map(([mixId, deadVolumeType]) => {
                        return mixId === AddToMixWaterId && deadVolumeType === DeadVolumeFluidType.MixFluid
                    }),
                    shareReplay()
                );

        return new FluidMaterialCalculator(
            this._materialMapping$,
            stageCalc,
            this._fluidModel.mixVolume,
            this._fluidModel.yield,
            this._fluidModel.sackWeight,
            this._fluidModel.mixWater,
            this._fluidModel.waterDensity,
            this._fluidModel.density,
            this._fluidMaterialModel.outputUnit,
            this._fluidMaterialModel.sg,
            this._fluidMaterialModel.testAmount,
            this.model.concentration,
            this.model.concentrationUnit,
            this._fluidMaterialModel.bulkDensity,
            this.isLiquid,
            overrideVolume$,
            actualVolume$,
            this.model.dryVolume,
            hasDryWeight$,
            this.isDryVolumeCalculationDisabled,
            this._unitConversionService,
            this.model.plannedScope3Co2e,
            this.model.actualScope3Co2e,
            addToMixWater$,
            this.isAdditive
        );
    }

    private _buildFormValue(
        model: PumpScheduleStageMaterialModel,
        fluidMaterialModel: FluidMaterialModel,
        fluidModel: FluidModel,
        blendComponentsCount
    ): { [key: string]: any } {

        const formValue = {
            ...model,
            materialType: fluidMaterialModel.materialType,
            ifactMaterialId: fluidMaterialModel.materialId,
            isBlendComponent: fluidMaterialModel.isBlendComponent,
            testAmount: fluidMaterialModel.testAmount,
            outputUnit: fluidMaterialModel.outputUnit,
            sg: fluidMaterialModel.sg,
            plannedVolume: model.plannedQty,
            blendBulkDensity: fluidModel.cemmentBulkDensity,
            cementBulkDensity: fluidMaterialModel.bulkDensity,
            mixWater: fluidModel.mixWater,
            sackWeight: fluidModel.sackWeight,
            countBlendComponent: blendComponentsCount,
            notExistedSAPMaterial: fluidMaterialModel.notExistedSAPMaterial,
            hdfMaterialId: fluidMaterialModel.hdfMaterialId,
            overrideMixingProcedure: fluidMaterialModel.mixingProcedureValue
        };

        return formValue;
    }

    private _hasDryWeight(mixId: string): boolean {

        const found = PumpScheduleStateFactory.dryWeightEnabledMaterialProcedures.find(ep => ep === mixId);
        return !! found;
    }

    private _findMixProcedure(fluidMaterial: FluidMaterialModel): string {

        let mixEntry = fluidMaterial.mixingProcedureValue;

        if (!mixEntry) {

            mixEntry = '_';    // no mixing procedure

            if (this._materialService.isSupplemental(fluidMaterial)) {
                
                mixEntry = 'PH';    // default mix for supplemental is 'Add to Mix Water'
            }
        }

        let foundMixEntry = '_';
        const mixProcedureKeys = Object.keys(PumpScheduleStateFactory.iFactsMaterialMixProceduresMap);
        for (const p of mixProcedureKeys) {

            if (p === mixEntry) {

                foundMixEntry = p;
                break;
            }
        }

        return PumpScheduleStateFactory.iFactsMaterialMixProceduresMap[foundMixEntry];
    }
}
