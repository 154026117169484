<nav *ngIf="isShowLeftNav > 0" class="sidebar-nav">
  <div class="background-left"></div>
  <ul>
    <li class="external-icon m-top-1" style="height: 18px" *ngIf="modalManagementService.notifyIFactDown$ | async"></li>
    <li class="external-icon m-top-1">
      <a href="" [routerLink]="['/active-jobs']" (click)="activeClass('active-jobs')" routerLinkActive="active-item">
        <img src="../../../../assets/images/activeJobs.png" class="external-icon-img ">
        <span class="navigator-title">
          Active Jobs</span>
      </a>
    </li>
    <li class="external-icon lite-spacing">
      <a href="" [routerLink]="['/saved-jobs']" (click)="activeClass('saved-jobs')" routerLinkActive="active-item">
        <img src="../../../../assets/images/savedJobs.png" class="external-icon-img ">
        <span class="navigator-title">
          Saved Jobs</span>
      </a>
    </li>
    <li class="external-icon lite-spacing">
      <a href="" [routerLink]="['/my-jobs']" (click)="activeClass('my-jobs')" routerLinkActive="active-item">
        <img src="../../../../assets/images/myJobs.png" class="external-icon-img ">
        <span class="navigator-title">
          My Jobs</span>
      </a>
    </li>
    <li class="divider"><hr /></li>
    <li class="external-icon lite-spacing">
      <a (click)="wellExpanded = !wellExpanded">
        <img src="{{getSrc(wellExpanded)}}" class="external-icon-img " #wellIcon>
        <span class="navigator-title" #wellTitle> Wells</span>
      </a>
      <ul class="sub-menu" *ngIf="wellExpanded" style="max-height:300px; overflow-y:auto;">
        <li *ngFor="let well of listWell" [pTooltip]="well.groupNameByWell" tooltipStyleClass="well-tooltip">
          <a [routerLink]="['/wells']" [queryParams]="{id: well.wellId}" (click)="wellChildExpanded = well.wellId;activeClass('wells')" routerLinkActive="title-active">
            {{well.groupNameByWell}}
          </a>
        </li>
      </ul>
    </li>


    <li class="external-icon lite-spacing">
      <a (click)="collectionExpanded = !collectionExpanded">
        <img src="{{getSrc(collectionExpanded)}}" class="external-icon-img " #collectionIcon>
        <span class="navigator-title" #collectionTitle>Collections</span>
      </a>
      <ul class="sub-menu" *ngIf="collectionExpanded" style="max-height:300px; overflow-y:auto;">
        <li *ngFor="let collect of collections" [pTooltip]="collect.name" tooltipStyleClass="well-tooltip">
          <a [routerLink]="['/collections']" [queryParams]="{id: collect.id}"  (click)="collectionChildExpanded = collect.id;activeClass('collections')" routerLinkActive="title-active">
            {{ collect.name}}
          </a>
        </li>
      </ul>
    </li>
    <li class="external-icon">
      <a (click)="showCreateOption()" [class.menu-disabled]="modalManagementService.notifyIFactDown$ | async">
        <img src="../../../../assets/images/startNewJob.png" class="external-icon-img ">
        <span class="navigator-title"> Start New Job</span>
      </a>
    <li class="external-icon">
      <a (click)="showCollection()">
        <img src="../../../../assets/images/createCollection.png" class="external-icon-img"> <span class="navigator-title">Create
          Collection</span>
      </a>
    </li>

    <li class="external-icon lite-spacing">
      <a (click)="toolExpanded = !toolExpanded">
        <img src="{{getSrc(toolExpanded)}}" class="external-icon-img">
        <span class="navigator-title">Tools</span>
      </a>
      <ul class="sub-menu" *ngIf="toolExpanded" style="max-height:300px; overflow-y:auto;">
        <li *ngIf="isIFactsVisualizerVisible">
          <a (click)="showIfactSearchPage()">
            iFacts Visualizer
          </a>
        </li>
        <li *ngIf="hasScope3access">
          <a (click)="showCarbonFootprintSidebar()">
            CO2e per <span [unitType]="UnitType.LargeVolume"></span>
          </a>
        </li>
        <li>
          <a href="{{appianKPISystemLink}}" target="_blank">
            KPI System
          </a>
        </li>
          <ng-container *ngIf="isBulkBoardEnabled">
            <li *ngIf="shouldDisplayBulkBoardItem$ | async">
                <a (click)="updateAppianBulkBoardSettings()">
                Bulk Board
                </a>
            </li>
          </ng-container>
      </ul>
    </li>

    <li class="external-icon">
      <a (click)="showUserSetting()">
        <img src="../../../../assets/images/userSettings.png" class="external-icon-img"> <span class="navigator-title">User
          Settings</span>
      </a>
    </li>
    <li class="external-icon" *ngIf="isAdmin || isEnhanced">
      <a (click)="showSecurityAdmin()" id="at-menu-admin">
        <img src="../../../../assets/images/maintenance.png" class="external-icon-img"> <span class="navigator-title">Maintenance</span>
      </a>
    </li>
  </ul>
</nav>
