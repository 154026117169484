import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import { environment } from 'libs/environment';
import { Role, Roles, RoleLevels } from 'apps/vida/src/modules/application/models';
import { HttpService } from './http.service';
import { UserSettingService } from './user-setting.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class RoleService {
  private _roles$ = new BehaviorSubject<Role[]>([]);
  public get roles$() { return this._roles$.asObservable(); }
  public get roles(): Role[] { return this._roles$.value; }

  // Global level
  public isAdmin: boolean;
  public isReviewer: boolean;
  public isRegion: boolean;
  public isGlobal: boolean;
  public isEnhanced: boolean;
  public isMember: boolean;

  // Group level
  public isBasicForCurrentGroup: boolean;
  public isReviewerForCurrentGroup: boolean;
  public isEnhancedForCurrentGroup: boolean;
  public isAdminForCurrentGroup: boolean;
  // ----

  // Opened job level
  public isJobReviewer: boolean;
  // ----

  private _isReviewer$ = new BehaviorSubject<boolean>(false);
  public get isReviewer$() { return this._isReviewer$.asObservable(); }

  private _isBasicForCurrentGroup$ = new BehaviorSubject<boolean>(false);
  private _isReviewerForCurrentGroup$ = new BehaviorSubject<boolean>(false);
  private _isEnhancedForCurrentGroup$ = new BehaviorSubject<boolean>(false);
  public get isBasicForCurrentGroup$() { return this._isBasicForCurrentGroup$.asObservable(); }
  public get isReviewerCurrentGroup$() { return this._isReviewerForCurrentGroup$.asObservable(); }
  public get isEnhancedForCurrentGroup$() { return this._isEnhancedForCurrentGroup$.asObservable(); }

  private _isJobReviewer$ = new BehaviorSubject<boolean>(false);
  public get isJobReviewer$() { return this._isJobReviewer$.asObservable(); }

  constructor(private http: HttpService, private userSettingService: UserSettingService) {
  }

  setRoles(roles: Role[]) {
    this.isAdmin = roles && roles.some(r => r.roleName === Roles.Admin);
    this.isReviewer = roles && roles.some(r => r.level >= RoleLevels.Reviewer);
    this.isRegion = roles && roles.some(r => r.level >= RoleLevels.Region);
    this.isGlobal = roles && roles.some(r => r.level >= RoleLevels.Global);
    this.isEnhanced = roles && roles.some(r => r.level >= RoleLevels.Enhanced);
    this.isMember = roles && roles.some(r => r.level >= RoleLevels.Basic);
    this.isBasicForCurrentGroup = roles && roles.some(r => r.level === RoleLevels.Basic && r.groupId === this.userSettingService.selectedLocation.groupId);
    this.isReviewerForCurrentGroup = roles && roles.some(r => r.level >= RoleLevels.Reviewer && r.groupId === this.userSettingService.selectedLocation.groupId);
    this.isEnhancedForCurrentGroup = roles && roles.some(r => r.level >= RoleLevels.Enhanced && r.groupId === this.userSettingService.selectedLocation.groupId);
    this.isAdminForCurrentGroup  = roles && roles.some(r => r.roleName === Roles.Admin && r.groupId === this.userSettingService.selectedLocation.groupId);
    this._isReviewer$.next(this.isReviewer);
    this._isBasicForCurrentGroup$.next(this.isBasicForCurrentGroup);
    this._isReviewerForCurrentGroup$.next(this.isReviewerForCurrentGroup);
    this._isEnhancedForCurrentGroup$.next(this.isEnhancedForCurrentGroup);
    this._roles$.next(roles);
  }

  setJobRoles(roles: Role[]) {
    this.isJobReviewer = roles && roles.some(r => r.level >= RoleLevels.Reviewer);
    this._isJobReviewer$.next(this.isJobReviewer);
  }

  getUserRole(groupId: string): Observable<Role[]> {
    return this.http.Get<Role[]>(`${environment.baseUrl}/api/groups/${groupId}/roles`);
  }

  refreshUserRoles(): Observable<Role[]> {
      //getUserRole(this.userSettingService.selectedLocation.groupId)
    return this.getCurrentUserRoles().pipe(
      tap(roles => this.setRoles(roles)));
  }

  getCurrentUserRoles(): Observable<Role[]> {
    return this.http.Get(`${environment.baseUrl}/api/roles`);
  }

  isAdminOrReviewer(groupId: string) {
    return this.isReviewerForCurrentGroup || this.roles.find(r => r.groupId === groupId && r.roleName == Roles.Admin);
  }

  isReviewerOrAboveInAnyGroup() {
    return this.isAdmin || this.userSettingService.locations.some(location =>
      this.roles && this.roles.some(role => (role.level >= RoleLevels.Reviewer)
                                             && role.groupId === location.groupId));
  }
}
