import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from 'libs/environment';
import { AdUser } from 'libs/models';
import { JobsService } from 'libs/shared';
import { MessageService } from 'primeng/api';
import { Subscription, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'change-job-owner-dialog',
  templateUrl: './change-job-owner-dialog.component.html',
  styleUrls: ['./change-job-owner-dialog.component.scss']
})

export class ChangeJobOwnerDialogComponent implements OnInit {

  jobId: string;
  jobOwnerNetworkId: string;
  members: AdUser[] = [];
  isVisible: boolean = false;
  
  result: AdUser = null;
  result$ = new Subject<string>();

  jobOwnerUpdate = new Subject<boolean>();
  subscriptions: Subscription = new Subscription();

  noUserErrorMessage = 'No user is found';
  error = null;

  constructor(
    private jobsService: JobsService,
    private messageService: MessageService,
    private ref: ChangeDetectorRef
    ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.result$.pipe(
        switchMap(keyword => this.jobsService.getUsersToSetAsJobOwner(this.jobId, keyword))
      ).subscribe((data: AdUser[]) => {
          
          this.members = data.map((member: AdUser) => {
            member.display = `${member.firstName} ${member.lastName} (${member.networkId})`;
            return member;
          });

          if (!this.members.length) {
            this.error = this.noUserErrorMessage;
          }

          this.ref.detectChanges();
          }
      )
    );
  }

  onComplete(input: string) {
    this.error = null;
    this.result$.next(input);
  }

  onClear() {
    this.error = null;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  open(jobId: string, jobOwnerNetworkId: string) {
    this.result = null;
    this.error = null;
    this.jobId = jobId;
    this.jobOwnerNetworkId = jobOwnerNetworkId;
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.ref.detectChanges();
  }

  changeJobOwner() {
    this.subscriptions.add(
      this.jobsService.changeJobOwner(this.jobId, this.result)
      .subscribe(
        isSuccess => {
          if (isSuccess) {
            this.messageService.add({
              life: environment.messagePopupLifetimeMs,
              severity: 'success',
              detail: 'Success: New Job Owner has been assigned.'
            });
            this.close();
            this.jobOwnerUpdate.next(true);
          }
        },
        error => {
          this.messageService.add({
            life: environment.messagePopupLifetimeMs,
            severity: 'error',
            detail: 'Failure: New Job Owner has not been assigned.'
          });
        })
    );
  }
}
