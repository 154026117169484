import { FileStatus } from './fileStatus';

export class FileChunk {

    public constructor(

        public blob: Blob = null,

        public size: number = 0,

        public bytesUploaded: number = 0,

        public status: FileStatus = FileStatus.Preparing
    ) {
    }
}
