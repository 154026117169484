import { Job } from 'libs/models';
import { ApplicationStateService, JobStateService, RoleService, UserSettingService } from 'libs/shared/services';
import { BehaviorSubject, combineLatest, concat, merge, Observable, of } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { ControlPointState, ControlPointType } from '../shared/constant';

export class ViewState {

    private readonly _cp4CompletionSrc = new BehaviorSubject<any>({ complete: false, pumpScheduleId: null, isStageJob: false });

    public readonly cp4Completion$ = this._cp4CompletionSrc.asObservable();

    private readonly _cp1StateChange$: Observable<ControlPointState> =
        merge(
            this._applicationStateService.controlPoint1Submission$
                .pipe(
                    filter(jobId => this._job.id === jobId),
                    map(_ => {

                        return ControlPointState.Submitted;
                    })
                ),
            this._applicationStateService.controlPoint1Unlock$
                .pipe(
                    filter(jobId => this._job.id === jobId),
                    map(_ => {

                        return ControlPointState.Unlock;
                    })
                )
        )
        .pipe(
            shareReplay()
        );

    public readonly isCP1Submitted$: Observable<boolean> = 
        concat(
            of(this._isCP1Submitted),
            this._cp1StateChange$
                .pipe(
                    map(state => {

                        return state === ControlPointState.Submitted;
                    })
                )
        )
        .pipe(
            shareReplay()
        );

    public readonly cogsVisibleForReviewer$ = combineLatest([
            this._roleService.isJobReviewer$,
            this._userSettingsService.showCogs$
        ])
        .pipe(
            map(([r, s]) => {

                return r && s;
            }),
            shareReplay()
        );

    public readonly isOffshoreJob$: Observable<boolean> = this._applicationStateService.isChangeOffshore$
        .pipe(shareReplay());

    public constructor(

        private readonly _roleService: RoleService,

        private readonly _userSettingsService: UserSettingService,

        private readonly _applicationStateService: ApplicationStateService,

        private readonly _jobStateService: JobStateService,

        private readonly _job: Job,

        public readonly controlPointNumber: number = null,

        public Name: string = null,

    ) {
    }

    public get isExistingJob(): boolean {

        return !!this._job && !!this._job.id;
    }

    public get isJobEditable(): boolean {

        return !!this._job && this._job.canEdit;
    }

    public get isEditable(): boolean {

        return this.isJobEditable && this.isScheduleEditView;
    }

    public get isJobTypeLiner(): boolean {

        return !!this._job && this._job.isLiner;
    }

    public get isScheduleEditView(): boolean {

        return !this.controlPointNumber;
    }

    public get isControlPointView(): boolean {

        return !this.isScheduleEditView;
    }

    public get isCP1View(): boolean {

        return this.isControlPointView && this.controlPointNumber === ControlPointType.ControlPoint1;
    }

    public get isCP2View(): boolean {

        return this.isControlPointView && this.controlPointNumber === ControlPointType.ControlPoint2;
    }

    public get isCP4View(): boolean {

        return this.isControlPointView && this.controlPointNumber === ControlPointType.ControlPoint4;
    }

    public get currentCPinView(): number {

        if (this.isControlPointView)
            return this.controlPointNumber;
    }

    public isFieldVisible$(field: string, visibleFields: string[][]): Observable<boolean> {

        const viewIndex = this.controlPointNumber || 0;

        const visible = visibleFields[viewIndex].some(c => c === field);

        const visible$ = of(visible);

        if (field == 'iFactsActual') 
        {
            return of(this.controlPointNumber == 4)
        }

        if (field === 'totalPlannedCO2e')
        {
            return of(this.controlPointNumber === 1);
        }

        if (field === 'totalActualCO2e')
        {
            return of(this.controlPointNumber === 4);
        }

        if (field !== 'totalCOGS') {
            return visible$;
        }

        return combineLatest([visible$, this.cogsVisibleForReviewer$])
            .pipe(
                map(([v, cvr]) => v && cvr)
            );
    }

    public get isJobCompleted(): boolean {

        return this._job.jobStatus === this._jobStateService.findJobState('Completed');
    }

    public get isJobCancelled(): boolean {

        return this._job.jobStatus === this._jobStateService.findJobState('Cancelled');
    }

    public get isJobClosed(): boolean {

        return this._job.jobStatus === this._jobStateService.findJobState('Closed');
    }

    private get _isCP1Submitted(): boolean {

        return this._controlPointsAvaialable && this._CP1State === ControlPointState.Submitted;
    }

    public get isCP1Approved(): boolean {

        return this._controlPointsAvaialable && this._CP1State === ControlPointState.Approved;
    }

    public get isCP1Prepared(): boolean {

        return this._controlPointsAvaialable && this._CP1State === ControlPointState.Prepared;
    }

    public get isCP1Completed(): boolean {

        return this._controlPointsAvaialable && this._CP1State === ControlPointState.Completed;
    }

    public get isCP2Prepared(): boolean {

        return this._controlPointsAvaialable && this._CP2State === ControlPointState.Prepared;
    }

    public get isCP2Approved(): boolean {

        return this._controlPointsAvaialable && this._CP2State === ControlPointState.Approved;
    }

    public get isCP2Completed(): boolean {

        return this._controlPointsAvaialable && this._CP2State === ControlPointState.Completed;
    }

    public get isCP4Completed(): boolean {

        return this._controlPointsAvaialable && this._CP4State === ControlPointState.Completed;
    }

    public isNewMaterialCOGSCalculation : boolean = false;

    public get isCogsCalculationDisabled$(): Observable<boolean> {

        return this.isCP1Submitted$
            .pipe(
                map(isCP1Submitted => {
                    return (isCP1Submitted || this.isCP1Approved || this.isCP1Prepared || this.isCP1Completed) && !this.isNewMaterialCOGSCalculation;
                })
            );
    }

    public get isDryVolumeCalculationDisabled(): boolean {

        return this.isCP2Completed;
    }

    public get isCP4InCompletionMode(): boolean {
        return this._cp4CompletionSrc.value.complete;
    }

    public setCP4CompletionMode(): void {

        this._cp4CompletionSrc.next({
            complete: true,
            pumpScheduleId: !this._job.isStageJob ? this._job.pumpSchedules[0].pumpScheduleId : null,
            isStageJob: this._job.isStageJob
        });
    }

    private get _controlPointsAvaialable(): boolean {

        return !!this._job.controlPoints && this._job.controlPoints.length > 0;
    }

    private get _CP1State(): ControlPointState {

        return this._job.controlPoints[ControlPointType.ControlPoint1 - 1].eControlPointState;
    }

    private get _CP2State(): ControlPointState {

        return this._job.controlPoints[ControlPointType.ControlPoint2 - 1].eControlPointState;
    }

    private get _CP4State(): ControlPointState {

        return this._job.controlPoints[ControlPointType.ControlPoint4 - 1].eControlPointState;
    }

    public parseTimeStringToMinutes(timeString: string): number {

        const timeParts = this._timeMaskToTimeParts(timeString);

        if (!timeParts) {
            return null;
        }
        else {
            const totalMinutes = Number(timeParts.hours) * 60 + Number(timeParts.minutes);

            return typeof(totalMinutes) === 'number' && !isNaN(totalMinutes) ? totalMinutes : null;
        }
    }

    public timeMaskToTimeString(timeMask: string): string {

        const timeParts = this._timeMaskToTimeParts(timeMask);

        if (!timeParts || (timeParts.hours === 0 && timeParts.minutes === 0)) {

            return null;
        }

        const timeString = timeParts.hours.toString().padStart(2, '0');
        const minutesString = timeParts.minutes.toString().padStart(2, '0');

        return `${ timeString }:${ minutesString }`;
    }

    public formatTime(absoluteMinutes: number): string {

        if (!absoluteMinutes) {

            return null;
        }

        const minutes = absoluteMinutes % 60;
        const hours = ((absoluteMinutes - minutes) / 60).toString().padStart(2, '0');

        return `${hours}:${minutes.toFixed(0).toString().padStart(2, '0')}`;
    }

    private _timeMaskToTimeParts(timeMask: string): { 
        hours: number,
        minutes: number
    } {

        if (!timeMask || !timeMask.split) {

            return null;
        }

        if (timeMask === '__:__') {

            return null;
        }

        const timeParts = timeMask.split(':');
        if (timeParts.length !== 2) {

            return null;
        }

        const hours = Number(timeParts[0].replace(/_/g, '0'));
        const minutes = Number(timeParts[1].replace(/_/g, '0'));

        return {
            hours: hours,
            minutes: minutes
        };
    }

    public setControlPointState(controlPointType: ControlPointType, value: ControlPointState) {
        this._job.controlPoints[controlPointType - 1].eControlPointState = value;
    }
}
