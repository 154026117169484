<datetime-picker class="disable-except" 
    [ngClass]="setClasses()"
    [canRemove]="canRemove"
    (onRemove)="onRemove()"
    (onBlur)="_onBlur($event)"
    [maxDate]="maxDate"
    (select)="_onSelect($event)"
    format="{{userSettings?.dateTimeFormat?.dateTimeFormatName}}" 
    [(ngModel)]="dateprojected"
    (ngModelChange)="onChange($event)" (onClear)="clearProjectedDate()"
    [placeholder]="userSettings?.dateTimeFormat?.dateTimeFormatName"
    [defaultTime]="projectedDateDefaultTime"
    [showTime]="showTime"
    [disabled]="isDisabled">
</datetime-picker>
