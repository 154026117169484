import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ActualQuantityConfirmationService } from '../../services';

@Component({
  selector: 'actual-quantity-confirmation',
  templateUrl: './actual-quantity-confirmation.component.html',
  styleUrls: ['./actual-quantity-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActualQuantityConfirmationComponent implements OnInit, AfterViewInit, OnDestroy {

  public data: { plannedQty: string, actualQty: string };

  @ViewChild('focusElement', { static: false }) divElement: ElementRef;

  private _sub: Subscription = new Subscription();
  private _isConfirmed: boolean = false;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private actualQuantityConfirmationService: ActualQuantityConfirmationService
  ) { }

  ngAfterViewInit(): void {
    this.divElement.nativeElement.focus();
  }

  ngOnInit(): void {
    this.data = this.dynamicDialogConfig.data;
    this._sub = this.dynamicDialogRef.onClose.subscribe(() => this.actualQuantityConfirmationService.confirmActualQuantityChange(this._isConfirmed));
  }
  
  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  onCancel(): void {
    this.dynamicDialogRef.close();
  }

  onConfirm():void {
    this._isConfirmed = true;
    this.dynamicDialogRef.close();
  }
}
