import { Component, forwardRef, Input, OnInit, OnChanges, Injector, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { getPattern } from '../validations/pkeyfilter-reg-exp';
import { UnitBaseComponent } from './unit-base.component';

@Component({
  selector: 'input-unit',
  template: `
    <input type="text" [maxlength]="maxlength" class="form-control" [ngClass]="styleClass" [formControl]="_formControl"
      [pKeyFilter]="pKeyFilter" [pattern]="pattern" [pTooltip]="tooltip" [tooltipStyleClass]="tooltipClass"
      (onClick)="_onClick($event, this)" (focus)="_onFocus($event)"
      (blur)="_onBlur($event)" (input)="_onKeyUp($event.target.value)">
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputUnitComponent),
      multi: true
    }
  ]
})
export class InputUnitComponent extends UnitBaseComponent implements OnInit, OnChanges {
  @Input() styleClass: string;
  @Input() tooltip: string;
  @Input() tooltipClass: string;
  @Input() maxlength: string = '15';
  @Input() pKeyFilter: string = 'num';
  @Input() pattern: RegExp = getPattern(this.pKeyFilter);

  constructor(protected inj: Injector) {
    super(inj);
  }
  
}
