import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  PageHeaderComponent,
  CustomerTypeaheadComponent,
  DatetimePickerComponent,
  JobMetaDataComponent,
  FieldErrorDisplayComponent,
  JobUploaderComponent,
  HeadingElementComponent,
  ModalUpdateSOComponent,
} from './components';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UIModule } from 'libs/ui';
import { IFactsLinkPipe, PrimaryStatusPipe } from './pipes';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { JobProjectedDateComponent } from './components/job-projected-date/job-projected-date.component';
import { ModalLinkOriginJobComponent } from './components/modal-link-origin-job/modal-link-origin-job.component';
import { ModalRemoveLinkOriginJobComponent } from './components/modal-remove-link-origin-job/modal-remove-link-origin-job.component';
import { FixBlockUI } from './helpers/block-ui-fixing';
import { JobOriginatingComponent } from './components/job-originating/job-originating.component';
import { ControlPointStatuses } from './components/control-point-statuses/control-point-statuses.component';
import { HandOverEditComponent } from './components/hand-over-edit/hand-over-edit.component';
import { BlockUIModule } from 'primeng/blockui';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { ModalBhctValidationComponent } from './components/modal-bhct-validation/modal-bhct-validation.component';
import { JobStateModalComponent } from '../edit-job/components/job-state-modal/job-state-modal.component';
import {DateFormatWithLocalTimeZonePipe, TimeFormatWithLocalTimeZone} from 'apps/vida/src/modules/jobs/pipes';
import { JobErrorsModalComponent } from '../edit-job/components/job-errors-modal/job-errors-modal.component';
import { NoMaterialDataComponent } from './components/no-material-data-modal/no-material-data-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { InputNumberRangeDirective } from './directives/input-number-range.directive';
import { RemoveTrailingZeroes } from './directives/remove-trailing-zeroes.directive';
import { ChangeJobOwnerDialogComponent } from './components/change-job-owner-dialog/change-job-owner-dialog.component';
import { JobDateComponent } from './components/job-date/job-date.component';
import { MoreInfoTooltipComponent } from './components/more-info-tooltip/more-info-tooltip.component';
import { JobListStatusIconsComponent } from './components/job-list-status-icons/job-list-status-icons.component';
import { CustomerAutocompleteDatatableComponent } from './components/customer-autocomplete-datatable/customer-autocomplete-datatable.component';


const thirdPartyModules = [
  BlockUIModule,
  CalendarModule,
  CheckboxModule,
  UIModule,
  TypeaheadModule,
  TooltipModule,
  RouterModule,
  OverlayPanelModule,
  PanelModule,
  DropdownModule,
  FileUploadModule,
  TableModule,
  AutoCompleteModule,
  DialogModule,
  RadioButtonModule,
  SidebarModule,
];

// TODO: try to remove this fix after module update
FixBlockUI();

const components = [
  PageHeaderComponent,
  CustomerTypeaheadComponent,
  JobUploaderComponent,
  DatetimePickerComponent,
  JobMetaDataComponent,
  IFactsLinkPipe,
  DateFormatWithLocalTimeZonePipe,
  TimeFormatWithLocalTimeZone,
  PrimaryStatusPipe,
  FieldErrorDisplayComponent,
  HeadingElementComponent,
  BreadcrumbComponent,
  JobProjectedDateComponent,
  ModalLinkOriginJobComponent,
  ModalRemoveLinkOriginJobComponent,
  JobOriginatingComponent,
  ControlPointStatuses,
  HandOverEditComponent,
  ModalBhctValidationComponent,
  ModalUpdateSOComponent,
  JobStateModalComponent,
  JobErrorsModalComponent,
  NoMaterialDataComponent,
  CustomerAutocompleteDatatableComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    ...thirdPartyModules
  ],
  declarations: [
    ...components,
    JobOriginatingComponent,
    CustomTooltipDirective,
    CustomTooltipComponent,
    InputNumberRangeDirective,
    RemoveTrailingZeroes,
    ChangeJobOwnerDialogComponent,
    JobDateComponent,
    MoreInfoTooltipComponent,
    JobListStatusIconsComponent,
    CustomerAutocompleteDatatableComponent
  ],
  exports: [
    ...components,
    ...thirdPartyModules,
    CustomTooltipDirective,
    InputNumberRangeDirective,
    RemoveTrailingZeroes,
    ChangeJobOwnerDialogComponent,
    JobDateComponent,
    MoreInfoTooltipComponent,
    JobListStatusIconsComponent
  ],
  providers: [DialogService]
})
export class CPESharedModule {
  static forRoot(): ModuleWithProviders<CPESharedModule> {
    return {
      ngModule: CPESharedModule,
      providers: []
    };
  }
}
