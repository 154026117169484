import {Message} from 'primeng/api';
import {environment} from 'libs/environment';

export class EnvanaDownYellowMessage implements Message {
  life = environment.messagePopupLifetimeMs;
  severity = 'warn';
  detail = 'Warning: Envana is unavailable and no carbon footprint calculations have been performed for this job.';
}

export class EnvanaDownRedMessage implements Message {
  life = environment.messagePopupLifetimeMs;
  severity = 'error';
  detail = 'Error: Envana is unavailable for carbon footprint calculations. If this problem persists please contact the ITSC.';
}

