import { CDIFieldTypeMapping } from "apps/vida/src/modules/control-point/components/cdi-criteria-responses/cdi.constant";

export class CDIQuestion {

  constructor(
    public id: string = null,
    public question: string = '',
    public negativeResponse: string = '',
    public evaluationResult: boolean = null,
    public title: string = null,
    public cdiFields: CDIField[] = []
  ) { }
}

export class CDIField {

  constructor(
    public id: string = null,
    public cdiValueId: string = null,
    public cdiQuestionFieldId: string = null,
    public unitClassEnum: number = null,
    public name: string = null,
    public isReadonly: boolean = null,
    public type: string = null,
    public decimalValue: number = null,
    public stringValue: string = null,
    public guidValue: string = null,
    public title: string = null,
    public precision: string = null,
  ) { }


  public get value(): string | number {
    return this[this.valueKey];
  }
  
  public get valueKey() : string {
    return CDIFieldTypeMapping[this.type];
  }
}

export class CDIValue {

  constructor(
    public id: string = null,
    public JobId: string = null,
    public CDIQuestionFieldId: string = null,
    public decimalValue: number = null,
    public stringValue: string = null,
    public guidValue: string = null
  ) { }
}