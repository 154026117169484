
import { MaterialManagementMappingModel } from 'libs/models/material-management/material-management-mapping.model';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit, ViewChild, ChangeDetectionStrategy, Injector, ViewChildren, OnDestroy } from '@angular/core';

import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

import { Job, PumpScheduleStageModel, FluidModel, PumpSchedule } from 'libs/models';
import {
  UnitType,
  FLUID_TYPE_SCHEDULE,
  SlurrySource,
  DeadVolumeFluidType
} from 'libs/constants';
import { PumpScheduleService, PumpScheduleLogic } from '../../services';
import { PDropdownDataPumpScheduleHelper } from '../../../shared/helpers';
import { ConfirmDialogService, FormControlContainer } from 'libs/ui';
import { convertObjToArr } from '../../../shared/helpers/object-to-array';
import { ActionState } from '../../../shared/constant';
import { PumpScheduleAdapter } from '../../../pump-schedule/adapters';
import { ApplicationStateService } from 'libs/shared/services';
import { PumpFluidDetailComponent } from '../pump-fluid-detail/pump-fluid-detail.component';
import { PumpEventComponent } from '../pump-event/pump-event.component';
import { Subscription } from 'rxjs';
import { EditJobAdapter } from '../../../edit-job/adapters';
import { AutoUnsubscribe } from 'libs/helpers/subscription-helper';
import { QueryList } from '@angular/core';

@AutoUnsubscribe(['_touched$', '_dirty$'])
@Component({
  selector: 'pump-stage',
  templateUrl: './pump-stage.component.html',
  styleUrls: ['./pump-stage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PumpStageComponent extends FormControlContainer implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() stageModel: PumpScheduleStageModel;
  @Input() index: number;
  @Input() permission: boolean;
  @Input() pumpScheduleForm: UntypedFormGroup;
  @Input() pumpScheduleModel: PumpSchedule;
  @Input() fluids: any = [];
  @Input() even: boolean;
  @Input() odd: boolean;
  @Input() cpState: number;
  @Input() jobState: string;
  @Input() isImportDataFromHDF = false;
  @Input() job: Job;
  @Input() sapMaterialMappings: MaterialManagementMappingModel[]; 

  @Input() 
  public set stageForm(value: UntypedFormGroup) {

    this.formControl = value;
  }
  public get stageForm(): UntypedFormGroup {

    return this.formControl as UntypedFormGroup;
  }

  @Output() insertBefore = new EventEmitter();
  @Output() insertAfter = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() onSelectedFluid = new EventEmitter();
  @Output() openStageCogsHelp = new EventEmitter();
  @ViewChild(PumpFluidDetailComponent) pumpFluidDetailComponent: PumpFluidDetailComponent;
  @ViewChildren(PumpEventComponent) pumpEventComponents: QueryList<PumpEventComponent>;

  fluidType: any = [];
  fluidTypeData: any = [];
  fluidTypeDataStageOne: any = [];
  selectedFluid: FluidModel;
  updateType = false;
  isExpanded = false;
  isExpandedType = true;
  defaultValue: number = 0;
  fluidIcon: string = '';
  readonly: boolean;
  isTopBottom: boolean = false;

  UnitType = UnitType;
  isMudType = false;
  subscription = new Subscription();

  constructor(
    protected inj: Injector,
    private pumpScheduleService: PumpScheduleService,
    private applicationStateService: ApplicationStateService,
    public confirmDialogService: ConfirmDialogService,
    protected editJobAdapter: EditJobAdapter,
    public pumpScheduleAdapter: PumpScheduleAdapter,
    public pumpScheduleLogic: PumpScheduleLogic
  ) {
    super(inj);
  }

  ngOnInit() {
    this.initMasterData();

    if (this.stageForm.controls.order.value === 0) {
      const defaultValue = 0;
      this.stageForm.controls.volume.setValue(defaultValue);
    }

    this.subscription.add(this.applicationStateService.pumpScheduleCreate$.subscribe(res => {
      if (res === ActionState.Created) {
        // Reset enable data for fluid type when create pump
        this.pumpScheduleAdapter.initFluidType();
        this.fluidIcon = '';
        this.applicationStateService.isCreate = true;

        this._cdRef.detectChanges();
      }
    }));

    this.subscription.add(this.applicationStateService.createAction$.subscribe(res => {
      if (res === ActionState.Edited) {
        this.applicationStateService.isCreate = false;
        this._cdRef.detectChanges();
      }
    }));

    this.subscription.add(
      this.pumpScheduleAdapter.stageChanges$.subscribe(_ => {
        this._cdRef.detectChanges();
      })
    );
  }

  ngAfterViewInit() {
    const currentStage = this.stageForm.get('number').value;
    if (currentStage > 0) {
      const scrollIntoView = document.getElementById(currentStage + (-1));
      scrollIntoView.scrollIntoView();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Check value change when load edit pump and collapse fluid type special
    const { stageForm, fluids } = changes;
    if (stageForm) {
      this.selectedFluid = this._findSelectedFluid(this.fluids, this.stageForm);
      this.onPumpScheduleFluidTypeChange();
      this.stageForm.controls.pumpScheduleFluidTypeId.valueChanges.subscribe(() => {
        this.onPumpScheduleFluidTypeChange();
      });
    }
    // if (fluids && fluids.currentValue && !this.isImportDataFromHDF) {
    if (fluids && fluids.currentValue) {
      // const isFromHDF = this.fluids.some(x => x.slurrySource === SlurrySource.HDFFluid);
      // // For HDF case when importing new data from HDF only
      // if (isFromHDF && this.stage.controls.slurryIdHDF.value && !this.stage.controls.slurry.value) {
      //   this.setFluidToStageHDFImport(this.fluids, this.stage);
      // }

      this.selectedFluid = this._findSelectedFluid(this.fluids, this.stageForm);
      this.stageForm.controls.slurry.patchValue(this.selectedFluid);
      // this._cdRef.detectChanges();
      // this.stage.controls.slurry.patchValue(this.selectedFluid);
      // if (this.job.id === '' || (this.job.id !== Guid.Empty && !this.stage.controls.id.value)) {
      //   this.selectedFluid = this.findSelectedFluidByStage(this.fluids, this.stage);
      // } else {
      //   this.selectedFluid = { ...this._findSelectedFluid(this.fluids, this.stage) };
      // }

    }
    this.readonly = !this.permission;
    // Show Planed or Used when edit pump schedule
    const slurry = this.stageForm.get('slurry').value;
    this.setFluidIcon(slurry ? slurry.primaryStatus : '');


    if (!!this.cpState && this.pumpFluidDetailComponent) {
      this.pumpFluidDetailComponent.cpState = this.cpState;
    }
  }


  checkMudType(isMudType) {
    this.isMudType = isMudType;    
  }

  onPumpScheduleFluidTypeChange() {
    // Load data correct with type expanded and collapse when edit
    this.editExpand();
    // Load data correct with disable fluid when edit
    this.loadDisableFluid();

    // Load data edit pump disable when TOP selected
    const res = this.stageForm.controls.pumpScheduleFluidTypeId.value;
    const selectedFluidType = this.pumpScheduleAdapter.fluidTypes.find(e => e.id === res);
    if (selectedFluidType) {
      if (selectedFluidType.name === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT) {
        this.checkTopFluidType(selectedFluidType);
      }
    }
  }

  _findSelectedFluid(fluids: FluidModel[], stage: UntypedFormGroup): FluidModel {
    let result = new FluidModel();
    if (fluids) {
      const isFromHDF = fluids.some(x => x.slurrySource === SlurrySource.HDFFluid);
      // For HDF case when importing new data from HDF only
      if (isFromHDF && stage.controls.slurryIdHDF.value && !this.stageForm.controls.slurry.value) {
        result = fluids.find(x => x.hdfSlurryIds && x.hdfSlurryIds.some(y => y === stage.controls.slurryIdHDF.value));
        // stage.controls.slurry.patchValue(result);
      } else {
        if (this.stageModel) {
          if (stage.controls.slurry.value) {
            //it doesn't work for duplicated job
            // if (stage.controls.slurry.value && stage.controls.slurry.value.id) {
            //   result = fluids.find(f => stage.controls.slurry.value && stage.controls.slurry.value.id === f.id);
            // } else {
              result = fluids.find(fluid => stage.controls.slurry.value &&
                stage.controls.slurry.value.requestId &&
                stage.controls.slurry.value.slurryNo &&
                stage.controls.slurry.value.requestId === fluid.requestId &&
                stage.controls.slurry.value.slurryNo === fluid.slurryNo);
              if (!result)
                result = stage.controls.slurry.value;
            //}
          }
        } else if (this.stageForm) {
          result = fluids.find(fluid => stage.controls.slurry.value && stage.controls.slurry.value.id && stage.controls.slurry.value.id === fluid.id);
          if (!result)
            result = stage.controls.slurry.value;
        } else {
          result = null;
        }

      }

      if (this.job.isClonedJob) {
        result = fluids.find(fluid => stage.controls.slurry.value &&
          stage.controls.slurry.value.requestId &&
          stage.controls.slurry.value.slurryNo &&
          stage.controls.slurry.value.requestId === fluid.requestId &&
          stage.controls.slurry.value.slurryNo === fluid.slurryNo);
        if (!result)
          result = stage.controls.slurry.value;
      }
    }

    return result ? { ...result } : new FluidModel();
  }

  findSelectedFluidByStage(fluids: FluidModel[], stage: UntypedFormGroup): FluidModel {
    let result = new FluidModel();
    if (this.stageForm) {
      if (stage.controls.slurry.value && stage.controls.slurry.value.id) {
        result = fluids.find(fluid => stage.controls.slurry.value && stage.controls.slurry.value.id === fluid.id);
      } else {
        result = this.stageForm.controls.slurry.value as FluidModel;
      }
    }
    return result;
  }

  loadDisableFluid() {
    const res = this.stageForm.controls.pumpScheduleFluidTypeId.value;

    const controlOptions = {
      emitEvent: false,
      onlySelf: true
    };

    if (res !== null) {
      const selectedFluidType = this.pumpScheduleAdapter.fluidTypes.find(e => e.id === res);
      if (selectedFluidType && selectedFluidType.name === FLUID_TYPE_SCHEDULE.BOTTOM_PLUG ||selectedFluidType && selectedFluidType.name === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT) {
        this.isTopBottom = true;
        this.stageForm.controls.slurry.reset();
        this.stageForm.controls.slurry.disable(controlOptions);
        this.fluidIcon = '';
      } else {
        this.isTopBottom = false;
        this.stageForm.controls.slurry.enable(controlOptions);
      }
    }
  }

  resetOrder(event) {
    const res = event.value;

    const controlOptions = {
      emitEvent: false,
      onlySelf: true
    };

    if (res !== null) {
      const selectedFluidType = this.pumpScheduleAdapter.fluidTypes.find(e => e.id === res);
      if (selectedFluidType.name === FLUID_TYPE_SCHEDULE.BOTTOM_PLUG || selectedFluidType.name === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT) {
        this.isTopBottom = true;
        this.stageForm.controls.avgRate.reset();
        this.stageForm.controls.volume.reset();
        this.stageForm.controls.topOfFluid.reset();
        this.stageForm.controls.slurry.reset();
        this.stageForm.controls.slurry.disable(controlOptions);

        // const eventsControl: FormArray = (this.stageForm.get('events') as FormArray);
        // while (eventsControl.length !== 0) {
        //   eventsControl.removeAt(0);
        // }

        this.isExpanded = false;
        this.isExpandedType = false;
      } else {
        this.isExpanded = true;
        this.isExpandedType = true;
        this.stageForm.controls.slurry.enable(controlOptions);
      }

      // Top Plug/Start Displacement  - no more this value once it is selected.
      if (selectedFluidType.name === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT) {
        this.checkTopFluidType(selectedFluidType);
      } else {
        this.handleTopFluidTypeChange();
      }
    } else {
      this.handleTopFluidTypeChange();
      this.isExpanded = true;
      this.stageForm.controls.slurry.enable(controlOptions);
    }
  }

  checkTopFluidType(selectedFluidType) {
    this.pumpScheduleAdapter.disableSelectedTopArray = this.pumpScheduleAdapter.getFluidTypeStage(false)
      .map(x => {
        const obj = Object.assign({}, x);
        if (obj.name === selectedFluidType.name) {
          obj.isEnableFirstStage = true;
        }
        return obj;
      });
    this.updateType = true;
    this.pumpScheduleService.updateType$.next(this.updateType);
  }

  checkExistedTopFluid(stages, topFluidTypeId) {
    let isExistTopFluid = false;
    for (let i = 0; i < +stages.length; i++) {
      const pumpScheduleFluidTypeId = (stages[i] as UntypedFormGroup).controls.pumpScheduleFluidTypeId;
      if (pumpScheduleFluidTypeId && pumpScheduleFluidTypeId.value === topFluidTypeId) {
        isExistTopFluid = true;
      }
    }

    return isExistTopFluid;
  }

  handleTopFluidTypeChange() {
    const topFluidType = this.pumpScheduleAdapter.fluidTypes.find(e => e.name === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT);
    const stages = ((this.pumpScheduleForm.get('stages') as UntypedFormGroup).controls);
    const isExistTopFluid = this.checkExistedTopFluid(stages, topFluidType.id);

    if (!isExistTopFluid) {
      this.pumpScheduleAdapter.enableSelectedTopArray = this.pumpScheduleAdapter.getFluidTypeStage(false);
      this.updateType = false;
      this.pumpScheduleService.updateType$.next(this.updateType);
    }
  }



  checkExistedShutdown(stages: UntypedFormGroup[], shutdownId: string) {
    stages.forEach((stage, index) => {
      const events = convertObjToArr(((stage.controls.events as UntypedFormGroup).controls)) as UntypedFormGroup[];
      if (index === 0 && events.length > 0) {
        (events[0] as UntypedFormGroup).controls.volume.setValue(this.defaultValue);
      }
      //TODO move this into events control
      this.shutdownEvents(events, shutdownId);
    });
  }

  shutdownEvents(events: UntypedFormGroup[], shutdownId: string) {
    const shutdownEvents = events.filter(event => event.controls.placementMethod.value === shutdownId);
    shutdownEvents.forEach(event => {
      event.controls.bulkCement.disable();
      event.controls.rate.disable();
      event.controls.volume.disable();
      event.controls.duration.enable();
    });
  }

  setDefaultFluidType() {
    const slurryName = this.stageForm.controls.slurry.value;
    const pumpScheduleFluidTypeId = this.stageForm.controls.pumpScheduleFluidTypeId.value;
    if (slurryName !== '') {
      this.setFluidIcon('');
      if (this.stageForm.get('order').value === 0 && !pumpScheduleFluidTypeId) {
        this.fluidType = this.fluidTypeDataStageOne.find(type => type.label === FLUID_TYPE_SCHEDULE.DRILLING_FLUID_MUD);
        this.stageForm.controls.pumpScheduleFluidTypeId.setValue(this.fluidType.value);
      } else {
        if (!pumpScheduleFluidTypeId) {
          this.fluidType = this.fluidTypeData.find(type => type.label === FLUID_TYPE_SCHEDULE.MUD);
          this.stageForm.controls.pumpScheduleFluidTypeId.setValue(this.fluidType.value);
          this.updateType = false;
        }
      }
      // const item = this.fluids.find(f => f.displayName === this.stage.controls.slurry.value);
      // if (item === undefined) {
      //   this.selectedFluid = null;
      //   this.pumpFluidDetailComponent.ngOnInit();
      // }
    }
    this.cleanFluidData();
  }

  initMasterData() {
    this.subscription.add(this.editJobAdapter.fluids$.subscribe(fluids => {
      this.fluids = fluids;

      if (this.fluids.length > 0) {
        this.selectedFluid = this.findSelectedFluidByStage(this.fluids, this.stageForm);
        if (this.selectedFluid && this.selectedFluid.id) {
          this.stageForm.controls.slurry.patchValue(this.selectedFluid);
          this._cdRef.markForCheck();
        }
      }
      // this.selectedFluid$.next(this._findSelectedFluid(this.fluids, this.stage));
    }));
    // this.masterDataService.getPumpScheduleFluidType().subscribe(res => {
    //   this.pumpScheduleService.fluidTypePumpScheduleData = res;
    // this.resetTypes();
    // When add a stage selected Top then Top will be disabled.
    if (this.pumpScheduleAdapter.disableSelectedTopArray.length > 0) {
      this.fluidTypeData = PDropdownDataPumpScheduleHelper('description', 'id', 'isEnableFirstStage', this.pumpScheduleAdapter.disableSelectedTopArray);
    }
    // });

    this.subscription.add(this.editJobAdapter.getAvailableSapMappings$(this.job.group).subscribe(sm => {
      this.sapMaterialMappings = sm as MaterialManagementMappingModel[];
      this._cdRef.detectChanges();
    }));
    
  }

  toggle() {
    const res = this.stageForm.controls.pumpScheduleFluidTypeId.value;
    const selectedFluidType = this.pumpScheduleAdapter.fluidTypes.find(e => e.id === res);
    if (selectedFluidType) {
      if (selectedFluidType.name === FLUID_TYPE_SCHEDULE.BOTTOM_PLUG || selectedFluidType.name === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT) {

      } else {
        this.isExpanded = !this.isExpanded;
      }
    } else {
      this.isExpanded = !this.isExpanded;
    }
  }

  insertStageBefore() {
    this.insertBefore.next();
  }

  insertStageAfter() {
    this.insertAfter.next();
  }

  deleteStage() {
    this.confirmDialogService.show({
      message: `Are you sure to remove this Stage?`,
      header: 'Confirmation',
      accept: () => {
        this.delete.next();
      },
      reject: () => {

      },
      acceptLabel: 'Yes',
      rejectLabel: 'No'
    });
  }

  _onSelectedFluid(slurry: any) {
    this.onSelectedFluid.emit(slurry);
    this.setFluidIcon(slurry.primaryStatus);
    this.selectedFluid = slurry && slurry.id ? this.fluids.find(f => f.id === slurry.id) : slurry;
    this.cleanFluidData();
  }

  onClearFluid() {
    this.selectedFluid = new FluidModel();
    this.stageForm.controls.bulkCement.setValue(null);
    this.applicationStateService.cogsRecalculationNeeded$.next(true);
  }

  cleanFluidData() {
    this.stageForm.controls.deadVolumeFluidType.setValue(DeadVolumeFluidType.CementSlurry);
  }

  setFluidIcon(status: string) {
    if (status === 'Planned') {
      this.fluidIcon = 'P';
    } else {
      if (status === 'Used') {
        this.fluidIcon = 'U';
      } else {
        this.fluidIcon = '';
      }
    }
  }

  onChangePlacementMethod($event: string) {
    const { value: { stages } } = this.pumpScheduleForm;
    const currentStage = stages.find((x: PumpScheduleStageModel) => x.id === $event);
    const { order: currentIndex } = currentStage;

    const stageControlList = (this.pumpScheduleForm.controls.stages as UntypedFormArray).controls;

    const eventControlList = stageControlList.map((x: UntypedFormGroup) => {
      if (x.value.order > currentIndex) {
        return (x.controls.events as UntypedFormArray).controls;
      }
    }).filter(x => typeof (x) !== 'undefined')
      .reduce((a, b) => a.concat(b));

      //TODO move this into events control
    eventControlList.forEach((event: UntypedFormGroup) => {
      event.controls.topOfFluid.setValue(null);
      event.controls.length.setValue('');
    });
  }


  expand() {
    const res = this.stageForm.controls.pumpScheduleFluidTypeId.value;
    const selectedFluidType = this.pumpScheduleAdapter.fluidTypes?.find(e => e.id === res);
    if (selectedFluidType) {
      if (selectedFluidType.name === FLUID_TYPE_SCHEDULE.BOTTOM_PLUG || selectedFluidType.name === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT) {
        this.isExpanded = false;
      } else {
        this.isExpanded = !this.isExpanded;
      }
    } else {
      this.isExpanded = !this.isExpanded;
    }
  }

  editExpand() {
    const res = this.stageForm.controls.pumpScheduleFluidTypeId.value;
    const selectedFluidType = this.pumpScheduleAdapter.fluidTypes?.find(e => e.id === res);
    if (selectedFluidType) {
      if (selectedFluidType.name === FLUID_TYPE_SCHEDULE.BOTTOM_PLUG || selectedFluidType.name === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT) {
        this.isExpanded = false;
      } else {
        this.isExpanded = false;
      }
    } else {
      this.isExpanded = false;
    }
  }

  markAsTouched() {
    super.markAsTouched();
    if (this.pumpFluidDetailComponent)
      this.pumpFluidDetailComponent.markAsTouched();
    if (this.pumpEventComponents)
      this.pumpEventComponents.forEach(x => x.markAsTouched());
  }

  isInvalid(): boolean {
    return (this.pumpEventComponents && this.pumpEventComponents.some(event => event.isInvalid())) ||
      (this.pumpFluidDetailComponent && this.pumpFluidDetailComponent.isInvalid());
  }
}
