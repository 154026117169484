<div class="form-subtitle-section">
  {{header}}<a class="view-less" (click)="isExpanded = !isExpanded">{{ isExpanded ? 'View All': 'View Less'}}</a>
</div>
<p-table [hidden]="isExpanded" [value]="fluidMaterials" class="border-table">
  <ng-template pTemplate="header">
    <tr class="pump-event-header">
      <th class="header-2" width="240px">iFacts Material Name</th>
      <th class="header-2">Concentration</th>
      <!-- <th class="header-2">Mixing Procedure</th> -->
      <th class="header-2" width="450px">SAP Material</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-fluidMaterialDetail let-index="rowIndex">
    <tr [formGroup]="fluidMaterialDetail" class="fluid-event-body">
      <td [lockableElement]="'supply_iFactsMaterialName'">
        <typeahead-datatable formControlName="materialName" styleClass="typeahead-material" [columns]="materialColumns"
          [options]="materalOptions[index]" (onLazyLoad)="onLazyLoadMaterial($event, index)"
          (onClear)="_onClearMaterial(fluidMaterialDetail, $event, index)" showOptionsOnFocus="true"
          (onChange)="onChangeMaterial(fluidMaterialDetail, $event, index)" [lockableElement]="'job_completed'">
        </typeahead-datatable>
        <error-display formControlName="materialName" [errorMsg]="errorMessages.materialName"></error-display>
      </td>
      <td class="vertical-align-middle" *ngIf="!isSupplemental">
        <div *ngIf="form?.controls?.materialType?.value !== 'Mix Water'; else mixWater">
          <span>{{fluidMaterialDetail.controls.concentration?.value | number:'.3'}}
            {{fluidMaterialDetail?.controls?.concentrationUnit?.value}}</span>
        </div>
        <ng-template #mixWater>
          <span formControlName="concentration" dUnitValue={{UnitType.LiquidsSolidsRatio}} dUnitWithName="true"></span>
        </ng-template>
      </td>
      <td class="vertical-align-middle" *ngIf="isSupplemental">
        <div *ngIf="form?.controls?.materialType?.value !== 'Mix Water'; else mixWater"
          class="flx-row no-gutters concen-input-group">
          <div class="flx-col-5" style="padding-right: 2px !important; padding-left: 1px !important" [lockableElement]="'supply_Concentration'">
            <input-unit class="" formControlName="concentration" maxlength="13" required (onBlur)="updateFluidSupple()"></input-unit>
            <error-display formControlName="concentration" [errorMsg]="errorMessages.concentration"></error-display>
          </div>
          <div class="flx-col-7" [lockableElement]="'supply_ConcentrationUnit'">
            <p-dropdown [options]="filterConcenUnitSupple" (onChange)="updateUnitConcenSupple($event, index)"
              formControlName="fluidUnitMeasureId"  placeholder="&nbsp;"
              showClear="true" required></p-dropdown>
            <error-display formControlName="fluidUnitMeasureId" [errorMsg]="errorMessages.concentrationUnit">
            </error-display>
          </div>
        </div>
        <ng-template #mixWater>
          <span formControlName="concentration" dUnitValue={{UnitType.LiquidsSolidsRatio}} dUnitWithName="true"></span>
        </ng-template>
      </td>
      <!-- <td class="vertical-align-middle">
          <span>{{fluidMaterialDetail.controls.mixingProcedureValue?.value}}</span>
        </td> -->
      <td class="vertical-align-middle" [lockableElement]="'Fluid_sap_material'"
        [ngStyle]="isSupplemental ? {'position': 'relative'} : ''">
        <button class="btn-remove-supp-event" [lockableElement]="'Fluid_remove'" *ngIf="isSupplemental"
          (click)="removeSupplemental(index)">
          <div class="btn-remove-fluid-event-icon">
            <i class="fa fa-times"></i>
          </div>
        </button>
        <sap-material class="exception-disable-item" enableDropdown="true" [slurrySource]="slurrySourceId"
          [generalMaterialId]="fluidMaterialDetail?.controls?.materialId"
          [sapMaterialNumber]="fluidMaterialDetail?.controls?.sapMaterialNumber"
          [sapMaterialName]="fluidMaterialDetail?.controls?.sapMaterialName"
          [notExistedSAPMaterial]="fluidMaterialDetail?.controls?.notExistedSAPMaterial"
          (onChangeSAP)="onChangeSAP(fluidMaterialDetail, $event, index)"
          (onClearSAP)="onClearSAP(fluidMaterialDetail, $event, index)" [lockableElement]="'job_completed'">
        </sap-material>
        <error-display formControlName="sapMaterialNumber" [errorMsg]="errorMessages.sapMaterialNumber"></error-display>
      </td>
    </tr>

  </ng-template>

</p-table>
<div *ngIf="!isExpanded && isSupplemental && fluidMaterials.length === 0">
  <p class="no-content">There currently are no Supplemental Materials included for this fluid.</p>
</div>
<div [lockableElement]="'pumpSchedule_addNewEvent'" *ngIf="isSupplemental && !isMudSlurryTypeOfSupMat && canEdit">
  <button class="action-add" (click)="addNewSup()">
    <i class="fa fa-plus-circle"></i><span>Add New Supplemental Material</span>
  </button>
</div>