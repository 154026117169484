import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BhctRiskLevelService {

  private bhctConfirmChannel = new Subject<boolean>();
  private _bhctConfirmChannel$ = this.bhctConfirmChannel.asObservable();

  private cdiRiskChannel = new Subject<number>();
  private _cdiRiskChannel$ = this.cdiRiskChannel.asObservable();

  get bhctConfirmChannel$(): Observable<boolean> {
    return this._bhctConfirmChannel$;
  }
  get cdiRiskChannel$(): Observable<number> {
    return this._cdiRiskChannel$;
  }

  constructor() { }

  bhctConfirmUpdate(isConfirmed: boolean) {
    this.bhctConfirmChannel.next(isConfirmed);
  }

  sendRiskValue(risk: number) {
    this.cdiRiskChannel.next(risk);
  }
}
