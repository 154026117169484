import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, AfterViewInit, AfterContentInit } from '@angular/core';
import { JobsService, ApplicationStateService } from 'libs/shared/services';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Job } from 'libs/models';
import { environment } from 'libs/environment';

@Component({
  selector: 'modal-link-origin-job',
  templateUrl: './modal-link-origin-job.component.html',
  styleUrls: ['./modal-link-origin-job.component.scss']
})
export class ModalLinkOriginJobComponent implements OnInit {

  @Input() visible: boolean;
  @Input() job: Job;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();

  jobCode: string = '';
  isConfirm: boolean = false;
  jobOriginInfo: any = {};
  loadingSubscription: Subscription;

  constructor(
    private jobService: JobsService,
    private messageService: MessageService,
    public appStateService: ApplicationStateService,
    protected cd: ChangeDetectorRef
  ) {
    this.visible = false;
  }

  ngOnInit() {
  }

  ngDestroy() {
    this.loadingSubscription.unsubscribe();
  }

  onShow() {
    this.isConfirm = false;
    this.jobCode = '';
    this.jobOriginInfo = {};
    this.jobOriginInfo.errorMessage = '';
  }

  onClose() {
    this.visibleChange.emit(this.visible);
  }

  onCancel() {
    this.visible = false;
  }

  getInfoOriginJob() {
    if (this.appStateService.notifyIFactDown$.value) {
      return;
    }


    const valueJobCode = this.jobCode.trim().toUpperCase();

    if (!valueJobCode) {
      this.jobOriginInfo.errorMessage = 'Please enter Job Number.';
      this.isConfirm = false;
      return;
    }

    if (valueJobCode === this.job.jobCode) {
      this.jobOriginInfo.errorMessage = 'A job cannot be linked to itself. <br>Please enter another Job Number.';
      this.isConfirm = false;
      return;
    }

    if (this.job.originalJobCode && valueJobCode === this.job.originalJobCode) {
      this.jobOriginInfo.errorMessage = 'Job <b>' + this.jobCode.trim() + '</b> is being linked to current job. <br>Please enter another Job Number.';
      this.isConfirm = false;
      return;
    }

    this.loadingSubscription = this.jobService.getInfoOriginJob(this.job.group, valueJobCode)
      .subscribe(data => {
        if (!data.hasError) {
          this.jobOriginInfo = data;
          this.isConfirm = true;
        } else {
          this.jobOriginInfo.errorMessage = data.errorMessage;
          this.isConfirm = false;
        }
        this.cd.markForCheck();
      });
  }

  btnConfirm_click() {
    this.loadingSubscription = this.jobService.updateJobLink(
      this.job.id, this.jobOriginInfo.jobId, this.jobOriginInfo.jobCode
    ).subscribe(result => {
      if (result) {
        this.onConfirm.emit(this.jobOriginInfo);
        this.onCancel();
      } else {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: `Has error occurred when processing!` });
      }
    });
  }

}
