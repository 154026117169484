import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) return null;

    // get total seconds between the times
    let delta = Number(value) / 1000;
    let sigh = Math.sign(delta) < 0 ? '-' : '';
    
    // calculate (and subtract) whole days
    let days = Math.floor(Math.abs(delta) / 86400);
    delta -= Math.sign(delta) * days * 86400;

    // calculate (and subtract) whole hours
    let hours = Math.floor(Math.abs(delta) / 3600) % 24;
    delta -= Math.sign(delta) * hours * 3600;

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(Math.abs(delta) / 60) % 60;
    delta -= Math.sign(delta) * minutes * 60;

    //let seconds = delta % 60;
    return `${sigh}${days} days ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

}
