<div [formGroup]="form">
  <div class="ui-table">
    <table>
      <thead>
        <tr class="pump-fluid-detail-header">
          <th>Blend Name</th>
          <th>Cement Name</th>
          <th>Water</th>
          <th>Sack Weight</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="vertical-align-middle">
            <span>{{ blendName$ | async }}</span>
          </td>
          <td class="vertical-align-middle">
            <span>{{ cementName$ | async }}</span>
          </td>
          <td class="vertical-align-middle">
            <span>{{ water$ | async }}</span>
          </td>
          <td class="vertical-align-middle">
            <span>{{ sackWeight$ | async | pUnitFormat }} lb/sack</span>
          </td>
        </tr>
      </tbody>
      </table>

    <table>
      <thead>
        <tr class="pump-fluid-detail-header">
          <th>Slurry Density</th>
          <th>Mix Water</th>
          <th>Total Mix Fluid</th>
          <th>Yield</th>
          <th *ngIf="isFoam$ | async">Foam Density</th>
          <th *ngIf="isFoam$ | async">Foam Quality</th>
          <th *ngIf="isCP4View">Thickening Time</th>
          <th *ngIf="isCP4View">Job Placement Time</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="vertical-align-middle">
            <span formControlName="density" dUnitValue={{UnitType.Density}} dUnitWithName="true"></span>
          </td>
          <td class="vertical-align-middle">
            <span formControlName="mixWater"
              dUnitValue={{UnitType.LiquidsSolidsRatio}}
              dUnitWithName="true"
              [dCustomUnit]="mixWaterUnit$ | async"
              [yieldValue]="yield$ | async"
              [sackWeight]="sackWeight$ | async">
            </span>
          </td>
          <td class="vertical-align-middle">
            <span formControlName="mixFluid"
              dUnitValue={{UnitType.LiquidsSolidsRatio}}
              dUnitWithName="true"
              [sackWeight]="sackWeight$ | async">
            </span>
          </td>
          <td class="vertical-align-middle">
            <span formControlName="yield"
              dUnitValue={{UnitType.Yield}}
              dUnitWithName="true"
              [sackWeight]="sackWeight$ | async">
            </span>
          </td>
          <td *ngIf="isFoam$ | async" class="vertical-align-middle">
            <span formControlName="foamDensity" dUnitValue={{UnitType.Density}} dUnitWithName="true"></span>
          </td>
          <td *ngIf="isFoam$ | async" class="vertical-align-middle">
            <span>{{ foamQuality$ | async | pUnitFormat }} %</span>
          </td>
          <td *ngIf="isVisible$('thickeninngTime') | async"><span>{{ thickeningTime$ | async }}</span></td>
          <td *ngIf="isVisible$('placementTime') | async"><span>{{ placementTime$ | async }}</span></td>
        </tr>
      </tbody>
      </table>

    <table>
      <thead>
        <tr class="pump-fluid-detail-header">
          <th>Planned Qty</th>
          <th>
            <span class="float-left">Dead Qty</span>
            <div class="more-info-icon float-left">
              <div class="more-info-icon-target"></div>
              <i class="fa fa-info-circle icon-info" (click)="deadVolumeHelp.toggle($event)"></i>
              <p-overlayPanel #deadVolumeHelp [dismissable]="true" [showCloseIcon]="true" styleClass="cpoint-overlay-panel">
                This is Dead Volume/Pit Loss and represents the amount of fluid that is left behind when the fluid is
                pumped.
              </p-overlayPanel>
            </div>
          </th>
          <th width="160px">Dead Volume Fluid Type</th>
          <th *ngIf="isVisible$('loadoutVolume') | async">{{ (isOffshoreJob$ | async) ? 'Required on Rig' : 'Load Out Qty'}}</th>
          <th>Bulk Cement</th>
          <th>Stage Water Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="vertical-align-middle">
            <div class="unit-value" [apiValue]="plannedVolume$ | async" [unitType]="UnitType.LargeVolume"></div>
          </td>
          <td class="vertical-align-middle">
            <div *ngIf="isDeadVolumeEditable"
              class="flx-ctn-row"
              [class.exception-disable-item]="enablingCPStates$ | async"
              [lockableElement]="'fluidDetailInfo_deadVolume'">
              <input-unit formControlName="deadVolume"
                class="flx-itm-grow m-r-10"
                unit={{UnitType.LargeVolume}}
                [lockableElement]="'fluidDetailInfo_deadVolume'"
                [class.exception-disable-item]="!isJobEditable"
                pKeyFilter="pnum">
              </input-unit>
              <span class="flx-itm-center" [unitType]="UnitType.LargeVolume"></span>
            </div>
            <span *ngIf="!isDeadVolumeEditable" formControlName="deadVolume" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
          </td>
          <td  class="vertical-align-middle">
            <div *ngIf="isDeadVolumeEditable" class="flx-ctn-row">
              <p-dropdown formControlName="deadVolumeFluidType"
                class="flx-itm-grow m-r-10"
                [class.exception-disable-item]="enablingCPStates$ | async"
                [options]="deadVolumeDropdownItems$ | async"
                [lockableElement]="'fluidDetailInfo_deadVolume'"
                [lockableElementDisable]="isDeadVolumeTypeDisabled$ | async">
                <ng-template let-type pTemplate="selectedItem">
                  <div>{{ type.label }}</div>
                </ng-template>
                <ng-template let-type pTemplate="item">
                  <div *ngIf="type.disabled" tooltipZIndex="10000" pTooltip="No mixing procedure information is available" tooltipPosition="right">{{type.label}}</div>
                  <div *ngIf="!type.disabled">{{ type.label }}</div>
                </ng-template>
              </p-dropdown>
            </div>
            <span *ngIf="!isDeadVolumeEditable">{{ deadVolumeFluidTypeName$ | async }}</span>
          </td>
          <td *ngIf="isVisible$('loadoutVolume') | async" class="vertical-align-middle">
            <div *ngIf="!isCP4View" class="flx-ctn-row" [class.exception-disable-item]="enablingCPStates$ | async" [lockableElement]="'fluidDetailInfo_loadoutVolume'">
              <input-unit formControlName="loadoutVolume"
                class="flx-itm-grow m-r-10"
                unit={{UnitType.LargeVolume}}
                [lockableElement]="'fluidDetailInfo_loadoutVolume'"
                pKeyFilter="pnum">
              </input-unit>
              <span class="flx-itm-center" [unitType]="UnitType.LargeVolume"></span>
            </div>
            <span *ngIf="isCP4View" formControlName="loadoutVolume" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
          </td>
          <td class="vertical-align-middle">
            <div *ngIf="isBulkCementEditable" class="flx-ctn-row" [class.exception-disable-item]="enablingCPStates$ | async">
              <input-unit class="flx-itm-grow m-r-10"
                formControlName="bulkCement"
                unit={{UnitType.BaseMaterialWeight}}
                [disabled]="bulkCementDisabled$ | async"
                [sackWeight]="sackWeight$ | async"
                [lockableElement]="'fluidDetailInfo_bulkCement'"
                pKeyFilter="pnum">
              </input-unit>
              <span class="flx-itm-center" [unitType]="UnitType.BaseMaterialWeight"></span>
              <ng-container *ngIf="bulkCementDisabled$ | async">
                <span class="fa fa-question-circle icon-help pl-1" (click)="noSackWeightWarning.toggle($event)"></span>
                <p-overlayPanel #noSackWeightWarning
                  [dismissable]="true"
                  [showCloseIcon]="true"
                  styleClass="cpoint-overlay-panel"
                  (mouseover)="noSackWeightWarning.togggle($event)">
                  WARNING: No Sack Weight available. Conversion to other units of measure not possible.
                </p-overlayPanel>
              </ng-container>
            </div>
            <div *ngIf="!isBulkCementEditable">
              <span
                formControlName="bulkCement"
                dUnitValue={{UnitType.BaseMaterialWeight}}
                dUnitWithName="true"
                [sackWeight]="sackWeight$ | async"></span>
            </div>
          </td>
          <td class="vertical-align-middle">
            <span formControlName="stageWaterTotal" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <stage-fluid-material *ngIf="hasBlendMaterials$ | async"
    class="m-l-30 m-t-30 pump-fluid-material-blends"
    [stageState]="stageState"
    [fluidState]="fluidState"
    [header]="getHeaderMaterialName(MATERIAL_TYPE.BLEND, (blendMaterialsCount$ | async))"
    [showMix]="false"
    [materials]="blendMaterials$ | async"
    [fluidType]="MATERIAL_TYPE.BLEND"
    [refreshForm]="refreshForm"
    [hasScope3access]="hasScope3access">
  </stage-fluid-material>

  <stage-fluid-material
    class="m-t-30 pump-fluid-material-additives"
    [stageState]="stageState"
    [fluidState]="fluidState"
    [header]="getHeaderMaterialName(MATERIAL_TYPE.ADDITIVE, (additivesCount$ | async))"
    [showMix]="true"
    [materials]="additives$ | async"
    [fluidType]="MATERIAL_TYPE.ADDITIVE"
    [refreshForm]="refreshForm"
    [hasScope3access]="hasScope3access">
  </stage-fluid-material>

  <stage-fluid-material
    class="m-l-30 m-t-30 pump-fluid-material-blends"
    [stageState]="stageState"
    [fluidState]="fluidState"
    [header]="getHeaderMaterialName(MATERIAL_TYPE.SUPPLEMENTAL, (supplementalsCount$ | async))"
    [showMix]="true"
    [materials]="supplementals$ | async"
    [fluidType]="MATERIAL_TYPE.SUPPLEMENTAL"
    [refreshForm]="refreshForm"
    [hasScope3access]="hasScope3access">
  </stage-fluid-material>
</div>
