import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, Injector, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ErrorMessageModel, FormControlContainer } from 'libs/ui';
import {
  ERROR_TYPE,
  ERROR_MESSAGE,
  UnitType,
  DefaultTimeString,
  SpacerMixMethods,
  SpacerMixMethod
} from 'libs/constants';
import { CustomValidators } from '../../../shared/validators';
import { ActionState, ControlPointState } from '../../../shared/constant';
import { ApplicationStateService, UserSettingService, RoleService } from 'libs/shared/services';
import { ControlPointType } from '../../../shared/constant';
import { PumpScheduleAdapter } from '../../adapters';
import { timeConvert, convertStringToTime } from 'apps/vida/src/modules/shared/helpers';
import {Job} from 'libs/models';
import {Subscription, Subject, Observable} from 'rxjs';
import { AutoUnsubscribe } from 'libs/helpers/subscription-helper';
import { takeUntil, delay, filter } from 'rxjs/operators';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'pump-schedule-shoetrack',
  templateUrl: './pump-schedule-shoetrack.component.html',
  styleUrls: ['./pump-schedule-shoetrack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PumpScheduleShoeTrackComponent extends FormControlContainer implements OnInit, OnChanges, OnDestroy {

  private readonly MaxThickeningTime = 5999;

  public readonly UnitType = UnitType;

  public readonly ControlPointState = ControlPointState;

  public readonly spacerMixMethods = SpacerMixMethods;

  @Input()
  public pumpScheduleForm: UntypedFormGroup;

  @Input()
  public jobPlacementTime: any = [];

  @Input()
  public cpState: number = 0;

  @Input()
  public jobState: string = '';

  @Input()
  public job: Job;

  @Input()
  public isManuallySpacerMixMethod: boolean;

  @Input()
  public controlPointNumber: ControlPointType | 0 = 0;


  @Output() openJobCogsHelp = new EventEmitter();

  public cp4State: string;

  public cp2State: string;

  public jptFormGroup: UntypedFormGroup;

  public isJobEdit: Observable<any>;

  get jptFormArray(): UntypedFormArray {

    return this.jptFormGroup.controls.formArr as UntypedFormArray;
  }

  private destroy$ = new Subject();

  get showCogs$() {
    return this.userSettingsService.showCogs$;
  }

  get isJobReviewer$() {
    return this.roleService.isJobReviewer$;
  }

  isJobTypeLiner: boolean = false;

  constructor(
    protected inj: Injector,
    private formBuilder: UntypedFormBuilder,
    private applicationStateService: ApplicationStateService,
    private roleService: RoleService,
    private userSettingsService: UserSettingService,
    protected pumpScheduleAdapter: PumpScheduleAdapter,
    private cd: ChangeDetectorRef
  ) {
    super(inj);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { jobPlacementTime } = changes;

    this.pumpScheduleForm.controls.spacerMixMethod.valueChanges.subscribe(value =>
      this.applicationStateService.spacerMixMethodChange$.next(value)
    );
    this.isJobEdit = this.applicationStateService.jobEditOpened$;
    if (!jobPlacementTime || !jobPlacementTime.currentValue) return;

    if (this.job && this.job.controlPoints && this.job.controlPoints.length) {
      this.cp4State = this.job.controlPoints[3].controlPointState.name;
      this.cp2State = this.job.controlPoints[1].controlPointState.name;
    }

    if (!this.jptFormGroup) {
      this.formControl = this.jptFormGroup = this.formBuilder.group({
        formArr: this.formBuilder.array([])
      });
    }

    const mappingJpt = this.jobPlacementTime.map(res => {

      const scheduledShutdown = convertStringToTime(this.pumpScheduleForm.controls.scheduledShutdown.value);
      const targetSafetyFactor = convertStringToTime(this.pumpScheduleForm.controls.targetSafetyFactor.value);
      const thickeningTimeJpt = convertStringToTime(res.thickeningTimeJpt);

      return {
        id: res.id,
        stageNumber: res.stageNumber,
        jpt: timeConvert(res.placementTime),
        slurryType: res.slurryType,
        thickeningTime: res.thickeningTimeJpt,
        minThickeningTime: this.calMinTT(res.placementTime, scheduledShutdown, targetSafetyFactor),
        actualSafety: this.calActualSafety(thickeningTimeJpt, res.placementTime),
      };
    });

    this.initShoeTrackStorage(mappingJpt);

    if (this.jptFormGroup) {
      const arr = this.jptFormGroup.controls.formArr as UntypedFormArray;
      const jptForms = this.formBuilder.array(mappingJpt.map(() => this.createJobPlacementTimeGroup()));
      jptForms.patchValue(mappingJpt);
      while (arr.length) arr.removeAt(0);
      jptForms.controls.forEach(x => arr.push(x));

      if (!this.job.canEdit) {
        this.jptFormGroup.disable();
      }
    }

    if (this.job.controlPoints && this.job.controlPoints[1] && (this.job.controlPoints[1].eControlPointState === ControlPointState.Completed
      || this.job.controlPoints[1].eControlPointState === ControlPointState.Prepared)) {
      this.pumpScheduleForm.controls.shoeTrackVolume.disable();
      this.pumpScheduleForm.controls.shoeTrackLength.disable();
    }

    if (this.job.controlPoints && this.job.controlPoints[1] && this.job.controlPoints[1].controlPointState.name === 'Completed') {
      document.querySelectorAll('p-inputMask > input').forEach((el) => el.setAttribute('tabindex', '-1'));
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnInit(): void {
    this.applicationStateService.pumpScheduleCreate$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
      if (res === ActionState.Created) {
        // Reset form when create
        this.applicationStateService.isCreate = true;
      }
      });

    this.applicationStateService.isLandChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(isLanded => {
      const isManuallySpacerMixMethod: boolean = this.pumpScheduleForm.controls.isManuallySpacerMixMethod.value;

      if (!isManuallySpacerMixMethod) {
        this.pumpScheduleForm.controls.spacerMixMethod.setValue(isLanded ? SpacerMixMethod.MixOnTheFly : SpacerMixMethod.BatchMix);
      }
    });

    this.applicationStateService.cogsRecalculationNeeded$
    .pipe(takeUntil(this.destroy$), filter(x => !x))
    .subscribe(() => {
      this.cd.markForCheck();
    });

    this.isJobTypeLiner = this.job.isLiner;
    this.applicationStateService.jobTypeChanged.subscribe((value: any) => {
      this.isJobTypeLiner = value.context.isLiner;
      this.cd.markForCheck();
    });
    this.cd.markForCheck();
  }

  public getJobCOGS() {
    return this.pumpScheduleForm.controls.totalCOGS.value !== null ? this.pumpScheduleForm.controls.totalCOGS.value.toFixed(2) : null;
  }

  public checkDisableItem() {
    return (this.jobState !== 'Completed' &&
            this.cp4State !== 'Completed' &&
            (this.cpState === ControlPointState.Approved || this.cpState === ControlPointState.Submitted) &&
            (this.cp2State && this.cp2State !== 'Completed')) ? 'exception-disable-item' : '';
  }

  get isCP1(): boolean {
    return this.controlPointNumber && this.controlPointNumber.toString() === ControlPointType.ControlPoint1.toString();
  }

  public changeThickeningTime(itemArrJptForm: UntypedFormGroup) {
    const itemArrJpt = itemArrJptForm.value;
    const { thickeningTime } = itemArrJpt;
    if (thickeningTime && (thickeningTime === DefaultTimeString.FULL_CHAR || !thickeningTime.includes(DefaultTimeString.PARTIAL_CHAR))) {
      itemArrJptForm.controls.thickeningTime.patchValue(thickeningTime === DefaultTimeString.FULL_CHAR ? null : thickeningTime);
      // cmt for not reflect value thickening time
      this.updateActualSafety(itemArrJpt);
      this.updateShoeTrackStorage(itemArrJpt);
      this.updateTestedTT();
    }

    this.cd.markForCheck();
  }

  public updateMinTTAndActualSafety() {
    this.jptFormArray.controls.forEach((jptForm: UntypedFormGroup) => {

      const jpt = convertStringToTime(jptForm.controls.jpt.value);
      const testedTT = convertStringToTime(jptForm.controls.thickeningTime.value);
      const scheduledShutdown = convertStringToTime(this.pumpScheduleForm.controls.scheduledShutdown.value);
      const targetSafetyFactor = convertStringToTime(this.pumpScheduleForm.controls.targetSafetyFactor.value);
      const minThickeningTime = this.calMinTT(jpt, scheduledShutdown, targetSafetyFactor);
      const actualSafety = this.calActualSafety(testedTT, jpt);

      jptForm.controls.minThickeningTime.setValue(minThickeningTime);
      jptForm.controls.actualSafety.setValue(actualSafety);

      this.updateJPTBackToStage(jptForm.value.id, jptForm.value);
    });

    this.cd.markForCheck();
  }

  public shouldDisplayTTError(jptForm: UntypedFormGroup) {
    return jptForm.controls.thickeningTime.errors && jptForm.controls.thickeningTime.errors.messageTime != null;
  }

  public shouldDisplayTTWarning(jptForm: UntypedFormGroup) {

    const jpt = convertStringToTime(jptForm.controls.jpt.value);
    const minThickeningTimeVal = convertStringToTime(jptForm.controls.minThickeningTime.value);
    const thickeningTime = convertStringToTime(jptForm.controls.thickeningTime.value);

    return thickeningTime < minThickeningTimeVal && thickeningTime > jpt;
  }

  public onSelectedOverlayPanel(event, overlaypanelCurrent: OverlayPanel, overlaypanelDisplayed: OverlayPanel) {
    overlaypanelDisplayed.hide();
    overlaypanelCurrent.toggle(event);

    this.cd.markForCheck();
  }

  public onSpacerMixMethodChanged() {
    if (this.isManuallySpacerMixMethod) {
      return; // User manually updated spacer mix method before, we won't care anymore.
    }

    this.pumpScheduleForm.controls.isManuallySpacerMixMethod.setValue(true, { emitEvent: false });
  }

  public validateThickeningTime(itemArrJpt) {
    this.applicationStateService.validateCompletedPumpSchedule$.next(itemArrJpt);
  }

  private createJobPlacementTimeGroup() {
    return this.formBuilder.group({
      id: null,
      stageNumber: 0,
      jpt: 0,
      slurryType: '',
      thickeningTime: new UntypedFormControl({
        value: 0,
        disabled: false
      }, [CustomValidators.checkValidThickeningTimePump]),
      minThickeningTime: '',
      actualSafety: ''
    });
  }

  private findStageFormGroup(id: string) {
    const stages = this.pumpScheduleForm.controls.stages as UntypedFormArray;
    const stage = stages.controls.find((x: UntypedFormGroup) => x.controls.id.value === id) as UntypedFormGroup;
    return stage;
  }

  private initShoeTrackStorage(shoeTrack) {
    const shoeTrackStorage = shoeTrack.reduce((shoeMap, shoe) => {
      const id = shoe.id;
      shoe.thickeningTimeOld = shoe.thickeningTime ? shoe.thickeningTime : null;
      shoeMap[id] = shoe;
      return shoeMap;
    }, {});
    window.sessionStorage.setItem('shoe-track', JSON.stringify(shoeTrackStorage));
  }

  private updateShoeTrackStorage(shoeTrack) {
    const shoeMap = {
      [shoeTrack.id]: shoeTrack
    };
    let shoeTrackStorage = JSON.parse(window.sessionStorage.getItem('shoe-track'));
    if (shoeTrackStorage[shoeTrack.id]) {
      shoeMap[shoeTrack.id] = Object.assign(shoeTrackStorage[shoeTrack.id], shoeTrack);
    }
    shoeTrackStorage = Object.assign(shoeTrackStorage, shoeMap);
    window.sessionStorage.setItem('shoe-track', JSON.stringify(shoeTrackStorage));
  }

  private calMinTT(jpt: number, scheduledShutdown: number, targetSafetyFactor: number) {
    return timeConvert(jpt + scheduledShutdown + targetSafetyFactor);
  }

  private calActualSafety(testedTT: number, jpt: number) {
    if (testedTT <= jpt) return null;
    return timeConvert(testedTT - jpt);
  }

  private updateActualSafety(itemArrJpt: any) {
    const jpt = convertStringToTime(itemArrJpt.jpt);
    const testedTT = convertStringToTime(itemArrJpt.thickeningTime);
    const actualSafety = this.calActualSafety(testedTT, jpt);
    this.jptFormArray.controls.forEach((jptForm: UntypedFormGroup) => {
      if (jptForm.value.id === itemArrJpt.id) {
        jptForm.controls.actualSafety.setValue(actualSafety);
        itemArrJpt.actualSafety = actualSafety;
        this.updateJPTBackToStage(itemArrJpt.id, jptForm.value);
      }
    });

    this.cd.markForCheck();
  }

  private updateJPTBackToStage(id: string, jptData: any) {
    const stage = this.findStageFormGroup(id);
    if (!stage) return;
    stage.controls.thickeningTime.setValue(jptData.thickeningTime);
    stage.controls.minThickeningTime.setValue(jptData.minThickeningTime);
    stage.controls.actualSafety.setValue(jptData.actualSafety);

    this.cd.markForCheck();
  }

  private updateTestedTT() {

    this.jptFormArray.controls.forEach((jptForm: UntypedFormGroup) => {

      const testedTT = convertStringToTime(jptForm.controls.thickeningTime.value);

      if (testedTT < this.MaxThickeningTime) {

        jptForm.controls.thickeningTime.setValue(timeConvert(testedTT));
      }
    });
  }
}
