import { PumpScheduleEventModel } from 'libs/models';
import { guid } from 'libs/helpers';
import { PumpScheduleAdapter } from '../../adapters';
import { Observable, Subject } from 'rxjs';
import { IPlacementMethod } from 'libs/models';
import { FLUID_TYPE_SCHEDULE } from 'libs/constants';
export class PumpEventLogic {
    _events: LocalEventModel[] = [];
    _isFirstState: boolean = false;
    _pumpScheduleAdapter: PumpScheduleAdapter;

    constructor(events: PumpScheduleEventModel[], isFirstState: boolean, pumpScheduleAdapter: PumpScheduleAdapter){
        this._pumpScheduleAdapter = pumpScheduleAdapter;
        this._isFirstState = isFirstState;
        this.cloneEvents(events);
        this.normalizeEventsOrder(true);
    }

    public get Events(): LocalEventModel[] {
        return this._events.sort((e1, e2) => e1.order - e2.order);
    }

    public get EventsCount(): number {
        return this._events.length;
    }

    public get NoEvents(): boolean {
        return !Boolean(this._events.length);
    }

    private _onProperyChanged:Subject<EventPropertyChanged> = new Subject<EventPropertyChanged>();
    public get onProperyChanged(): Observable<EventPropertyChanged>{
        return this._onProperyChanged;
    }

    private _onListChanged:Subject<EventListChanged> = new Subject<EventListChanged>();
    public get onListChanged(): Observable<EventListChanged>{
        return this._onListChanged;
    }

    public addEmptyEvent(order: number = null): LocalEventModel {
        const eventOrder = (order !== null) ? order : order = this.EventsCount; //it's suppoused that events are normalized
        const newEvent: LocalEventModel ={
            localId: guid(),
            pumpScheduleEventId: null,
            pumpScheduleStageId: null,
            placementMethod: null,
            placementMethodName: null,
            rate: null,
            actualRate: null,
            volume: null,
            topOfFluid: null,
            length: null,
            duration: 0,
            actualDuration: null,
            bulkCement: null,
            order: eventOrder
        };
        if (order !== null) {
            this.increaseEventsOrder(order, true);
        }
        this._events.push(newEvent);
        this._onListChanged.next(new EventListChanged(newEvent, 'add'));
        return newEvent;
    }

    public removeEvent(order: number): LocalEventModel{
        const event: LocalEventModel = this._events.find(e => e.order === order);
        if (event == null)
            console.error('Pump event logic removeEvent: order out of range');
        this._events.splice(this._events.indexOf(event), 1);
        this.decreaseEventsOrder(order, true);
        this._onListChanged.next(new EventListChanged(event, 'remove'));
        return event;
    }

    public removeAll() {
        let count = this.EventsCount;
        while(count > 0){
            count--;
            this.removeEvent(count);
        }
    }

    public updateEventProperty(localId: string, propertyName: string, value: any) {
        const event: LocalEventModel = this.getEventByLocalId(localId);
        if (event != null) {
            switch (propertyName) {
                case 'rate':
                    this.updateEventRate(event, this.convertToNumber(value), false);
                    break;
                case 'volume':
                    this.updateEventVolume(event, this.convertToNumber(value), false);
                    break;
                case 'actualRate':
                case 'topOfFluid':
                case 'length':
                case 'duration':
                case 'actualDuration':
                case 'bulkCement':
                    this.setEventProperty(event, propertyName, this.convertToNumber(value), false);
                    break;
                case 'placementMethod':
                    this.updatePlacementMethod(event, value, false);
                    break;
                case 'placementMethodName':
                    this.updatePlacementMethodName(event, value, false);
                    break;
            }
        }
    }

    //TODO build validation class
    public validateEvent(event: LocalEventModel, propertyName: string, newValue: any, fluidTypeName: string, mode: string): EventValidationModel{
        const vm = new EventValidationModel();
        const isCompleteMode = mode === 'complete';
        if (propertyName === undefined || propertyName === 'actualRate'){
            vm.actualRate = !isCompleteMode || this.isPlugFluid(fluidTypeName) || this._isFirstState || this.isShutdown(event) || newValue !== null ? null : {'required': true};
        }
        if (propertyName === undefined || propertyName === 'actualDuration'){
            vm.actualDuration = !isCompleteMode || !this.isShutdown(event) || (newValue !== null && newValue >= 0) ? null : {'required': true};
        }
        return vm;
    }

    public isPlugFluid(fluidTypeName: string){
        return fluidTypeName === FLUID_TYPE_SCHEDULE.BOTTOM_PLUG || fluidTypeName === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT;
    }

    private setEventProperty(event: LocalEventModel, propertyName: string, value: any, emitEvent: boolean = true): boolean{
        const oldValue = event[propertyName];
        if (oldValue != value){
            event[propertyName] = value;
            if (emitEvent){
                this._onProperyChanged.next(new EventPropertyChanged(event, propertyName, oldValue));
            }
            return true;
        }
        return false;
    }

    private updateEventRate(event: LocalEventModel, rate: number, emitEvent: boolean = true): PumpScheduleEventModel{
        this.setEventProperty(event, 'rate', rate, emitEvent);
        this.recalculateDuraion(event);
        return event;
    }

    private updateEventVolume(event: LocalEventModel, volume: number, emitEvent: boolean = true): PumpScheduleEventModel{
        this.setEventProperty(event, 'volume', volume, emitEvent);
        this.recalculateDuraion(event);
        return event;
    }

    private updatePlacementMethod(event: LocalEventModel, placementMethodId: string, emitEvent: boolean = true): PumpScheduleEventModel {
        const prevPlacementMethod = event.placementMethodName;
        const wasShutdown = this.isShutdown(event);
        const wasVolume = this.isVolume(event);
        this.setEventProperty(event, 'placementMethod', placementMethodId, emitEvent);
        event.placementMethod = placementMethodId;
        this.refreshEventPlacementMethodName(event, true);

        if (prevPlacementMethod != null && !wasShutdown && !wasVolume) {
            if (this.isShutdown(event)) {
                this.setEventProperty(event, 'rate', null);
                this.setEventProperty(event, 'actualRate', null);
                this.setEventProperty(event, 'volume', null);
                this.setEventProperty(event, 'bulkCement', null);
                this.setEventProperty(event, 'duration', 0);
                this.setEventProperty(event, 'actualDuration', null);
                this.setEventProperty(event, 'length', null);
                this.setEventProperty(event, 'topOfFluid', null);
            }
            else if (this.isVolume(event)) {
                this.setEventProperty(event, 'length', null);
                this.setEventProperty(event, 'topOfFluid', null);
                this.setEventProperty(event, 'actualDuration', null);
            }
        }
        else {
            if (this.isShutdown(event)) {
                this.setEventProperty(event, 'rate', null);
                this.setEventProperty(event, 'actualRate', null);
                this.setEventProperty(event, 'volume', null);
                this.setEventProperty(event, 'bulkCement', null);
                this.setEventProperty(event, 'duration', 0);
                this.setEventProperty(event, 'actualDuration', null);
                this.setEventProperty(event, 'length', null);
                this.setEventProperty(event, 'topOfFluid', null);
            }
            else {
                const rate = event.rate;
                const volume = event.volume;
                if ((event.placementMethodName === null || this.isVolume(event)) && (rate === null || volume === null)) {
                    this.setEventProperty(event, 'duration', 0);
                    this.setEventProperty(event, 'actualDuration', null);
                    this.setEventProperty(event, 'length', null);
                    this.setEventProperty(event, 'topOfFluid', null);
                }
            }
        }
        return event;
    }

    private updatePlacementMethodName(event: LocalEventModel, placementMethodName: string, emitEvent: boolean = true): PumpScheduleEventModel {
        const placementMethod = this.getPlacementMethodByName(placementMethodName);
        return this.updatePlacementMethod(event, placementMethod ? placementMethod.id : null, true);
    }

    private convertToNumber(value: string): number {
        if (value === null || (typeof(value) === 'string' && value.trim() === '')) {
            return null;
        }
        const n = Number(value);
        return n === NaN ? null : n;
    }

    private recalculateDuraion(event: LocalEventModel){
        let duration = null;
        if (event.rate === null || event.volume === null){
            duration = 0;
        }
        else{
            duration = this.calculationDuration(event);
        }
        this.setEventProperty(event, 'duration', duration, true);
    }

    private getEventByLocalId(localId: string): LocalEventModel {
        const event: LocalEventModel = this._events.find(e => e.localId === localId);
        if (!event){
            console.error('Pump event logic (getEventByLocalId): can\'t find elemt with localId ' + localId);
        }
        return event;
    }

    private increaseEventsOrder(order: number, emitEvent: boolean){
        if (order < this.EventsCount){
            this._events.forEach((e: PumpScheduleEventModel) => {
                if (e.order >= order) e.order++;
            });
            //...because unary operation
            if (emitEvent){
                this._events.forEach((e: LocalEventModel) => {
                    if (e.order > order) {
                        this._onProperyChanged.next(new EventPropertyChanged(e, 'order', e.order - 1));
                    }
                }); 
            }
        }
    }

    private decreaseEventsOrder(order: number, emitEvent: boolean){
        if (order < this.EventsCount){
            this._events.forEach((e: PumpScheduleEventModel) => {
                if (e.order > order) {
                    e.order--;
                }
            });
            //...because unary operation
            if (emitEvent){
                this._events.forEach((e: LocalEventModel) => {
                    if (e.order >= order) {
                        this._onProperyChanged.next(new EventPropertyChanged(e, 'order', e.order + 1));
                    }
                }); 
            }
        }
    }

    private normalizeEventsOrder(emitEvent: boolean): void {
        const sortedEvents = this.Events;
        let order = 0;
        const prevValues: number[] = [];
        sortedEvents.forEach(e => {
            prevValues.push(e.order);
            e.order = order;
            order++;
        });
        //...because unary operation
        if (emitEvent){
            sortedEvents.forEach((e, index) => {
                if (e.order !== prevValues[index]) {
                    this._onProperyChanged.next(new EventPropertyChanged(e, 'order', prevValues[index]));
                }
            });
        }
    }

    private cloneEvents(events: PumpScheduleEventModel[]){
        this._events = events.map(e => {
            const clonedEvent: LocalEventModel = Object.assign({
                localId: guid()
            }, e);
            if (e.duration === null) e.duration = 0;
            //if (this._isFirstState) e.actualRate = null;
            this.refreshEventPlacementMethodName(clonedEvent, false);
            return clonedEvent;
        }) || [];
    }

    private refreshEventPlacementMethodName(event: LocalEventModel, emitEvent: boolean): void {
        const placementMethodId: string = event.placementMethod;
        const placementMethod: IPlacementMethod = this.getPlacementMethod(placementMethodId);
        this.setEventProperty(event, 'placementMethodName', placementMethod ? placementMethod.name : null, emitEvent);
    }

    private calculationDuration(event: PumpScheduleEventModel): number {
        const rate = +event.rate;
        const volume = this._isFirstState ? 0 : +event.volume;
        if (isNaN(rate) || isNaN(volume)) {
          return null;
        }
        if (rate !== 0) {
          return volume / rate;
        } else {
          return 0;
        }
    }

    private getPlacementMethod(placementMethodId: string): IPlacementMethod {
        let placementMethod: IPlacementMethod = null;
        if (placementMethodId != null){
            const placementMethodFirstStageValue = this._isFirstState ? this._pumpScheduleAdapter.placementMethodFirstStage$.value : this._pumpScheduleAdapter.placementMethodOtherStage$.value;
            placementMethod = placementMethodFirstStageValue.find(e => e.id === placementMethodId);
        }
        return placementMethod;
    }

    private getPlacementMethodByName(placementMethodName: string): IPlacementMethod {
        let placementMethod: IPlacementMethod = null;
        if (placementMethodName != null){
            const placementMethodFirstStageValue = this._isFirstState ? this._pumpScheduleAdapter.placementMethodFirstStage$.value : this._pumpScheduleAdapter.placementMethodOtherStage$.value;
            placementMethod = placementMethodFirstStageValue.find(e => e.name ===placementMethodName);
        }
        return placementMethod;
    }

    public isShutdown(event: LocalEventModel){
        return event.placementMethodName === 'Shutdown';
    }

    public isVolume(event: LocalEventModel){
        return event.placementMethodName === 'Volume';
    }
}

export class LocalEventModel extends PumpScheduleEventModel{
    localId: string;
}

export class EventPropertyChanged {
    constructor (
        public event: LocalEventModel, 
        public propertyName: string,
        public oldValue: any){}
}

export class EventListChanged {
    constructor (
        public event: LocalEventModel, 
        public action: string){}
}

export class EventValidationModel {
    public actualRate: any;
    public actualDuration: any;
    //other properties should be here
}