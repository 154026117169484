import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmDialogService, DynamicSidebarRef } from 'libs/ui';
import { Subscription } from 'rxjs';
import { ExternalDocumentsService } from '../../services';

@Component({
  selector: 'external-documents-sidebar',
  templateUrl: './external-documents-sidebar.component.html',
  styleUrls: ['./external-documents-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalDocumentsSidebarComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  private labReportsSelected = false;
  private hdfFilesSelected = false;

  data: any = {};

  constructor(
    public externalDocumentsService: ExternalDocumentsService,
    private dynamicSidebarRef: DynamicSidebarRef<ExternalDocumentsSidebarComponent>,
    private confirmDialogService: ConfirmDialogService,
    private cdRef: ChangeDetectorRef) { }

  get showLoadingIndicator(): boolean {
    return this.externalDocumentsService.loadingIndicator;
  }

  get downloading(): boolean {
    return this.externalDocumentsService.filesDownloading;
  }

  get canDownload(): boolean {
    return this.labReportsSelected || this.hdfFilesSelected;
  }

  ngOnInit(): void {
    this.externalDocumentsService.setJobData(this.data);

    this.subscription.add(
      this.externalDocumentsService.documentsDownloaded$.subscribe(_ => {
        this.closeSidebar();
        this.cdRef.markForCheck();
      })
    );

    this.subscription.add(
      this.externalDocumentsService.loadingIndicatorChanged$.subscribe(_ => {
        this.cdRef.detectChanges();
      })
    );
  }

  labReportsSelectionChanged(filesSelected: boolean) {
    this.labReportsSelected = filesSelected;
    this.cdRef.markForCheck();
  }

  hdfFilesSelectionChanged(filesSelected: boolean) {
    this.hdfFilesSelected = filesSelected;
    this.cdRef.markForCheck();
  }

  download() {
    this.externalDocumentsService.downloadDocuments();
  }

  onClose() {
    if (this.downloading) {
      this.confirmDialogService.show({
        message: `Exiting the page will terminate the current download. Are you sure you want to exit?`,
        header: 'Message',
        accept: () => {
          this.externalDocumentsService.cancelDownload();
        },
        reject: () => {
        },
        acceptLabel: 'Yes',
        rejectLabel: 'No'
      });
    } else {
      this.closeSidebar();
    }
  }

  closeSidebar() {
    this.dynamicSidebarRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
