import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ColumnDefinition } from 'libs/ui/models/column-definition';

@Component({
  selector: 'customer-autocomplete-datatable',
  templateUrl: './customer-autocomplete-datatable.component.html',
  styleUrls: ['./customer-autocomplete-datatable.component.scss']
})
export class CustomerAutocompleteDatatableComponent implements OnInit {
  
  @Input() _suggestions: any;
  @Input() _customerForm: UntypedFormGroup;
  @Output() _complete: any = new EventEmitter();
 
  highlightedText: string;
  
  columns: ColumnDefinition[] = [
    new ColumnDefinition('customerName', 'Customer Name'),
    new ColumnDefinition('customerId', 'Customer ID'),
    new ColumnDefinition('location', 'Location')
  ];

  constructor() { }

  ngOnInit(): void {
  }

  onComplete(event: any) {
    this._complete.emit(event);
  }

  setHighlightedText(event: any) {
    this.highlightedText = event;
  }

}
