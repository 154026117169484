import { Component, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PumpScheduleAdapter } from '../../adapters';
import { ApplicationStateService, MasterDataService, FluidService, JobService, UserSettingService, RoleService } from 'libs/shared/services';
import { DynamicSidebarService } from 'libs/ui';
import { PumpScheduleService } from '../../services';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { FluidFormFactory } from '../../../fluids/components/fluid-container/fluid-form.factory';
import { ControlPointAdapter } from '../../../control-point/adapters';
import { EditJobAdapter } from '../../../edit-job/adapters';
import { PumpScheduleInfoComponent } from '../pump-schedule-info/pump-schedule-info.component';
import { MessageService } from 'primeng/api';
import { JobModalManagementService } from 'libs/ui/services/job-modal-management.service';

// @AutoUnsubscribe()
@Component({
  selector: 'pump-schedule-info-cp4',
  templateUrl: './pump-schedule-info-cp4.component.html', 
  styleUrls: ['../pump-schedule-info/pump-schedule-info.component.scss'],
  providers: [{ provide: PumpScheduleInfoComponent, useExisting: PumpScheduleInfoComponentCP4 }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PumpScheduleInfoComponentCP4 extends PumpScheduleInfoComponent {
  @Output() onActualDensityChange = new EventEmitter<any>();
  @Output() onActualVolumeChange = new EventEmitter<any>();

  constructor(
    applicationStateService: ApplicationStateService,
    jobService: JobService,
    pumpScheduleService: PumpScheduleService,
    unitConversionService: UnitConversionService,
    dynamicSidebarService: DynamicSidebarService,
    messageService: MessageService,
    pumpScheduleAdapter: PumpScheduleAdapter,
    fb: UntypedFormBuilder,
    roleService: RoleService,
    editJobAdapter: EditJobAdapter,
    changeDetector: ChangeDetectorRef,
    masterDataService: MasterDataService,
    controlPointAdapter: ControlPointAdapter,
    fluidService: FluidService,
    userSettingsService: UserSettingService,
    jobModalManagementService: JobModalManagementService,
    _fluidFormFactory: FluidFormFactory
  ) {    
    super(applicationStateService, jobService, pumpScheduleService, unitConversionService, 
      dynamicSidebarService, messageService, pumpScheduleAdapter, fb, roleService, editJobAdapter, 
      changeDetector, masterDataService, controlPointAdapter, fluidService, userSettingsService,
      jobModalManagementService, _fluidFormFactory);
    }

    _onActualVolumeChange(event) {
      const typeName = this.stageForm.getRawValue().pumpScheduleFluidTypeName;
      if (typeName && typeName.includes('Cement')) {
        this.onActualVolumeChange.emit(event);
        this.applicationStateService.actualVolumeDetectChange$.next();
      }
  
      this.changeDetector.markForCheck();
    } 
  
    _onActualDensityChange(event) {
      this.onActualDensityChange.emit(event);  
      this.applicationStateService.actualDensityDetectChange$.next();
      this.changeDetector.markForCheck();
    }  
}

  


