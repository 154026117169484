import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ICustomerInformationModel, createCustomerInformationFromCustomer, ICustomerModel } from '../../models';
import { CustomerService } from 'libs/shared/services';
import { ColumnDefinition } from 'libs/ui';

@Component({
  selector: 'customer-typeahead',
  templateUrl: './customer-typeahead.component.html',
  styleUrls: ['./customer-typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerTypeaheadComponent {
  _columns: ColumnDefinition[] = [
    new ColumnDefinition('customerName', 'Customer Name'),
    new ColumnDefinition('customerId', 'Customer ID'),
    new ColumnDefinition('location', 'Location')
  ];
  _options: ICustomerInformationModel[] = [];

  @Input() customerIdControl: UntypedFormControl;
  @Input() customerNameControl: UntypedFormControl;
  @Input() touched: boolean;
  @Input() shouldValidate: boolean = true;
  @Input() shouldMarkAsTouchedOnFocus: boolean = true;
  @Input() inputLengthForHidePanel: number;

  @Output() focusOut: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _customerService: CustomerService,
    private cd: ChangeDetectorRef
  ) {
  }

  _onLazyLoad(keyword: string) {
    this._customerService.searchCustomers(keyword).subscribe(customers => {
      this._options = [...customers.map((value: ICustomerModel) => createCustomerInformationFromCustomer(value))];
      this.cd.markForCheck();
    });
  }

  _onChange(option: ICustomerInformationModel) {
    this.customerIdControl.setValue(option.customerId);
    this.customerNameControl.setValue(option.customerName);
    this.cd.markForCheck();
  }

  _onClear(e) {
    this.customerIdControl.setValue(null);
    this.cd.markForCheck();
  }

  _onFocusOut(e) {
    this.focusOut.emit(e);
  }
}

